import axios from 'axios'
import { userService } from './login'
import { IRaceJson } from '../types'
import { Race } from '../models/race'

const getRace = async (
    raceDate: string,
    trackCode: string,
    raceNumber: number,
    allUsers?: boolean,
    afterRecall?: boolean,
    genericRealTimeTrackerID?: number | null,
    genericHistoricalTrackerID?: number | null
) => {
    let url = `/api/v2/race?race_date=${raceDate}&track_code=${trackCode}&race_number=${raceNumber}`
    if (allUsers) {
        url += `&all_users=${allUsers}`
    }
    if (afterRecall) {
        url += `&after_recall=${afterRecall}`
    }
    if (genericRealTimeTrackerID) {
        url += `&generic_real_time_tracker_id=${genericRealTimeTrackerID}`
    }
    if (genericHistoricalTrackerID) {
        url += `&generic_historical_tracker_id=${genericHistoricalTrackerID}`
    }
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const race = response.data as IRaceJson
    return new Race(race)
}

export default getRace

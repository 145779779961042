import axios from 'axios'
import { getErrorData } from './get_error'
import { userService } from './login'
import { Dispatch } from 'react'

interface realTimeVideo {
    url: string
    format: string
}

export function getRaceLiveVideoUrl(
    trackCode: string,
    source: string,
    dispatch: Dispatch<any>
): Promise<realTimeVideo | null> {
    const url = `/api/v2/video?track_code=${trackCode}&source=${source}`

    const request = axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })

    return request
        .then((response) => {
            if (!response.data) {
                return null
            }

            return {
                url: response.data.url,
                format: response.data.format,
            }
        })
        .catch((error) => {
            if (axios.isAxiosError(error) && error.response?.status === 404) {
                return null
            }

            getErrorData(error, dispatch)
            return null
        })
}

export interface VideoJson {
    url: string
    format: string
    start_time: string
    end_time: string
}

export interface Video {
    URL: string
    Format: string
    StartTime: string
    EndTime: string
}

export function getRaceHistoricalVideo(
    trackCode: string,
    raceDate: number | string,
    raceNumber: number,
    source: string,
    dispatch: Dispatch<any>
): Promise<void | Video | undefined> {
    if (source === '') {
        source = 'xpressbet'
    }
    const url = `/api/v2/historical_video?track_code=${trackCode}&race_date=${raceDate}&race_number=${raceNumber}&source=${source}`

    const request = axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })

    return request
        .then((response) => {
            if (!response.data) {
                return
            }
            const videoJson = response.data as VideoJson
            return {
                URL: videoJson.url,
                Format: videoJson.format,
                StartTime: videoJson.start_time,
                EndTime: videoJson.end_time,
            } as Video
        })
        .catch((error) => {
            if (axios.isAxiosError(error) && error.response?.status === 404) {
                return
            }

            getErrorData(error, dispatch)
        })
}

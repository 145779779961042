import { createAction, handleAction } from 'redux-actions'

export const getDarkMode = createAction('GET_DARK_MODE')

export const getDarkModeReducer = handleAction(
    getDarkMode,
    (state, action) => ({
        ...state,
        darkMode: action.payload as boolean,
    }),
    { darkMode: localStorage.getItem('darkMode') === 'true' }
)

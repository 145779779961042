import React from 'react'
import { Collapse, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { GenericRealTimeTracker } from '../../../services/generic_real_time_tracker'
import { Race } from '../../../models/race'

export const AudioCommandOptions = ({ tracker, race }: { tracker: GenericRealTimeTracker; race: Race }) => {
    const [commandsOpen, setCommandsOpen] = React.useState(false)
    return (
        <>
            <label onClick={() => setCommandsOpen(!commandsOpen)}>
                Commands
                {commandsOpen ? <ExpandLess /> : <ExpandMore />}
            </label>
            <Collapse in={commandsOpen} timeout="auto" unmountOnExit>
                {commandsOpen && (
                    <>
                        <Typography variant={'h5'}>Race level commands</Typography>
                        <ul>
                            {tracker.GenericRealTimeTrackerRaceStateGroups.filter(
                                (rsg) =>
                                    (rsg.RaceType === null || rsg.RaceType === race.raceType) &&
                                    (rsg.SubRaceType === null || rsg.SubRaceType === race.raceSubType) &&
                                    (rsg.StartType === null || rsg.StartType === race.startType)
                            )
                                .flatMap((g) => g.GenericRealTimeTrackerRaceStates)
                                .map((raceState) => (
                                    <li key={raceState.ButtonText}>{raceState.ButtonText}</li>
                                ))}
                        </ul>
                        <Typography variant={'h5'}>Runner commands</Typography>
                        <ul>
                            {tracker.GenericRealTimeTrackerRunnerEvents.filter(
                                (re) =>
                                    (re.RaceType === null || re.RaceType === race.raceType) &&
                                    (re.SubRaceType === null || re.SubRaceType === race.raceSubType) &&
                                    (re.StartType === null || re.StartType === race.startType)
                            ).map((runnerEvent) => (
                                <li key={runnerEvent.ButtonText}>{runnerEvent.ButtonText}</li>
                            ))}
                        </ul>
                    </>
                )}
            </Collapse>
        </>
    )
}

import { Race } from '../../models/race'
import { pollCreatingUserState } from '../../services/create_user_state'
import { mapsHaveSameKeys, mergeRaceIdToOrderMap, moveRace } from '../../services/sort_races_utilities'
import { pollUpcomingRaces } from '../../services/poll_upcoming_races'
import { SortableRacesContainer, SortableRunnerTrackerRaceCard } from '../common/SortableRunnerTrackerComponents'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GenericRealTimeTracker } from '../../services/generic_real_time_tracker'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { SortEnd } from 'react-sortable-hoc'
import { RaceCard } from './RaceCard'
import { getUpcomingRaces } from '../../services/get_upcoming_races'

interface Props {
    tracker: GenericRealTimeTracker
    assignMeRaces: boolean
    onlyAssignedRaces: boolean
}

const mtp = 25

export const RaceCardsWrapper = ({ tracker, assignMeRaces, onlyAssignedRaces }: Props) => {
    const [races, updateRaces] = useState([] as Race[])
    const dispatch = useDispatch()
    useEffect(() => {
        if (assignMeRaces) {
            const timerMilliseconds = 10000
            const userStateTimer = pollCreatingUserState(
                `ACTIVE_${tracker.TrackingType.toUpperCase()}`,
                dispatch,
                timerMilliseconds
            )
            return () => {
                clearInterval(userStateTimer)
            }
        }

        if (!assignMeRaces && !onlyAssignedRaces) {
            const timerMilliseconds = 10000
            const userStateTimer = pollCreatingUserState(
                `ACTIVE_${tracker.TrackingType.toUpperCase()}_PRACTICE`,
                dispatch,
                timerMilliseconds
            )
            return () => {
                clearInterval(userStateTimer)
            }
        }

        return () => {}
    }, [dispatch, assignMeRaces, tracker.TrackingType, onlyAssignedRaces])

    useEffect(() => {
        const upcomingRacesTimer = pollUpcomingRaces(
            {
                dispatch: dispatch,
                mtp: mtp,
                raceType: tracker.RaceType,
                onlyAssigned: onlyAssignedRaces,
                trackingType: tracker.TrackingType,
                country: tracker.Country,
            },
            (newRaces: Race[]) => updateRaces(newRaces)
        )
        return () => {
            clearInterval(upcomingRacesTimer)
        }
    }, [dispatch, tracker.RaceType, onlyAssignedRaces, tracker.TrackingType, tracker.Country])

    const [flexboxIndexByRaceId, updateFlexboxIndexByRaceId] = useState({} as Record<number, number>)
    const [domIndexByRaceId, updateDomIndexByRaceId] = useState({} as Record<number, number>)
    const newSortIndexByRaceId = mergeRaceIdToOrderMap(races, flexboxIndexByRaceId)
    const newRenderOrderByRaceId = mergeRaceIdToOrderMap(races, domIndexByRaceId)
    if (!mapsHaveSameKeys(flexboxIndexByRaceId, newSortIndexByRaceId)) {
        updateFlexboxIndexByRaceId(newSortIndexByRaceId)
        updateDomIndexByRaceId(newRenderOrderByRaceId)
    }

    const stableDomOrderedRaces = [...races].sort((a, b) => domIndexByRaceId[a.id] - domIndexByRaceId[b.id])

    return (
        <>
            <Grid container spacing={1} direction={'column'} justifyContent={'flex-start'}>
                {races.length === 0 && (
                    <Grid item>
                        <Typography variant="h5" gutterBottom>
                            {' '}
                            No races yet{' '}
                        </Typography>
                    </Grid>
                )}
                <StartTrackingAlertBanner races={races} />
                {
                    <Grid item>
                        <SortableRacesContainer
                            onSortEnd={(end: SortEnd) =>
                                updateFlexboxIndexByRaceId(moveRace(newSortIndexByRaceId, end.oldIndex, end.newIndex))
                            }
                            axis={'xy'}
                            useDragHandle={true}
                        >
                            {stableDomOrderedRaces.map((race) => (
                                <SortableRunnerTrackerRaceCard
                                    raceId={race.id}
                                    order={newSortIndexByRaceId[race.id]}
                                    index={newSortIndexByRaceId[race.id]}
                                    key={race.id}
                                >
                                    <RaceCard
                                        tracker={tracker}
                                        race={race}
                                        updateUpcomingRaces={async () => {
                                            const newRaces = await getUpcomingRaces({
                                                dispatch: dispatch,
                                                mtp: mtp,
                                                raceType: tracker.RaceType,
                                                onlyAssigned: onlyAssignedRaces,
                                                trackingType: tracker.TrackingType,
                                                country: tracker.Country,
                                                afterRecall: true,
                                            })
                                            updateRaces(newRaces)
                                        }}
                                    />
                                </SortableRunnerTrackerRaceCard>
                            ))}
                        </SortableRacesContainer>
                    </Grid>
                }
            </Grid>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    '@keyframes blinker': {
        from: { opacity: 1 },
        to: { opacity: 0 },
    },
    alertBanner: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'red',
        animationName: '$blinker',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        padding: theme.spacing(1),
    },
}))

const StartTrackingAlertBanner = ({ races }: { races: Race[] }) => {
    const classes = useStyles()
    const firstRaceUnderOneMtpNotTracked = races.find((race) => race.mtp <= 2 && race.raceStates.length === 0)
    if (!firstRaceUnderOneMtpNotTracked) {
        return null
    }
    return (
        <div className={classes.alertBanner}>
            <Typography variant={'h6'}>
                {firstRaceUnderOneMtpNotTracked.longTrackName} {firstRaceUnderOneMtpNotTracked.raceNumber} (MTP{' '}
                {firstRaceUnderOneMtpNotTracked.mtp}) - needs start tracking
            </Typography>
        </div>
    )
}

import * as React from 'react'
import { Race } from '../../models/race'
import { Grid } from '@material-ui/core'
import { JockeySilk } from './JockeySilk'
import { getSilkColor, getSilkColorsForRaceType } from '../../services/silk_colors'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    runnerNumber: {
        width: '2.5rem',
        height: '2.5rem',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1.5rem !important',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

export const RunnersSilksAndNumbers = ({ race }: { race: Race }) => {
    const classes = useStyles()
    return (
        <Grid container direction={'row'} spacing={1}>
            {race.runners.map((runner) => {
                const silkColors = getSilkColorsForRaceType(race.country, race.raceType)
                let buttonStyles: any
                if (getSilkColor(silkColors, runner.programNumber).backgroundImage) {
                    buttonStyles = {
                        backgroundImage: getSilkColor(silkColors, runner.programNumber).backgroundImage,
                        color: 'transparent',
                        backgroundSize: '100%',
                    }
                } else {
                    buttonStyles = {
                        color: getSilkColor(silkColors, runner.programNumber).color,
                        backgroundColor: getSilkColor(silkColors, runner.programNumber).silkColor,
                    }
                }
                return (
                    <Grid item>
                        <Grid container direction={'column'} spacing={1}>
                            <Grid item>
                                <JockeySilk race={race} runner={runner} />
                            </Grid>
                            <Grid item style={buttonStyles} className={classes.runnerNumber}>
                                {runner.programNumber}
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

import { createAction, handleAction } from 'redux-actions'

export const getToggles = createAction('GET_TOGGLES')

export const getTogglesReducer = handleAction(
    getToggles,
    (state, action) => ({
        ...state,
        toggles: action.payload as Record<string, boolean>,
    }),
    { toggles: {} } as { toggles: Record<string, boolean> }
)

import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, TextField, Tooltip } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import {
    createGenericActionComment,
    GenericActionComment,
    GenericTrackerAction,
    getCommentsForGenericAction,
    getUsersAndGroups,
    IDAndName,
} from '../../../services/generic_action_review'
import { RaceStateTypes } from '../../../types'
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'

export const FeedbackForm = ({
    row,
    allActions,
}: {
    row: GenericTrackerAction
    allActions: GenericTrackerAction[]
}) => {
    const [users, updateUsers] = useState(null as IDAndName[] | null)
    const [groups, updateGroups] = useState(null as IDAndName[] | null)
    const [userRecipients, updateUserRecipients] = useState([] as number[])
    const [groupRecipients, updateGroupRecipients] = useState([] as number[])
    const [alreadySentComments, updateAlreadySentComments] = useState([] as GenericActionComment[])
    const [comment, updateComment] = useState('')
    const [commentType, updateCommentType] = useState('')
    const [name, updateName] = useState('')
    const [shouldUpdateCommentsSent, updateShouldUpdateCommentsSent] = useState(true)
    const [isLearningGuideExample, updateIsLearningGuideExample] = useState(false)

    useEffect(() => {
        const populateCommentsSent = async () => {
            const comments = await getCommentsForGenericAction(row.RunnerEventID, row.RaceStateID)
            if (comments) {
                updateAlreadySentComments(comments)
            }
        }
        if (shouldUpdateCommentsSent) {
            populateCommentsSent()
        }
        updateShouldUpdateCommentsSent(false)
    }, [row.RunnerEventID, row.RaceStateID, shouldUpdateCommentsSent])

    useEffect(() => {
        const fetchUsersAndGroups = async () => {
            const usersAndGroups = await getUsersAndGroups()
            updateUsers(usersAndGroups.Users.sort((a, b) => (a.Name < b.Name ? -1 : 1)))
            updateGroups(usersAndGroups.Groups.sort((a, b) => (a.Name < b.Name ? -1 : 1)))
        }
        if (!users) {
            fetchUsersAndGroups()
        }
    }, [users, groups])

    const submitFeedback = async (providedCommentType?: string | undefined, providedComment?: string | undefined) => {
        const succeeded = await createGenericActionComment(
            name,
            row.RunnerEventID,
            row.RaceStateID,
            providedCommentType ?? commentType,
            providedComment ?? comment,
            userRecipients,
            groupRecipients,
            isLearningGuideExample
        )
        if (!succeeded) {
            return
        }
        updateShouldUpdateCommentsSent(true)
    }

    const startTrackingAction = getStartTrackingAction(row, allActions)
    const otherTrackers = getOtherTrackers(startTrackingAction, row, allActions)
    const agreers = getAgreers(row, allActions)

    return (
        <Grid container direction={'row'} spacing={4}>
            <Grid item key={'already-sent-comments'} className={'already-sent-comments'}>
                <Grid container direction={'column'} spacing={2}>
                    <Grid item>Already sent comments:</Grid>
                    {alreadySentComments.map((c) => {
                        return (
                            <Grid item key={c.Comment}>
                                <Grid container direction={'column'} spacing={1}>
                                    <Grid item>Name: {c.Name}</Grid>
                                    <Grid item>Comment type: {c.CommentType}</Grid>
                                    <Grid item>Comment: {c.Comment}</Grid>
                                    {c.UserRecipients.length > 0 && (
                                        <Grid item>User Recipients: {c.UserRecipients.join(', ')}</Grid>
                                    )}
                                    {c.GroupRecipients.length > 0 && (
                                        <Grid item>Group Recipients: {c.GroupRecipients.join(', ')}</Grid>
                                    )}
                                    {c.Acknowledgements.length > 0 && (
                                        <Grid item>Acknowledgements: {c.Acknowledgements.join(', ')}</Grid>
                                    )}
                                    {
                                        <Grid item>
                                            IsLearningGuideExample: {c.IsLearningGuideExample ? 'true' : 'false'}
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid item key={'quick-recipients'}>
                <Grid container direction={'column'} spacing={1}>
                    <Grid item>Tracker:</Grid>
                    <Grid item>
                        <Button
                            variant={'text'}
                            onClick={() => updateUserRecipients([...new Set([...userRecipients, row.UserID])])}
                        >
                            {row.UserName}
                        </Button>
                    </Grid>
                    <Grid item>Other Trackers:</Grid>
                    {otherTrackers.map((ot) => {
                        const agreed = agreers.includes(ot)
                        return (
                            <Grid item>
                                <Button
                                    variant={'text'}
                                    onClick={() => updateUserRecipients([...new Set([...userRecipients, ot])])}
                                >
                                    {users?.find((u) => u.ID === ot)?.Name}
                                    {agreed && '( agreed )'}
                                </Button>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid item key={'recipients'}>
                <Grid container direction={'column'} spacing={1}>
                    {users && (
                        <Grid item>
                            <Autocomplete
                                multiple
                                id="feedback-form-users"
                                options={users}
                                getOptionLabel={(user: any) => user.Name}
                                value={users.filter((u) => userRecipients.includes(u.ID))}
                                onChange={(event: any, values: any) => {
                                    updateUserRecipients(values.map((v: any) => v.ID))
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="User Recipients"
                                        placeholder="users"
                                    />
                                )}
                                popupIcon={<ArrowDropDown className={'user-recipients'} />}
                            />
                        </Grid>
                    )}
                    {groups && (
                        <Grid item>
                            <Autocomplete
                                multiple
                                id="feedback-form-groups"
                                options={groups}
                                getOptionLabel={(group: any) => group.Name}
                                value={groups.filter((g) => groupRecipients.includes(g.ID))}
                                onChange={(event: any, values: any) => {
                                    updateGroupRecipients(values.map((v: any) => v.ID))
                                }}
                                renderInput={(params: AutocompleteRenderInputParams) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Group Recipients"
                                        placeholder="groups"
                                    />
                                )}
                                popupIcon={<ArrowDropDown className={'group-recipients'} />}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item key={'comment'}>
                <Grid container direction={'column'} spacing={3}>
                    <Grid item>
                        <Button
                            variant={'text'}
                            onClick={() => {
                                submitFeedback('Good Job', 'Good Job')
                            }}
                        >
                            Good Job
                        </Button>
                        <Button
                            variant={'text'}
                            onClick={() => {
                                submitFeedback('Missed Runner', 'You missed this one')
                            }}
                        >
                            Missed Runner
                        </Button>
                        <Button
                            variant={'text'}
                            onClick={() => {
                                submitFeedback('Wrong Horse', 'You marked the right action but the wrong horse')
                            }}
                        >
                            Wrong Horse
                        </Button>
                        <Button
                            variant={'text'}
                            onClick={() => {
                                submitFeedback(
                                    'Falsely Flagged Runner',
                                    'You marked this action, but it did not occur or was not strong enough to be marked. You should not have marked this action.'
                                )
                            }}
                        >
                            Falsely Flagged Runner
                        </Button>
                        <Button
                            variant={'text'}
                            onClick={() => {
                                submitFeedback(
                                    'Close But Not Enough',
                                    'Almost enough, but this horse did not do it enough to mark'
                                )
                            }}
                        >
                            Close But Not Enough
                        </Button>
                        <Button
                            variant={'text'}
                            onClick={() => {
                                submitFeedback('Different Category', 'Different Category')
                            }}
                        >
                            Different Category
                        </Button>
                        <Button
                            variant={'text'}
                            onClick={() => {
                                submitFeedback('Ignore', 'Ignore')
                            }}
                        >
                            Ignore
                        </Button>
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(e) => updateName(e.target.value)}
                            id={`runner-event-${row.RunnerEventID}-race-state-${row.RaceStateID}-comment-name`}
                            value={name}
                            variant={'outlined'}
                            label={'Name (optional)'}
                            size={'medium'}
                            className={'comment-name'}
                            data-testid={`runner-event-${row.RunnerEventID}-race-state-${row.RaceStateID}-comment-name`}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(e) => updateCommentType(e.target.value)}
                            id={`runner-event-${row.RunnerEventID}-race-state-${row.RaceStateID}-comment-type`}
                            value={commentType}
                            variant={'outlined'}
                            label={'Comment Type'}
                            size={'medium'}
                            className={'comment-type'}
                            data-testid={`runner-event-${row.RunnerEventID}-race-state-${row.RaceStateID}-comment-type`}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            multiline
                            onChange={(e) => updateComment(e.target.value)}
                            id={`runner-event-${row.RunnerEventID}-race-state-${row.RaceStateID}-comment`}
                            value={comment}
                            variant={'outlined'}
                            label={'Comment'}
                            size={'medium'}
                            className={'comment'}
                            data-testid={`runner-event-${row.RunnerEventID}-race-state-${row.RaceStateID}-comment`}
                            rows={4}
                        />
                    </Grid>

                    <Grid item>
                        <Tooltip
                            title={userRecipients.length + groupRecipients.length === 0 ? 'No recipients selected' : ''}
                        >
                            <span>
                                <Button
                                    disabled={userRecipients.length + groupRecipients.length === 0}
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => submitFeedback()}
                                >
                                    Submit Comment
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item key={'isLearningGuide'}>
                <Grid container direction={'column'} spacing={1}>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isLearningGuideExample}
                                    onChange={() => updateIsLearningGuideExample(!isLearningGuideExample)}
                                    color="primary"
                                />
                            }
                            label="Mark as example for learning guide"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const getStartTrackingAction = (row: GenericTrackerAction, allActions: GenericTrackerAction[]) => {
    const startTrackingTypes = new Set([
        RaceStateTypes.MTP_START_TRACKING,
        'BANDAGE_START_TRACKING',
        'GRUMPY_START_TRACKING',
        'FRANCEMTP_START_TRACKING',
        'THB_EXACT_LOAD_TIMES_START_TRACKING',
        'HISTORICAL_GRUMPY_TRACKING_START_TRACKING',
        'NEW_MTP_SIGNALS_START_TRACKING',
        'HARNESS_EXACT_LANDMARK_TIMES_START_TRACKING',
    ])
    const startTrackingAction = allActions.find(
        (a) => a.UserID === row.UserID && a.RaceID === row.RaceID && startTrackingTypes.has(a.Type as RaceStateTypes)
    )
    return startTrackingAction?.Type
}

const getOtherTrackers = (
    startTrackingAction: string | undefined,
    row: GenericTrackerAction,
    allActions: GenericTrackerAction[]
) => {
    const otherUsers = allActions
        .filter((a) => a.Type === startTrackingAction && a.RaceID === row.RaceID && a.UserID !== row.UserID)
        .map((a) => a.UserID)
    return [...new Set(otherUsers)]
}

const getAgreers = (row: GenericTrackerAction, allActions: GenericTrackerAction[]) => {
    const agreers = allActions
        .filter(
            (a) =>
                a.Type === row.Type &&
                a.RaceID === row.RaceID &&
                a.UserID !== row.UserID &&
                (Math.abs(row.ActionTime.getTime() - a.ActionTime.getTime()) / 1000 < 5 ||
                    (0 < Math.abs(row.ActionVideoTimestamp - a.ActionVideoTimestamp) &&
                        Math.abs(row.ActionVideoTimestamp - a.ActionVideoTimestamp) < 5)) &&
                (a.RunnerID ?? 0) === (row.RunnerID ?? 0)
        )
        .map((a) => a.UserID)
    return [...new Set(agreers)]
}

import { Grid } from '@material-ui/core'
import * as React from 'react'
import { Rnd } from 'react-rnd'

interface PictureProps {
    pictureURL: string
    height: number
    width: number
}

export const PictureSnapshot = ({ pictureURL, height, width }: PictureProps) => {
    return (
        <Grid item>
            <Rnd
                default={{
                    x: 0,
                    y: 0,
                    width,
                    height,
                }}
                disableDragging={true}
                lockAspectRatio={width / height}
                style={{
                    position: 'relative',
                }}
            >
                <img src={pictureURL} height={'100%'} width={'100%'} alt={'screenshot'} />
            </Rnd>
        </Grid>
    )
}

import * as React from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'

interface ActionReviewLearningCorrectCommentReviewProps {
    commentType: string
    correctCommentType: string
    correctComment: string
    onCorrectAnswerReviewed: () => Promise<void>
}

const useStyles = makeStyles((theme) => ({
    correct: {
        color: theme.palette.success.main,
    },
    incorrect: {
        color: theme.palette.error.main,
    },
}))

export const ActionReviewLearningCorrectCommentReview = ({
    commentType,
    correctCommentType,
    correctComment,
    onCorrectAnswerReviewed,
}: ActionReviewLearningCorrectCommentReviewProps) => {
    const classes = useStyles()
    return (
        <>
            {commentType === correctCommentType && (
                <Grid item>
                    <Grid item className={'action-review-title'}>
                        <Typography variant={'h6'} className={classes.correct}>
                            Good job! The correct answer is {correctCommentType} ({correctComment}). Way to go!
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {commentType !== correctCommentType && (
                <Grid item>
                    <Grid item className={'action-review-title'}>
                        <Typography variant={'h6'} className={classes.incorrect}>
                            Incorrect. The correct answer is {correctCommentType} ({correctComment}). Please review the
                            action again to learn what {correctCommentType} looks like.
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid item>
                <Button
                    color={'primary'}
                    variant={'contained'}
                    size={'medium'}
                    disabled={commentType === ''}
                    onClick={onCorrectAnswerReviewed}
                >
                    Ok
                </Button>
            </Grid>
        </>
    )
}

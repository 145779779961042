import { createAction, handleAction } from 'redux-actions'

export const getLatency = createAction('GET_LATNECY')

export const getLatencyReducer = handleAction(
    getLatency,
    (state, action) => ({
        ...state,
        latencyInSeconds: action.payload,
    }),
    { latencyInSeconds: null }
)

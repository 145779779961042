import { IRace, IRaceJson, IRaceState, IRaceStateJson, IRunner, IRunnerJson } from '../types'
import { RaceState } from './race_state'
import { Runner } from './runner'

export class Race implements IRace {
    public static raceFromKey = (key: string): Race => {
        let trackCode
        let raceDate
        let raceNumber
        ;[trackCode, raceNumber, raceDate] = key.split('-')

        return new Race({
            actual_post_time: '',
            id: 0,
            long_track_name: '',
            mtp: 0,
            number_of_horses: 0,
            race_date: raceDate,
            race_number: +raceNumber,
            race_states: [],
            race_type: '',
            country: '',
            race_sub_type: null,
            start_type: null,
            distance: null,
            france_reunion_number: null,
            runners: [],
            track_code: trackCode,
        })
    }

    public id: number
    public raceNumber: number
    public country: string
    public raceType: string
    public raceSubType: string | null
    public startType: string | null
    public distance: number | null
    public trackCode: string
    public longTrackName: string
    public raceDate: string
    public actualPostTime: Date
    public mtp: number
    public numberOfHorses: number
    public franceReunionNumber: number | null
    public raceStates: IRaceState[]
    public runners: IRunner[]

    constructor(raceJson: IRaceJson) {
        this.id = raceJson.id
        this.raceNumber = raceJson.race_number
        this.country = raceJson.country
        this.raceType = raceJson.race_type
        this.raceSubType = raceJson.race_sub_type
        this.startType = raceJson.start_type
        this.distance = raceJson.distance
        this.trackCode = raceJson.track_code
        this.longTrackName = raceJson.long_track_name
        this.raceDate = raceJson.race_date
        this.actualPostTime = new Date(raceJson.actual_post_time)
        this.mtp = raceJson.mtp
        this.numberOfHorses = raceJson.number_of_horses
        this.franceReunionNumber = raceJson.france_reunion_number
        this.raceStates = raceJson.race_states
            ? raceJson.race_states.map((raceState: IRaceStateJson) => new RaceState(raceState))
            : []
        this.runners = raceJson.runners ? raceJson.runners.map((runner: IRunnerJson) => new Runner(runner)) : []
    }

    public raceKey = (): string => {
        return `${this.trackCode}-${this.raceNumber}-${this.raceDate}`
    }

    public hasRaceState = (state: string): boolean => {
        return this.raceStates?.some((r: IRaceState, _i: number): boolean => r.type === state)
    }

    public videoReplayIssuesForSource = (source: string): string => {
        return `VIDEO_REPLAY_ISSUES_${source.toUpperCase()}`
    }
}

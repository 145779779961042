import React, { useState } from 'react'
import { Button, Grid, TextField, Tooltip, Typography } from '@material-ui/core'
import { RootStateType } from '../../store'
import { useSelector } from 'react-redux'
import { createAssessmentResponse, UnifiedAssessment } from '../../services/tracker_assessment'

export interface Props {
    Assessment: UnifiedAssessment
    OnResponded: () => void
}

export const AssessmentAcknowledgeObject = ({ Assessment, OnResponded }: Props) => {
    const [comment, setComment] = useState('')
    const userName = useSelector((state: RootStateType) => state?.meStore?.me?.userName)
    if (userName !== Assessment.UserName) {
        return null
    }

    if (Assessment.AssessmentText === 'Correct') {
        return <Typography>You were correct for this action. No need to acknowledge/object.</Typography>
    }

    let acknowledgeTooltip = ''
    let objectTooltip = ''
    if (Assessment.Acknowledged) {
        acknowledgeTooltip = 'Already acknowledged mistake'
        objectTooltip = 'Already acknowledged mistake'
    } else if (Assessment.Objected) {
        acknowledgeTooltip = 'Already objected to mistake'
        objectTooltip = 'Already objected to mistake'
    } else if (!commentIsValid(comment)) {
        objectTooltip = 'You must provide a comment to object'
    }

    return (
        <Grid container direction={'column'} spacing={4}>
            <Grid item key={'comment'}>
                <TextField
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    variant={'outlined'}
                    label={'Comment (required for objection)'}
                    size={'medium'}
                    className={'comment'}
                    data-testid={`assessment-${Assessment.RaceTrackingRaceStateAssessmentID}-rs-${Assessment.RaceTrackingRunnerEventAssessmentID}-re`}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item key={'acknowledge'}>
                <Tooltip title={acknowledgeTooltip}>
                    <span>
                        <Button
                            size={'large'}
                            color={'primary'}
                            disabled={acknowledgeTooltip !== ''}
                            variant={'contained'}
                            onClick={async () => {
                                const success = await createAssessmentResponse(
                                    Assessment.RaceTrackingRaceStateAssessmentID,
                                    Assessment.RaceTrackingRunnerEventAssessmentID,
                                    true,
                                    false,
                                    comment
                                )
                                if (!success) {
                                    return
                                }
                                setComment('')
                                OnResponded()
                            }}
                        >
                            Acknowledge
                        </Button>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item key={'object'}>
                <Tooltip title={objectTooltip}>
                    <span>
                        <Button
                            size={'large'}
                            color={'secondary'}
                            variant={'contained'}
                            disabled={objectTooltip !== ''}
                            onClick={async () => {
                                const success = await createAssessmentResponse(
                                    Assessment.RaceTrackingRaceStateAssessmentID,
                                    Assessment.RaceTrackingRunnerEventAssessmentID,
                                    false,
                                    true,
                                    comment
                                )
                                if (!success) {
                                    return
                                }
                                setComment('')
                                OnResponded()
                            }}
                        >
                            Object to mistake
                        </Button>
                    </span>
                </Tooltip>
            </Grid>
        </Grid>
    )
}

const commentIsValid = (comment: string): boolean => {
    return comment.length > 3
}

import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Button,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { Replay10, Replay30, Replay5, KeyboardArrowDown, FastForward } from '@material-ui/icons'

interface Props {
    videoRef: React.RefObject<HTMLVideoElement>
    scrubLocations?: ScrubLocation[]
    initialPlaybackRate?: string
}

export interface ScrubLocation {
    timestamp: number
    timestampName: string
}

const adjustVideoTimestamp = (vidRef: React.RefObject<HTMLVideoElement>, seconds: number) => {
    if (vidRef.current === null) {
        return
    }
    vidRef.current.currentTime = vidRef.current.currentTime + seconds
}

const scrubToTimestamp = (vidRef: React.RefObject<HTMLVideoElement>, newTimestamp: number) => {
    if (vidRef.current === null) {
        return
    }
    vidRef.current.currentTime = newTimestamp
}

export const VideoControls = ({ videoRef, scrubLocations, initialPlaybackRate }: Props) => {
    const [playbackRate, setPlaybackRate] = useState(initialPlaybackRate ?? '1')
    const [menuAnchor, setMenuAnchor] = useState(null as HTMLButtonElement | null)
    const [skipForwardAnchorEl, setSkipForwardAnchorEl] = useState(null as HTMLButtonElement | null)
    const scrubDurations = [1, 3, 5, 10, 15, 30]
    const videoRefPlaybackRate = videoRef?.current?.playbackRate
    useEffect(() => {
        if (videoRef.current === null) {
            return
        }
        if (!playbackRate) {
            return
        }
        videoRef.current.playbackRate = +playbackRate
    }, [playbackRate, videoRef, videoRefPlaybackRate])
    return (
        <Grid container direction={'row'} spacing={1} justifyContent={'center'}>
            <Grid item>
                <IconButton onClick={(event) => setSkipForwardAnchorEl(event.currentTarget)}>
                    <FastForward fontSize={'large'} />
                </IconButton>
                {skipForwardAnchorEl && (
                    <Menu
                        id="basic-menu"
                        anchorEl={skipForwardAnchorEl}
                        open={!!skipForwardAnchorEl}
                        onClose={() => setSkipForwardAnchorEl(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {scrubDurations.map((scrubDuration) => (
                            <MenuItem
                                key={scrubDuration}
                                onClick={() => {
                                    adjustVideoTimestamp(videoRef, scrubDuration)
                                    setSkipForwardAnchorEl(null)
                                }}
                            >
                                +{scrubDuration} Seconds
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            </Grid>
            <Grid item>
                <IconButton onClick={() => adjustVideoTimestamp(videoRef, -5)}>
                    <Replay5 fontSize={'large'} />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={() => adjustVideoTimestamp(videoRef, -10)}>
                    <Replay10 fontSize={'large'} />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={() => adjustVideoTimestamp(videoRef, -30)}>
                    <Replay30 fontSize={'large'} />
                </IconButton>
            </Grid>
            <Grid item>
                <FormControl>
                    <FormLabel>Playback Speed</FormLabel>
                    <RadioGroup
                        onChange={(event) => {
                            if (videoRef.current === null) {
                                return
                            }

                            videoRef.current.playbackRate = +event.currentTarget.value
                            setPlaybackRate(event.currentTarget.value)
                            return true
                        }}
                        row={true}
                        value={playbackRate}
                    >
                        <FormControlLabel value={'0.5'} control={<Radio />} label="1/2x" />
                        <FormControlLabel value={'1'} control={<Radio />} label="1x" />
                        <FormControlLabel value={'1.5'} control={<Radio />} label="1.5x" />
                        <FormControlLabel value={'2'} control={<Radio />} label="2x" />
                        <FormControlLabel value={'5'} control={<Radio />} label="5x" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            {scrubLocations && scrubLocations.length > 0 && (
                <Grid item>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={menuAnchor ? 'true' : undefined}
                        onClick={(event) => setMenuAnchor(event.currentTarget)}
                        endIcon={<KeyboardArrowDown />}
                    >
                        Scrub To
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={menuAnchor}
                        open={!!menuAnchor}
                        onClose={() => setMenuAnchor(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {scrubLocations.map((scrubLocation) => (
                            <MenuItem
                                key={scrubLocation.timestampName}
                                onClick={() => scrubToTimestamp(videoRef, scrubLocation.timestamp)}
                            >
                                {scrubLocation.timestampName}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
            )}
        </Grid>
    )
}

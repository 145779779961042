import axios from 'axios'
import { userService } from './login'
import CONFIG from '../settings/config'
import { Dispatch } from 'redux'
import { getActionReviews as actionReviews } from '../reducers/get_action_reviews'
import { getErrorData } from './get_error'

export interface ActionReview {
    ID: number
    Name: string
    IsActive: boolean
    IsLearningTool: boolean
    Buttons: ActionButton[]
}

export interface ActionReviewJson {
    id: number
    name: string
    is_active: boolean
    is_learning_tool: boolean
    buttons: ActionButtonJson[]
}

export interface ActionButton {
    Ordering: number
    CommentType: string
    CommentTypeButtonText: string
    AllowComment: boolean
    CommentPrompt: string
}

interface ActionButtonJson {
    ordering: number
    comment_type: string
    comment_type_button_text: string
    allow_comment: boolean
    comment_prompt: string
}

export interface ActionReviewAction {
    RaceID: number
    RaceDate: number
    TrackCode: string
    RaceNumber: number
    ProgramNumber: number
    RunnerID: number | null
    CollapsedType: string
    RaceStateID: number | null
    RunnerEventID: number | null
    CorrectCommentType: string
    CorrectComment: string
}

interface NextActionJson {
    race_id: number
    race_date: number
    track_code: string
    race_number: number
    program_number: number
    runner_id: number | null
    collapsed_type: string
    race_state_id: number | null
    runner_event_id: number | null
    correct_comment_type: string
    correct_comment: string
}

export const pollActionReviews = (dispatch: Dispatch<any>) => {
    const initializeActionReviews = async () => {
        try {
            const reviews = await getActionReviews()
            dispatch(actionReviews(reviews))
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }

    initializeActionReviews()

    return window.setInterval(async () => {
        try {
            const reviews = await getActionReviews()
            dispatch(actionReviews(reviews))
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }, CONFIG.TRACKERS_POLL_INTERVAL)
}

export const getActionReviewNextAction = async (actionReviewID: number) => {
    const url = `/api/v2/next_action_review_action?action_review_id=${actionReviewID}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const nextAction = response.data as NextActionJson
    return {
        RaceID: nextAction.race_id,
        RaceDate: nextAction.race_date,
        TrackCode: nextAction.track_code,
        RaceNumber: nextAction.race_number,
        RunnerID: nextAction.runner_id,
        CollapsedType: nextAction.collapsed_type,
        RaceStateID: nextAction.race_state_id,
        RunnerEventID: nextAction.runner_event_id,
        ProgramNumber: nextAction.program_number,
        CorrectComment: nextAction.correct_comment,
        CorrectCommentType: nextAction.correct_comment_type,
    } as ActionReviewAction
}

export const getAllActionReviewActions = async (actionReviewID: number) => {
    const url = `/api/v2/all_action_review_actions?action_review_id=${actionReviewID}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const allActions = response.data as any
    return allActions.map(
        (a: any) =>
            ({
                RaceID: a.race_id,
                RaceDate: a.race_date,
                TrackCode: a.track_code,
                RaceNumber: a.race_number,
                RunnerID: a.runner_id,
                CollapsedType: a.collapsed_type,
                RaceStateID: a.race_state_id,
                RunnerEventID: a.runner_event_id,
                ProgramNumber: a.program_number,
                CorrectComment: a.correct_comment,
                CorrectCommentType: a.correct_comment_type,
            } as ActionReviewAction)
    )
}

export const getActionReviews = async () => {
    const url = '/api/v2/action_reviews'
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const reviews = response.data as ActionReviewJson[]
    return reviews
        .filter((r) => r.is_active)
        .map(
            (r) =>
                ({
                    ID: r.id,
                    Name: r.name,
                    IsLearningTool: r.is_learning_tool,
                    Buttons: r.buttons.map(
                        (b) =>
                            ({
                                Ordering: b.ordering,
                                CommentType: b.comment_type,
                                CommentTypeButtonText: b.comment_type_button_text,
                                AllowComment: b.allow_comment,
                                CommentPrompt: b.comment_prompt,
                            } as ActionButton)
                    ),
                } as ActionReview)
        ) as ActionReview[]
}

export const getRemainingActionReviews = async (actionReviewID: number) => {
    const url = `/api/v2/remaining_action_reviews?action_review_id=${actionReviewID}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return response.data.remaining_actions
}

import React from 'react'
import { Race } from '../../models/race'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { GenericTrackerSingleRunnerButtonsIconList } from './SingleRunnerButtonsIconList'
import { GenericRealTimeTracker } from '../../services/generic_real_time_tracker'
import clsx from 'clsx'

export interface GenericRealTimeTrackerRunnerButtonsProps {
    Tracker: GenericRealTimeTracker
    Race: Race
    VideoSource: string
    RunnerNumberFilter: string[]
    RunnerButtonFilter: string[]
    ShowJockeySilks: boolean
    UpdateUpcoming: () => void
    RunnerButtonsWidth: number
    SortByDrawNumber: boolean
    ShowRunnerNames: boolean
}

const useStyles = makeStyles((theme) => ({
    buttonContainer: ({ runnerButtonsWidth }: { runnerButtonsWidth: number }) => ({
        width: 60 * runnerButtonsWidth,
    }),
}))

export const RunnerButtons = ({
    Tracker,
    Race,
    VideoSource,
    RunnerNumberFilter,
    ShowJockeySilks,
    UpdateUpcoming,
    RunnerButtonsWidth,
    SortByDrawNumber,
    ShowRunnerNames,
    RunnerButtonFilter,
}: GenericRealTimeTrackerRunnerButtonsProps) => {
    const classes = useStyles({ runnerButtonsWidth: RunnerButtonsWidth })
    const orderedRunners = [...Race.runners]
        .sort((a, b) => {
            if (SortByDrawNumber) {
                return a.drawNumber - b.drawNumber
            }
            const aNumber = parseInt(a.programNumber, 10)
            const bNumber = parseInt(b.programNumber, 10)
            return aNumber - bNumber
        })
        .filter((r) => RunnerNumberFilter.length === 0 || RunnerNumberFilter.includes(r.programNumber))
    return (
        <>
            <Grid
                container
                direction={'row'}
                spacing={1}
                justifyContent={'center'}
                className={clsx(classes.buttonContainer, 'generic-tracker-runner-buttons')}
            >
                {orderedRunners.map((r) => (
                    <Grid item key={r.programNumber}>
                        <GenericTrackerSingleRunnerButtonsIconList
                            tracker={Tracker}
                            race={Race}
                            videoSource={VideoSource}
                            runner={r}
                            showJockeySilks={ShowJockeySilks}
                            showRunnerNames={ShowRunnerNames}
                            updateUpcoming={UpdateUpcoming}
                            runnerButtonFilter={RunnerButtonFilter}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Link, Typography } from '@material-ui/core'
import { TrackerSelector } from './GenericActionReview'
import { getTrackerMistakes, UnifiedAssessment } from '../../services/tracker_assessment'
import { PerformanceHeading } from '../new_performance/PerformanceHeading'
import { SelectDateRange } from '../new_performance/SelectDateRange'
import { MistakeReview } from '../new_performance/MistakeReview'
import { RootStateType } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { GenericRealTimeTracker } from '../../services/generic_real_time_tracker'
import { GenericHistoricalTracker } from '../../services/generic_historical_tracker'
import { pollCreatingUserState } from '../../services/create_user_state'

function getTrackingModuleName(
    realTimeTrackerID: number | null,
    historicalTrackerID: number | null,
    realTimeTrackers: GenericRealTimeTracker[] | null,
    historicalTrackers: GenericHistoricalTracker[] | null
) {
    if (realTimeTrackerID) {
        return realTimeTrackers?.find((tracker) => tracker.ID === realTimeTrackerID)?.Name
    }
    if (historicalTrackerID) {
        return historicalTrackers?.find((tracker) => tracker.ID === historicalTrackerID)?.Name
    }
    return null
}

export const MyPerformance = () => {
    const [fetchingAssessments, updateFetchingAssessments] = useState(false)
    const [realTimeTrackerID, setRealTimeTrackerID] = useState(null as number | null)
    const [historicalTrackerID, setHistoricalTrackerID] = useState(null as number | null)
    const [assessments, setAssessments] = useState([] as UnifiedAssessment[])
    const dispatch = useDispatch()

    const [defaultStartDate, defaultEndDate] = defaultStartEndDate()
    const [startDate, setStartDate] = useState(defaultStartDate)
    const [endDate, setEndDate] = useState(defaultEndDate)
    const { historicalTrackers, realTimeTrackers } = useSelector((state: RootStateType) => ({
        historicalTrackers: state?.historicalTrackersStore?.historicalTrackers,
        realTimeTrackers: state?.realTimeTrackersStore?.realTimeTrackers,
        currentUser: state?.meStore?.me,
    }))
    useEffect(() => {
        const timerMilliseconds = 10000
        const userStateTimer = pollCreatingUserState('ACTIVE_MY_PERFORMANCE', dispatch, timerMilliseconds)
        return () => {
            clearInterval(userStateTimer)
        }
    }, [dispatch])

    useEffect(() => {
        setAssessments([])
        fetchData(updateFetchingAssessments, startDate, endDate, realTimeTrackerID, historicalTrackerID, setAssessments)
    }, [startDate, endDate, realTimeTrackerID, historicalTrackerID])

    return (
        <Grid container direction={'column'} spacing={4} justifyContent={'center'} className={'my-performance-root'}>
            <Grid item>
                <PerformanceHeading />
            </Grid>
            <Grid item>
                <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item>
                        <TrackerSelector
                            realTimeTrackerID={realTimeTrackerID}
                            setRealTimeTrackerID={(trackerID) => {
                                setRealTimeTrackerID(trackerID)
                            }}
                            historicalTrackerID={historicalTrackerID}
                            setHistoricalTrackerID={setHistoricalTrackerID}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <SelectDateRange
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                />
            </Grid>
            {fetchingAssessments && (
                <Grid item>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {(realTimeTrackerID || historicalTrackerID) && (
                <>
                    <Grid item>
                        <Grid item container direction={'row'} justifyContent={'center'}>
                            <Grid item>
                                {assessments.length > 0 && (
                                    <Typography variant={'h6'}>
                                        You have {assessments.filter((a) => !a.Acknowledged && !a.Objected).length}{' '}
                                        total mistakes to review
                                    </Typography>
                                )}
                                {assessments.length === 0 && (
                                    <Typography variant={'h6'}>You have no mistakes to review</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'}>
                            <Link
                                href={`https://redash.eochgroup.com/public/dashboards/aNfKWLfw2AyAD7ibwOP5xrrVSoUyQBBth5nQ9TIU?org_slug=default&p_dateRange=${
                                    startDate.toISOString().split('T')[0]
                                }--${endDate.toISOString().split('T')[0]}&p_trackingModule=${getTrackingModuleName(
                                    realTimeTrackerID,
                                    historicalTrackerID,
                                    realTimeTrackers,
                                    historicalTrackers
                                )}`}
                                target={'_blank'}
                            >
                                Click to open leaderboard & runner signal details in new tab
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <MistakeReview
                            fetchingAssessments={fetchingAssessments}
                            assessments={assessments}
                            realTimeTrackerID={realTimeTrackerID}
                            historicalTrackerID={historicalTrackerID}
                            onReviewComplete={() => {
                                fetchData(
                                    updateFetchingAssessments,
                                    startDate,
                                    endDate,
                                    realTimeTrackerID,
                                    historicalTrackerID,
                                    setAssessments
                                )
                            }}
                            onApprovedRejected={() => {
                                fetchData(
                                    updateFetchingAssessments,
                                    startDate,
                                    endDate,
                                    realTimeTrackerID,
                                    historicalTrackerID,
                                    setAssessments
                                )
                            }}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    )
}

const fetchData = async (
    updateFetchingAssessments: (b: boolean) => void,
    startDate: Date,
    endDate: Date,
    realTimeTrackerID: number | null,
    historicalTrackerID: number | null,
    setAssessments: (assessments: UnifiedAssessment[]) => void
) => {
    updateFetchingAssessments(true)
    const trackerAssessments = await getTrackerMistakes(startDate, endDate)
    const assessmentsForThisModule = trackerAssessments.filter(
        (assessment) =>
            (assessment.GenericRealTimeTrackerID ?? 0) === (realTimeTrackerID ?? 0) &&
            (assessment.GenericHistoricalTrackerID ?? 0) === (historicalTrackerID ?? 0)
    )
    setAssessments(assessmentsForThisModule)
    updateFetchingAssessments(false)
}

const defaultStartEndDate = () => {
    const today = new Date()
    const millisecondsInDay = 24 * 60 * 60 * 1000
    const yesterday = new Date(today.getTime())
    const fifteenDaysAgo = new Date(today.getTime() - 15 * millisecondsInDay)
    return [fifteenDaysAgo, yesterday]
}

export const addDays = (date: Date, days: number) => {
    const newDate = new Date()
    newDate.setDate(date.getDate() + days)
    return newDate
}

export const getRaceDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    const yearString = `${year - 2000}`
    const monthString = month + 1 >= 10 ? `${month + 1}` : `0${month + 1}`
    const dayString = day >= 10 ? `${day}` : `0${day}`

    return `${yearString}${monthString}${dayString}`
}

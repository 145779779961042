import axios from 'axios'
import { userService } from './login'

interface SecondsToPostResponse {
    seconds_to_post: number
    estimate_status: string
}

export const getSecondsToPostEstimate = async (raceDate: string, trackCode: string, raceNumber: number) => {
    const url = `/api/v2/seconds_to_post_estimate?race_date=${raceDate}&track_code=${trackCode}&race_number=${raceNumber}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const secondsToPostResponse = response.data as SecondsToPostResponse
    return secondsToPostResponse.seconds_to_post
}

import React from 'react'
import { Grid, Paper, TextField, Typography } from '@material-ui/core'
import { TrackerActionsGrid } from '../generic_action_review/TrackerActionsGrid'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { ArrowDropDown } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { GenericHistoricalTracker } from '../../services/generic_historical_tracker'
import { RootStateType } from '../../store'
import { GenericRealTimeTracker } from '../../services/generic_real_time_tracker'

export const GenericActionReviewCenter = () => {
    return (
        <>
            <Grid container spacing={4} direction={'column'}>
                <Grid item>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid item className={'generic-review-header'}>
                            <Typography variant={'h2'}> Generic Action Review</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Paper>
                        <Grid
                            container
                            direction={'column'}
                            spacing={1}
                            data-testid={'tracker-actions-section'}
                            className={'tracker-actions-section'}
                        >
                            <Grid item>
                                <TrackerActionsGrid />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export interface TrackerSelectorProps {
    realTimeTrackerID: number | null
    setRealTimeTrackerID: (n: number | null) => void
    historicalTrackerID: number | null
    setHistoricalTrackerID: (n: number | null) => void
}

export const TrackerSelector = ({
    realTimeTrackerID,
    setRealTimeTrackerID,
    historicalTrackerID,
    setHistoricalTrackerID,
}: TrackerSelectorProps) => {
    const { historicalTrackers, realTimeTrackers } = useSelector((state: RootStateType) => ({
        historicalTrackers: state?.historicalTrackersStore?.historicalTrackers,
        realTimeTrackers: state?.realTimeTrackersStore?.realTimeTrackers,
    }))
    return (
        <Grid container direction={'row'} spacing={1}>
            <Grid item>
                {(!historicalTrackers || historicalTrackers.length === 0) && (
                    <Typography>No historical trackers found</Typography>
                )}
                {historicalTrackers && historicalTrackers.length > 0 && (
                    <Autocomplete
                        style={{ width: 350 }}
                        multiple={false}
                        id="historical-tracker-selector"
                        options={historicalTrackers}
                        getOptionLabel={(tracker: GenericHistoricalTracker) => tracker.Name}
                        value={historicalTrackers.find((t) => t.ID === historicalTrackerID) ?? null}
                        onChange={(event: any, tracker: any) => {
                            setRealTimeTrackerID(null)
                            setHistoricalTrackerID(tracker?.ID ?? null)
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Historical Tracker"
                                placeholder="tracker"
                            />
                        )}
                        renderOption={(tracker: GenericHistoricalTracker) => (
                            <span className={`historical-tracker-option-${tracker.ID}`}>{tracker.Name}</span>
                        )}
                        popupIcon={<ArrowDropDown className={'historical-trackers'} />}
                    />
                )}
            </Grid>
            <Grid item>
                {(!realTimeTrackers || realTimeTrackers.length === 0) && (
                    <Typography>No real time trackers found</Typography>
                )}
                {realTimeTrackers && realTimeTrackers.length > 0 && (
                    <Autocomplete
                        style={{ width: 350 }}
                        multiple={false}
                        id="real-time-tracker-selector"
                        options={realTimeTrackers}
                        getOptionLabel={(tracker: GenericRealTimeTracker) => tracker.Name}
                        value={realTimeTrackers.find((t) => t.ID === realTimeTrackerID) ?? null}
                        onChange={(event: any, tracker: any) => {
                            setHistoricalTrackerID(null)
                            setRealTimeTrackerID(tracker?.ID ?? null)
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField {...params} variant="standard" label="Real Time Tracker" placeholder="tracker" />
                        )}
                        renderOption={(tracker: GenericRealTimeTracker) => (
                            <span className={`real-time-tracker-option-${tracker.ID}`}>{tracker.Name}</span>
                        )}
                        popupIcon={<ArrowDropDown className={'real-time-trackers'} />}
                    />
                )}
            </Grid>
        </Grid>
    )
}

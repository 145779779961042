import { IRaceState, IRaceStateJson, ISimpleUser } from '../types'

export class RaceState implements IRaceState {
    public id: number
    public time: Date
    public type: string
    public user: ISimpleUser
    public videoTimestamp: number | null
    public videoSource: string
    public genericRealTimeTrackerID: number | null
    public genericHistoricalTrackerID: number | null

    constructor(raceStateJson: IRaceStateJson) {
        this.id = raceStateJson.id
        this.time = new Date(raceStateJson.time)
        this.type = raceStateJson.type
        this.user = {
            userName: raceStateJson.user.user_name,
            team: raceStateJson.user.team,
            active: raceStateJson.user.active,
        }
        this.videoTimestamp = raceStateJson.video_timestamp
        this.videoSource = raceStateJson.video_source
        this.genericRealTimeTrackerID = raceStateJson.generic_real_time_tracker_id
        this.genericHistoricalTrackerID = raceStateJson.generic_historical_tracker_id
    }
}

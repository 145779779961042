import * as React from 'react'
import {
    ActionReview,
    getActionReviewNextAction,
    getRemainingActionReviews,
    ActionReviewAction,
} from '../../services/action_review'
import { useEffect, useState } from 'react'
import { getErrorData } from '../../services/get_error'
import { useDispatch } from 'react-redux'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import { ActionReviewVideo } from './ActionReviewVideo'
import { ActionReviewButtons } from './ActionReviewButtons'
import getRace from '../../services/get_race'
import { Race } from '../../models/race'
import { pollCreatingUserState } from '../../services/create_user_state'

export const ActionReviewComponent = ({ actionReview }: { actionReview: ActionReview }) => {
    const [action, setAction] = useState(null as ActionReviewAction | null)
    const [remainingActions, setRemainingActions] = useState(null as number | null)
    const [noNextAction, setNoNextAction] = useState(false)
    const [fetchingAction, setFetchingAction] = useState(false)
    const [race, setRace] = useState(null as Race | null)
    const dispatch = useDispatch()
    useEffect(() => {
        const timerMilliseconds = 10000
        const actionReviewPrefix = actionReview.Name.replace(/ /g, '_').toUpperCase()
        const userStateTimer = pollCreatingUserState(`ACTIVE_${actionReviewPrefix}`, dispatch, timerMilliseconds)
        return () => {
            clearInterval(userStateTimer)
        }
    }, [actionReview.Name, dispatch])
    useEffect(() => {
        const fetchNextAction = async () => {
            setFetchingAction(true)
            setRace(null)
            try {
                const nextAction = await getActionReviewNextAction(actionReview.ID)
                const remaining = await getRemainingActionReviews(actionReview.ID)
                const race = await getRace(nextAction.RaceDate.toString(), nextAction.TrackCode, nextAction.RaceNumber)
                setAction(nextAction)
                setRemainingActions(remaining)
                setRace(race)
            } catch (e) {
                getErrorData(e, dispatch)
                setNoNextAction(true)
            } finally {
                setFetchingAction(false)
            }
        }
        if (!action && !fetchingAction && !noNextAction) {
            fetchNextAction()
        }
    }, [action, fetchingAction, actionReview.ID, dispatch, noNextAction])

    return (
        <Grid container direction={'column'} alignItems={'center'} spacing={3}>
            <Grid item container direction={'row'} justifyContent={'center'}>
                <Grid item className={'action-review-title'}>
                    <Typography variant={'h3'}>{actionReview.Name}</Typography>
                </Grid>
            </Grid>
            {remainingActions !== null && remainingActions > 0 && (
                <Grid item container direction={'row'} justifyContent={'center'}>
                    <Grid item className={'action-review-remaining'}>
                        <Typography variant={'body1'}>{remainingActions} actions left</Typography>
                    </Grid>
                </Grid>
            )}
            {fetchingAction && (
                <Grid item container direction={'row'} justifyContent={'center'}>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
            {noNextAction && (
                <Grid item container direction={'row'} justifyContent={'center'}>
                    <Grid item>
                        <Typography variant={'body1'} color={'secondary'}>
                            No more actions, please reach out to Will
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {action && race && <ActionReviewVideo action={action} race={race} />}
            {action && (
                <ActionReviewButtons
                    action={action}
                    actionReview={actionReview}
                    onActionReviewed={() => setAction(null)}
                />
            )}
        </Grid>
    )
}

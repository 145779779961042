import React from 'react'
import { Race } from '../../../models/race'
import { DragHandle } from '../../common/SortableRunnerTrackerComponents'
import { Chip, Grid, Typography } from '@material-ui/core'

export const AudioHeader = ({ race }: { race: Race }) => {
    return (
        <>
            <Grid item>
                <DragHandle />
            </Grid>
            <Grid item>
                <Typography variant={'h5'}>
                    {race.longTrackName} {race.raceNumber}
                </Typography>
            </Grid>
            <Grid item>
                <Chip label={`MTP: ${race.mtp}`} variant={'default'} />
            </Grid>
            <Grid item>
                <Chip label={`${race.numberOfHorses} horses`} variant={'default'} />
            </Grid>
            <Grid item>
                <Chip label={`${race.raceType}`} variant={'default'} />
            </Grid>
            {race.raceSubType && (
                <Grid item>
                    <Chip label={`${race.raceSubType}`} variant={'default'} />
                </Grid>
            )}
            {race.startType && (
                <Grid item>
                    <Chip label={`${race.startType}`} variant={'default'} />
                </Grid>
            )}
        </>
    )
}

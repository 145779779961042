import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { getTrackerObjections, UnifiedAssessment } from '../../services/tracker_assessment'
import { SelectDateRange } from '../new_performance/SelectDateRange'
import { ObjectionReview } from '../objection_review/ObjectionReview'

export const ObjectionReviewLayout = () => {
    const [fetchingAssessments, updateFetchingAssessments] = useState(false)
    const [assessments, setAssessments] = useState([] as UnifiedAssessment[])
    const [defaultStartDate, defaultEndDate] = defaultStartEndDate()
    const [startDate, setStartDate] = useState(defaultStartDate)
    const [endDate, setEndDate] = useState(defaultEndDate)

    useEffect(() => {
        setAssessments([])
        fetchData(updateFetchingAssessments, startDate, endDate, setAssessments)
    }, [startDate, endDate])

    return (
        <Grid container direction={'column'} spacing={4} justifyContent={'center'} className={'my-performance-root'}>
            <Grid item>
                <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item>
                        <Typography variant={'h2'}>Objection Review</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <SelectDateRange
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                />
            </Grid>
            {fetchingAssessments && (
                <Grid item>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {!fetchingAssessments && (
                <Grid item>
                    <Grid item container direction={'row'} justifyContent={'center'}>
                        <Grid item>
                            {assessments.length > 0 && (
                                <Typography variant={'h6'}>
                                    {assessments.filter((a) => !a.Rejected && !a.Approved).length} total objections to
                                    review
                                </Typography>
                            )}
                            {assessments.length === 0 && (
                                <Typography variant={'h6'}>No objections to review</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item>
                <ObjectionReview
                    fetchingAssessments={fetchingAssessments}
                    assessments={assessments}
                    onReviewComplete={() => {
                        fetchData(updateFetchingAssessments, startDate, endDate, setAssessments)
                    }}
                    onApprovedRejected={() => {
                        fetchData(updateFetchingAssessments, startDate, endDate, setAssessments)
                    }}
                />
            </Grid>
        </Grid>
    )
}

const fetchData = async (
    updateFetchingAssessments: (b: boolean) => void,
    startDate: Date,
    endDate: Date,
    setAssessments: (assessments: UnifiedAssessment[]) => void
) => {
    updateFetchingAssessments(true)
    const trackerAssessments = await getTrackerObjections(startDate, endDate)
    setAssessments(trackerAssessments)
    updateFetchingAssessments(false)
}

const defaultStartEndDate = () => {
    const today = new Date()
    const millisecondsInDay = 24 * 60 * 60 * 1000
    const yesterday = new Date(today.getTime())
    const sevenDaysAgo = new Date(today.getTime() - 7 * millisecondsInDay)
    return [sevenDaysAgo, yesterday]
}

import axios from 'axios'
import { getErrorData } from './get_error'
import { userService } from './login'
import { v4 as uuidv4 } from 'uuid'
import { getMeData } from './get_me'
import { Dispatch } from 'redux'

export const createUserState = (type: string, dispatch: Dispatch<any>) => {
    const url = '/api/v2/user_state'

    const uuidString = uuidv4().toString()

    const request = axios
        .post(
            url,
            {
                type: type.toString(),
                uuid: uuidString,
            },
            {
                headers: userService.authHeader(),
                timeout: 1000 * 10,
            }
        )
        .then(() => getMeData(dispatch))
        .catch((error) => {
            if (axios.isAxiosError(error) && error.response) {
                getErrorData(error, dispatch)
            }
        })

    return request
}

export const pollCreatingUserState = (type: string, dispatch: Dispatch<any>, intervalMilliseconds: number) => {
    if (localStorage.getItem('user')) {
        createUserState(type, dispatch)
    }

    return window.setInterval(() => {
        if (localStorage.getItem('user')) {
            createUserState(type, dispatch)
        }
    }, intervalMilliseconds)
}

import React from 'react'
import { Race } from '../../models/race'
import { Button, Grid, Tooltip } from '@material-ui/core'
import {
    GenericHistoricalTracker,
    GenericHistoricalTrackerStartTracking,
} from '../../services/generic_historical_tracker'
import { GenericHistoricalTrackerRaceButtons } from './GenericHistoricalTrackerRaceButtons'
import { HarnessLandmarkDisplay } from './HarnessLandmarkDisplay'
import { useDispatch } from 'react-redux'
import { createRaceState } from '../../services/create_race_state'

export function RaceButtonsLayout(props: {
    fullRace: Race
    videoTimestamp: number | null
    videoSource: string
    tracker: GenericHistoricalTracker
    startedTracking: boolean
    onTrackingCompleted: () => void
    updateRace: () => void
}) {
    const dispatch = useDispatch()
    return (
        <Grid container direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
            {!props.startedTracking && (
                <Tooltip title={(props.videoTimestamp === null).toString()}>
                    <Grid item>
                        <Button
                            disabled={props.videoTimestamp === null}
                            color={'primary'}
                            variant="contained"
                            size={'small'}
                            onClick={async () => {
                                await createRaceState(
                                    {
                                        raceID: props.fullRace.id,
                                        type: GenericHistoricalTrackerStartTracking(props.tracker),
                                        latencyInSeconds: 0,
                                        practiceMode: props.tracker.PracticeMode,
                                        videoTimestamp: props.videoTimestamp,
                                        videoSource: props.videoSource,
                                        genericHistoricalTrackerID: props.tracker.ID,
                                        genericRealTimeTrackerID: null,
                                    },
                                    dispatch
                                )
                                props.updateRace()
                            }}
                        >
                            Start Tracking
                        </Button>
                    </Grid>
                </Tooltip>
            )}
            {props.startedTracking && (
                <>
                    {props.fullRace.raceType === 'HARNESS' && (
                        <HarnessLandmarkDisplay trackCode={props.fullRace.trackCode} runners={props.fullRace.runners} />
                    )}
                    {props.fullRace.raceType === 'TROT' && props.fullRace.startType === 'AUTOMOBILE' && (
                        <HarnessLandmarkDisplay
                            trackCode={props.fullRace.trackCode}
                            distance={props.fullRace.distance}
                        />
                    )}
                    {(props.videoTimestamp !== null || props.videoSource !== null) && (
                        <Grid
                            item
                            data-testid={'generic-historical-tracker-race-state-buttons'}
                            className={'generic-historical-tracker-race-state-buttons'}
                        >
                            <GenericHistoricalTrackerRaceButtons
                                Tracker={props.tracker}
                                Race={props.fullRace}
                                VideoTimestamp={props.videoTimestamp}
                                VideoSource={props.videoSource}
                                OnTrackingCompleted={props.onTrackingCompleted}
                                UpdateRace={props.updateRace}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    )
}

import * as React from 'react'
import { useEffect, useState, SyntheticEvent } from 'react'
import getHarnessLandmark, { HarnessLandmark } from '../../services/get_harness_landmark'
import { useDispatch } from 'react-redux'
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core'
import { ExpandLess, ExpandMore, Add, Remove } from '@material-ui/icons'
import { IRunner } from '../../types'

export const HarnessLandmarkDisplay = ({
    trackCode,
    distance,
    runners,
}: {
    trackCode: string
    distance?: number | null
    runners?: IRunner[]
}) => {
    const [hideEverything, setHideEverything] = useState(false)
    const [showLandmarkPicture, setShowLandmarkPicture] = useState(false)
    const [whichLandmark, setWhichLandmark] = useState('normal')
    const [harnessLandmark, setHarnessLandmark] = useState(null as HarnessLandmark | null)
    const dispatch = useDispatch()

    useEffect(() => {
        let populateState = async () => {
            const harnessLandmark = await getHarnessLandmark(trackCode, distance ?? null, dispatch)
            setWhichLandmark(harnessLandmark?.HasEarlyLandmark ? 'early' : 'normal')
            setHarnessLandmark(harnessLandmark)
        }
        populateState()
    }, [trackCode, distance, dispatch])

    if (!harnessLandmark) {
        return null
    }
    const secondRowRunners = runners?.filter((r) => parseInt(r.programNumber, 10) > harnessLandmark.GateWidth)
    return (
        <Grid container spacing={1} direction={'row'} alignItems={'center'} justifyContent={'center'}>
            <Grid item>
                <Grid container direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                    {hideEverything && (
                        <Grid item>
                            <IconButton onClick={() => setHideEverything(false)} size={'small'}>
                                <Add />
                            </IconButton>
                        </Grid>
                    )}
                    {!hideEverything && (
                        <Grid item>
                            <IconButton onClick={() => setHideEverything(true)}>
                                <Remove />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {!hideEverything && (
                <Grid item>
                    <Grid container direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                        {harnessLandmark.HasEarlyLandmark && (
                            <Grid item>
                                <Typography variant={'body1'}>
                                    Early Landmark: {harnessLandmark.EarlyLandmarkName}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <Typography variant={'body1'}>Landmark: {harnessLandmark.LandmarkName}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={'body1'}>Max horses on gate: {harnessLandmark.GateWidth}</Typography>
                        </Grid>
                        {secondRowRunners &&
                            secondRowRunners.length > 0 &&
                            secondRowRunners.map((runner) => (
                                <Grid item>
                                    <Typography variant={'body1'} color={'secondary'}>
                                        RUNNER {runner.programNumber} IN SECOND ROW (behind runner 1)
                                    </Typography>
                                </Grid>
                            ))}
                        <Grid item>
                            {!showLandmarkPicture && (
                                <label>
                                    <IconButton
                                        size={'small'}
                                        onClick={() => setShowLandmarkPicture(true)}
                                        color={'primary'}
                                    >
                                        <ExpandMore /> Show landmark info
                                    </IconButton>
                                </label>
                            )}
                            {showLandmarkPicture && (
                                <label>
                                    <IconButton
                                        size={'small'}
                                        onClick={() => setShowLandmarkPicture(false)}
                                        color={'secondary'}
                                    >
                                        <ExpandLess />
                                        Hide landmark info
                                    </IconButton>
                                </label>
                            )}
                        </Grid>
                        {showLandmarkPicture && (
                            <Grid item>
                                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                    {harnessLandmark.HasEarlyLandmark && (
                                        <Grid>
                                            <FormControl>
                                                <FormLabel>Which Landmark?</FormLabel>
                                                <RadioGroup
                                                    onChange={(event: SyntheticEvent<HTMLInputElement>) =>
                                                        setWhichLandmark(event.currentTarget.value)
                                                    }
                                                    row={true}
                                                    value={whichLandmark}
                                                >
                                                    <FormControlLabel
                                                        value={'early'}
                                                        control={<Radio />}
                                                        label="early"
                                                    />
                                                    <FormControlLabel
                                                        value={'normal'}
                                                        control={<Radio />}
                                                        label="normal"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <img
                                            src={
                                                whichLandmark === 'normal'
                                                    ? harnessLandmark.LandmarkLocationImageUrl
                                                    : harnessLandmark.EarlyLandmarkLocationImageUrl
                                            }
                                            height={100}
                                            alt={
                                                'diagram of landmark location missing. please reach out to the channel.'
                                            }
                                        />
                                    </Grid>
                                    {harnessLandmark.LandmarkDescription && harnessLandmark.LandmarkDescription !== '' && (
                                        <Grid item>
                                            <Typography>
                                                {whichLandmark === 'normal'
                                                    ? harnessLandmark.LandmarkDescription
                                                    : harnessLandmark.EarlyLandmarkDescription}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <img
                                            src={
                                                whichLandmark === 'normal'
                                                    ? harnessLandmark.ImageURL
                                                    : harnessLandmark.EarlyImageURL
                                            }
                                            height={300}
                                            alt={'landmark missing. please reach out to the channel.'}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

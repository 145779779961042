import { getError } from '../reducers/get_error'
import { datadogLogs } from '@datadog/browser-logs'
import { Dispatch } from 'react'

export function getErrorData(error: any, dispatch: Dispatch<any>) {
    datadogLogs.logger.error(errorToString(error), error)
    console.error(error)
    dispatch(getError(errorToString(error)))
}

function errorToString(error: any): string {
    return error.toString() + '\n' + error?.request?.responseURL ?? ''
}

import * as React from 'react'
import { RefObject, SyntheticEvent, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { Rnd } from 'react-rnd'
import ReactHlsPlayer from 'react-hls-player'

interface Props {
    url: string
    onProgress?: (event: SyntheticEvent<HTMLVideoElement>) => void
    initialTimestamp: number
    videoRef: RefObject<HTMLVideoElement>
    idForIFrame?: string
}

export const HlsVideo = ({ url, onProgress, videoRef, initialTimestamp, idForIFrame }: Props) => {
    useEffect(() => {
        const scrubToTimestamp = () => {
            videoRef!.current!.currentTime = initialTimestamp
        }
        videoRef?.current?.addEventListener('loadedmetadata', scrubToTimestamp)
        return () => videoRef?.current?.removeEventListener('loadedmetadata', scrubToTimestamp)
    }, [videoRef, initialTimestamp])
    if (!url) {
        return null
    }
    return (
        <Grid container direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
            <Grid item>
                <Rnd
                    default={{
                        x: 0,
                        y: 0,
                        width: 800,
                        height: 600,
                    }}
                    disableDragging={false}
                    lockAspectRatio={4 / 3}
                    style={{
                        position: 'relative',
                    }}
                >
                    <ReactHlsPlayer
                        id={idForIFrame}
                        src={url}
                        autoPlay={false}
                        controls={true}
                        width="100%"
                        height="100%"
                        playerRef={videoRef}
                        onTimeUpdate={onProgress}
                    />
                </Rnd>
            </Grid>
        </Grid>
    )
}

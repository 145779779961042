import axios from 'axios'
import { getErrorData } from './get_error'
import { userService } from './login'
import { Dispatch } from 'react'

export function getDeepgramAccessKey(dispatch: Dispatch<any>): Promise<string> {
    const url = '/api/v2/deepgram_access_key'

    const request = axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })

    return request
        .then((response) => {
            if (!response.data) {
                return
            }

            return response.data.key
        })
        .catch((error) => {
            if (axios.isAxiosError(error) && error.response?.status === 404) {
                return
            }

            getErrorData(error, dispatch)
        })
}

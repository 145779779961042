import React, { useState } from 'react'
import { AppBar, Grid, Paper, Tab, Tabs, Typography } from '@material-ui/core'
import { AssessmentAcknowledgeObject } from './AssesmentAcknowledgeObject'
import { GenericActionVideo } from '../common/GenericActionVideo'
import { AssessmentResponseApproveReject } from './AssessmentResponseApproveReject'
import { UnifiedAssessment } from '../../services/tracker_assessment'
import { makeStyles } from '@material-ui/core/styles'

export interface Props {
    Assessment: UnifiedAssessment
    OnResponded: () => void
    OnApprovedRejected: () => void
    Height?: number
    Width?: number
    AllowApproveReject: boolean
}
const useStyles = makeStyles((theme) => ({
    videoContent: {
        margin: theme.spacing(2),
    },
}))

export const SingleActionReviewDetail = ({
    Assessment,
    OnResponded,
    OnApprovedRejected,
    Height,
    Width,
    AllowApproveReject,
}: Props) => {
    const hasExample = !!(Assessment.ExampleExpectedRaceStateID ?? Assessment.ExampleExpectedRunnerEventID)
    const hasUserAction = !!(Assessment.ActualRaceStateID ?? Assessment.ActualRunnerEventID)
    const [currentTab, setCurrentTab] = useState('your-action')
    const classes = useStyles()

    return (
        <Paper>
            <AppBar position="static">
                <Tabs
                    value={currentTab}
                    onChange={(event, newValue) => setCurrentTab(newValue)}
                    aria-label="pick which users action to view"
                >
                    <Tab label={'Your Action'} value={'your-action'} key={'your-action'} />
                    <Tab label={'Graders Action'} value={'graders-action'} key={'graders-action'} />
                </Tabs>
            </AppBar>
            <Grid
                container
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                className={classes.videoContent}
            >
                {currentTab === 'graders-action' && (
                    <>
                        {hasExample && (
                            <>
                                <Grid item>
                                    <Typography variant={'h5'}>
                                        Recording of GRADER'S action (!!! this is NOT your action !!!)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction={'column'} spacing={1}>
                                        <GenericActionVideo
                                            RaceID={Assessment.RaceID}
                                            RaceDate={Assessment.RaceDate}
                                            TrackCode={Assessment.TrackCode}
                                            RaceNumber={Assessment.RaceNumber}
                                            RaceStateID={Assessment.ExampleExpectedRaceStateID}
                                            RunnerEventID={Assessment.ExampleExpectedRunnerEventID}
                                            Height={Height}
                                            Width={Width}
                                            FullVideoTitleOverride={"Full video scrubbed to GRADER'S button click"}
                                            UserClickVideoTitleOverride={"Screen recording of GRADER'S button click"}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {!hasExample && (
                            <>
                                <Grid item>
                                    <Typography variant={'h5'}>
                                        The grader did not click {Assessment.Type}, so there is no video / screen
                                        recording for the grader
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </>
                )}

                {currentTab === 'your-action' && (
                    <>
                        {hasUserAction && (
                            <>
                                <Grid item>
                                    <Typography variant={'h5'}>Recording of YOUR action</Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container direction={'column'} spacing={1}>
                                        <GenericActionVideo
                                            RaceID={Assessment.RaceID}
                                            RaceDate={Assessment.RaceDate}
                                            TrackCode={Assessment.TrackCode}
                                            RaceNumber={Assessment.RaceNumber}
                                            RaceStateID={Assessment.ActualRaceStateID}
                                            RunnerEventID={Assessment.ActualRunnerEventID}
                                            Height={Height}
                                            Width={Width}
                                            FullVideoTitleOverride={'Full video scrubbed to YOUR button click'}
                                            UserClickVideoTitleOverride={'Screen recording of YOUR button click'}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {!hasUserAction && (
                            <>
                                <Grid item>
                                    <Typography variant={'h5'}>
                                        You did not click {Assessment.Type}, so there is no video / screen recording for
                                        you
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </>
                )}

                <Grid item>
                    <AssessmentAcknowledgeObject Assessment={Assessment} OnResponded={OnResponded} />
                </Grid>
                {AllowApproveReject && (
                    <Grid item>
                        <AssessmentResponseApproveReject
                            Assessment={Assessment}
                            OnApprovedRejected={OnApprovedRejected}
                        />
                    </Grid>
                )}
            </Grid>
        </Paper>
    )
}

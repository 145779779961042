import axios from 'axios'
import { userService } from './login'

export const saveFile = async (fileName: string, contentType: string, file: ArrayBuffer) => {
    const url = '/api/v2/file_to_s3'

    const base64File = btoa(new Uint8Array(file).reduce((data, byte) => data + String.fromCharCode(byte), ''))
    const data = {
        file_name: fileName,
        content_type: contentType,
        file: base64File,
    }
    const response = await axios.put(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return response.status
}

import React from 'react'
import { Grid } from '@material-ui/core'
import { FeedbackForm } from './FeedbackForm'
import { GenericTrackerAction } from '../../../services/generic_action_review'
import { GenericActionVideo } from '../../common/GenericActionVideo'

export const ActionVideos = ({
    row,
    allActions,
}: {
    row: GenericTrackerAction
    allActions: GenericTrackerAction[]
}) => {
    return (
        <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            spacing={6}
            className={`details-action-${row.RunnerEventID}-${row.RaceStateID}`}
            data-testid={`details-action-${row.RunnerEventID}-${row.RaceStateID}`}
        >
            <GenericActionVideo
                RaceID={row.RaceID}
                RaceDate={row.RaceDate}
                TrackCode={row.TrackCode}
                RaceNumber={row.RaceNumber}
                RaceStateID={row.RaceStateID}
                RunnerEventID={row.RunnerEventID}
                Height={900}
                Width={1200}
            />
            <Grid item>
                <FeedbackForm row={row} allActions={allActions} />
            </Grid>
        </Grid>
    )
}

import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MistakesList } from '../new_performance/MistakesList'
import { UnifiedAssessment } from '../../services/tracker_assessment'

export interface Props {
    assessments: UnifiedAssessment[]
    selectedAssessment: UnifiedAssessment | null
    setSelectedAssessment: (assessment: UnifiedAssessment | null) => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}))

export const ObjectionSelector = ({ assessments, selectedAssessment, setSelectedAssessment }: Props) => {
    const classes = useStyles()
    useEffect(() => {
        setSelectedAssessment(null)
    }, [assessments, setSelectedAssessment])
    return (
        <Grid container direction={'column'} spacing={1} className={classes.root}>
            <Grid item>
                {!(assessments && assessments.length > 0) && <Typography>No mistakes found</Typography>}
                {assessments && assessments.length > 0 && (
                    <MistakesList
                        mistakes={assessments}
                        selectedAssessment={selectedAssessment}
                        setSelectedAssessment={setSelectedAssessment}
                    />
                )}
            </Grid>
        </Grid>
    )
}

import { OptionsObject, SnackbarKey, SnackbarMessage, withSnackbar } from 'notistack'
import * as React from 'react'
import { connect } from 'react-redux'
import { RootStateType } from '../../store'

interface Iprops {
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey
    closeSnackbar: (key?: string | number | undefined) => void
    errorStore?: any
}

interface IState {
    errors: string[]
}

class Notifier extends React.Component<Iprops, IState> {
    public state: IState = {
        errors: [],
    }

    public componentDidUpdate(): void {
        if (this.props.errorStore.error) {
            const error: string[] = this.props.errorStore.error

            this.props.enqueueSnackbar(error, { variant: 'error' })
            this.props.errorStore.error = null
        }
    }

    public render() {
        return <span />
    }
}

export default connect((state: RootStateType) => state)(withSnackbar(Notifier))

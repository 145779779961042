import React, { useEffect, useState } from 'react'
import {
    DataTypeProvider,
    Filter,
    FilteringState,
    Grouping,
    GroupingState,
    IntegratedGrouping,
    PagingState,
    RowDetailState,
    SortingState,
    CustomPaging,
    SelectionState,
} from '@devexpress/dx-react-grid'
import {
    Grid as DataGrid,
    GroupingPanel,
    PagingPanel,
    TableColumnResizing,
    Table,
    TableFilterRow,
    TableGroupRow,
    TableHeaderRow,
    Toolbar,
    TableRowDetail,
    DragDropProvider,
    TableSelection,
} from '@devexpress/dx-react-grid-material-ui'
import { ActionVideos } from './tracker_actions_grid_components/ActionVideoDetailPanel'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Papa from 'papaparse'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    Radio,
    RadioGroup,
    TableCell,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { genericActionsFromJson, GenericTrackerAction } from '../../services/generic_action_review'
import { getErrorData } from '../../services/get_error'
import { useDispatch } from 'react-redux'
import { userService } from '../../services/login'
import axios from 'axios'
import {
    createActionMontageUrl,
    getVideoMontage,
    TypedActionForMontage,
    VideoMontageStatuses,
} from '../../services/create_action_clip'
import { useSnackbar } from 'notistack'

const URL = '/api/v2/generic_action_review_rows'
const useStyles = makeStyles((theme) => ({
    actionsTable: {
        height: 1500,
    },
    toggleCellButton: {
        verticalAlign: 'middle',
        display: 'inline-block',
        padding: theme.spacing(1),
    },
}))

const columns = [
    { name: 'RaceDate', title: 'raceDate' },
    { name: 'TrackCode', title: 'trackCode' },
    { name: 'LongTrackName', title: 'longTrackName' },
    { name: 'RaceNumber', title: 'raceNumber' },
    { name: 'RaceType', title: 'raceType' },
    { name: 'ProgramNumber', title: 'programNumber' },
    { name: 'UserName', title: 'userName' },
    { name: 'Type', title: 'action' },
    {
        name: 'ActionTime',
        title: 'actionTime',
        getCellValue: (row: GenericTrackerAction) => row.ActionTime.toJSON(),
    },
    { name: 'ActionVideoTimestamp', title: 'actionVideoTimestamp' },
    {
        name: 'TrackingModule',
        title: 'trackingModule',
        getCellValue: (row: GenericTrackerAction) => row.TrackingModule ?? 'n/a',
    },
]

const defaultColumnWidths = [
    { columnName: 'RaceDate', width: 100 },
    { columnName: 'TrackCode', width: 100 },
    { columnName: 'LongTrackName', width: 200 },
    { columnName: 'RaceNumber', width: 100 },
    { columnName: 'RaceType', width: 100 },
    { columnName: 'ProgramNumber', width: 100 },
    { columnName: 'UserName', width: 100 },
    { columnName: 'Type', width: 350 },
    { columnName: 'ActionTime', width: 200 },
    { columnName: 'ActionVideoTimestamp', width: 100 },
    { columnName: 'TrackingModule', width: 350 },
]

function toVideoClips(videoMontageCsv: string) {
    const response = Papa.parse(videoMontageCsv, {
        header: true,
        dynamicTyping: true,
    })
    if (response.errors.length > 0) {
        return {
            valid: false,
            clips: response.data as TypedActionForMontage[],
            errorMessage: response.errors[0].message,
        }
    }
    if (response?.meta?.fields?.join(',') !== 'runner_event_id,race_state_id,comment,seconds_before,seconds_after') {
        return {
            valid: false,
            clips: response.data as TypedActionForMontage[],
            errorMessage: 'header should be runner_event_id,race_state_id,comment,seconds_before,seconds_after',
        }
    }
    return {
        valid: true,
        clips: response.data as TypedActionForMontage[],
        errorMessage: '',
    }
}

function getActions(
    selection: (string | number)[],
    rows: GenericTrackerAction[],
    videoMontageCheckboxOrCsv: string,
    videoMontageCsv: string
) {
    if (videoMontageCheckboxOrCsv === 'checkbox') {
        const actions = selection
            .map((index) => rows[index])
            .map((row) => ({
                runner_event_id: row.RunnerEventID,
                race_state_id: row.RaceStateID,
                comment: '',
                seconds_before: 9,
                seconds_after: 5,
            }))
        return {
            actions: actions,
            valid: actions.length > 0,
        }
    }
    const { clips, valid } = toVideoClips(videoMontageCsv)
    return {
        actions: clips,
        valid: valid,
    }
}

export const TrackerActionsGrid = () => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const [rows, setRows] = useState([] as GenericTrackerAction[])
    const [loading, setLoading] = useState(false)
    const [lastQuery, setLastQuery] = useState('')
    const pageSizes = [10, 25, 50]
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(0)
    const [sorting, setSorting] = useState([{ columnName: 'ActionTime', direction: 'desc' as 'asc' | 'desc' }])
    const [expandedRowIds, updateExpandedRowIds] = useState([] as number[])
    const [filters, updateFilters] = useState([] as Filter[])
    const [grouping, updateGrouping] = useState([] as Grouping[])
    const [selection, setSelection] = useState([] as (string | number)[])
    const [videoMontagePanelOpen, setVideoMontagePanelOpen] = useState(false)
    const [montageName, setMontageName] = useState('')
    const [videoMontageCreationInProgress, setVideoMontageCreationInProgress] = useState(false)
    const [videoMontageStatus, setVideoMontageStatus] = useState('')
    const [videoMontageProgress, setVideoMontageProgress] = useState(0)
    const [videoMontageCheckboxOrCsv, setVideoMontageCheckboxOrCsv] = useState('checkbox')
    const [videoMontageCsv, setVideoMontageCsv] = useState('')

    const getQueryString = () => {
        let filterQueryString = filters.reduce((acc, { columnName, operation, value }) => {
            return `${acc}&${columnName}Operation=${operation}&${columnName}Value=${value}`
        }, '')
        return `${URL}?Take=${pageSize}&Skip=${pageSize * currentPage}&SortColumn=${
            sorting[0].columnName
        }&SortDirection=${sorting[0].direction}${filterQueryString}`
    }

    const loadData = () => {
        const queryString = getQueryString()
        if (queryString !== lastQuery && !loading) {
            setLoading(true)
            setRows([])
            axios
                .get(queryString, {
                    headers: userService.authHeader(),
                    timeout: 1000 * 90,
                })
                .then((response) => genericActionsFromJson(response.data))
                .then((actions) => {
                    setRows(actions)
                    setLoading(false)
                    setLastQuery(queryString)
                })
                .catch((err) => {
                    if (err.response.data.includes('canceling statement due to user request')) {
                        enqueueSnackbar(
                            'Query timed out. Your filters likely match no actions. Try removing/modifying filters. (Consider changing an equals filter to a contains filter!)',
                            {
                                variant: 'warning',
                                autoHideDuration: 15000,
                            }
                        )
                        setLoading(false)
                    } else {
                        getErrorData(err, dispatch)
                        setLoading(false)
                    }
                })
            setLastQuery(queryString)
        }
    }

    useEffect(() => loadData())

    useEffect(() => {
        const videoMontagePoll = window.setInterval(async () => {
            if (!videoMontageCreationInProgress) {
                return
            }
            const videoMontage = await getVideoMontage(montageName)
            setVideoMontageStatus(videoMontage.status)
            setVideoMontageProgress(videoMontage.progressPercent)
            if (
                videoMontage.status === VideoMontageStatuses.StatusComplete ||
                videoMontage.status === VideoMontageStatuses.StatusFailure
            ) {
                setVideoMontageCreationInProgress(false)
            }
            if (videoMontage.status === VideoMontageStatuses.StatusComplete) {
                const anchor = document.createElement('a')
                anchor.href = videoMontage.url
                anchor.download = videoMontage.url
                // Append to the DOM
                document.body.appendChild(anchor)
                // Trigger `click` event
                anchor.click()
                // Remove element from DOM
                document.body.removeChild(anchor)
            }
        }, 1000)
        return () => {
            clearInterval(videoMontagePoll)
        }
    }, [montageName, videoMontageCreationInProgress])

    return (
        <>
            <Grid item>
                <Button variant={'text'} onClick={() => setVideoMontagePanelOpen(true)}>
                    Create Video Montage
                </Button>
            </Grid>
            <Dialog open={videoMontagePanelOpen} onClose={() => setVideoMontagePanelOpen(false)}>
                <DialogTitle id="customized-dialog-title">Name the montage</DialogTitle>
                <DialogContent dividers>
                    <RadioGroup
                        aria-label="action-assessment"
                        name="controlled-radio-buttons-group"
                        value={videoMontageCheckboxOrCsv}
                        onChange={(event) => setVideoMontageCheckboxOrCsv(event.target.value)}
                    >
                        <FormControlLabel
                            key={'checkbox'}
                            value={'checkbox'}
                            control={<Radio />}
                            label={`Use actions selected by checkbox in grid (${selection.length} clips)`}
                        />
                        <FormControlLabel
                            key={'csv'}
                            value={'csv'}
                            control={<Radio />}
                            label={'Provide a csv of action ids'}
                        />
                    </RadioGroup>
                    {videoMontageCheckboxOrCsv === 'checkbox' && (
                        <Typography variant={'h6'}>Creating a montage of {selection.length} video clips</Typography>
                    )}
                    {videoMontageCheckboxOrCsv === 'csv' && (
                        <>
                            <Typography variant={'h6'}>
                                Creating a montage of{' '}
                                {getActions(selection, rows, videoMontageCheckboxOrCsv, videoMontageCsv).actions
                                    ?.length ?? 0}{' '}
                                video clips
                            </Typography>
                            <TextField
                                onChange={(e) => setVideoMontageCsv(e.target.value)}
                                value={videoMontageCsv}
                                variant={'outlined'}
                                label={
                                    'video montage csv with columns runner_event_id,race_state_id,comment,seconds_before,seconds_after'
                                }
                                size={'medium'}
                                className={'video_montage_csv'}
                                data-testid={'video_montage_csv'}
                                error={!toVideoClips(videoMontageCsv).valid}
                                rows={5}
                                multiline
                                helperText={toVideoClips(videoMontageCsv).errorMessage}
                                fullWidth
                            />
                        </>
                    )}
                    <TextField
                        onChange={(e) => setMontageName(e.target.value)}
                        value={montageName}
                        variant={'outlined'}
                        size={'medium'}
                        data-testid={'montage-name'}
                        label={'Montage Key'}
                    />
                    {videoMontageCreationInProgress && (
                        <Typography variant={'body1'}>Video Montage Creation Status: {videoMontageStatus}</Typography>
                    )}
                    {videoMontageCreationInProgress && (
                        <LinearProgress variant={'determinate'} value={videoMontageProgress * 100} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        size={'medium'}
                        onClick={async () => {
                            const { actions, valid } = getActions(
                                selection,
                                rows,
                                videoMontageCheckboxOrCsv,
                                videoMontageCsv
                            )
                            if (!valid) {
                                return
                            }
                            try {
                                setVideoMontageCreationInProgress(true)
                                await createActionMontageUrl(montageName, actions)
                            } catch (err) {
                                getErrorData(err, dispatch)
                            }
                        }}
                        disabled={videoMontageCreationInProgress}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <DataGrid rows={rows} columns={columns}>
                <DragDropProvider />

                <DataTypeProvider
                    for={[
                        'RaceDate',
                        'RaceNumber',
                        'ProgramNumber',
                        'ActionTime',
                        'ActionVideoTimestamp',
                        'NumOtherTrackers',
                        'NumAgreers',
                    ]}
                    availableFilterOperations={[
                        'equal',
                        'notEqual',
                        'greaterThan',
                        'greaterThanOrEqual',
                        'lessThan',
                        'lessThanOrEqual',
                    ]}
                />
                <DataTypeProvider
                    for={['TrackCode', 'LongTrackName', 'RaceType', 'UserName', 'Type', 'TrackingModule']}
                    availableFilterOperations={['equal', 'contains']}
                />

                <FilteringState filters={filters ?? []} onFiltersChange={(newFilters) => updateFilters(newFilters)} />
                <SortingState sorting={sorting} onSortingChange={setSorting} />

                <PagingState
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    onPageSizeChange={setPageSize}
                />
                <GroupingState grouping={grouping ?? []} onGroupingChange={updateGrouping} />
                <RowDetailState
                    expandedRowIds={expandedRowIds}
                    onExpandedRowIdsChange={(expandedRowIds) => updateExpandedRowIds(expandedRowIds as number[])}
                />
                <SelectionState selection={selection} onSelectionChange={setSelection} />

                <IntegratedGrouping />
                <CustomPaging totalCount={500} />

                <Table />

                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />

                <TableHeaderRow showSortingControls={true} />
                <TableFilterRow showFilterSelector={true} />
                <TableSelection />
                <PagingPanel pageSizes={pageSizes} />

                <TableGroupRow />
                <Toolbar />
                <GroupingPanel showSortingControls={true} />

                <TableRowDetail
                    contentComponent={({ row }: any) => (
                        <ActionVideos row={row as GenericTrackerAction} allActions={rows} />
                    )}
                    toggleCellComponent={ToggleCellComponent}
                />
            </DataGrid>
            {loading && <CircularProgress />}
        </>
    )
}

const ToggleCellComponent = ({
    row,
    expanded,
    onToggle,
}: {
    row: GenericTrackerAction
    expanded: boolean
    onToggle: () => void
}) => {
    const classes = useStyles()
    return (
        <>
            <TableCell>
                <IconButton
                    data-testid={`expand-action-${row.RunnerEventID}-${row.RaceStateID}`}
                    className={`${classes.toggleCellButton} expand-action-${row.RunnerEventID}-${row.RaceStateID}`}
                    onClick={() => onToggle()}
                >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </TableCell>
        </>
    )
}

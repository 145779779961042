import axios from 'axios'
import { userService } from './login'

interface PublicTimeMarkerResponse {
    public_time_marker_time: string
    public_time_marker_video_timestamp: number | null
}

const getRacePublicTimeMarker = async (
    raceDate: string,
    trackCode: string,
    raceNumber: number,
    publicTimeMarkerRaceState: string
) => {
    const url = `/api/v2/race_public_time_marker?race_date=${raceDate}&track_code=${trackCode}&race_number=${raceNumber}&public_time_marker_race_state=${publicTimeMarkerRaceState}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const timeMarkerResponse = response.data as PublicTimeMarkerResponse
    return {
        time: new Date(timeMarkerResponse.public_time_marker_time),
        videoTimestamp: timeMarkerResponse.public_time_marker_video_timestamp,
    }
}

export default getRacePublicTimeMarker

interface RunnerPublicTimeMarkerResponse {
    program_number: string
    time: string
    video_timestamp: number
}

export const getRunnerPublicTimeMarkers = async (
    raceDate: string,
    trackCode: string,
    raceNumber: number,
    genericHistoricalTrackerID: number
) => {
    const url = `/api/v2/runner_public_time_marker?race_date=${raceDate}&track_code=${trackCode}&race_number=${raceNumber}&generic_historical_tracker_id=${genericHistoricalTrackerID}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const timeMarkerResponse = response.data as RunnerPublicTimeMarkerResponse[]
    return timeMarkerResponse.map((marker) => ({
        programNumber: marker.program_number,
        time: new Date(marker.time),
        videoTimestamp: marker.video_timestamp,
    }))
}

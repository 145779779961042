import { getRunnerEventVideo, RunnerEventVideo } from '../../services/get_runner_event_video'
import { getRaceStateVideo, RaceStateVideo } from '../../services/get_race_state_video'
import { getRaceHistoricalVideo, Video } from '../../services/get_race_video_url'
import React, { RefObject, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    FormControlLabel,
    Grid,
    IconButton,
    CircularProgress,
    FormControl,
    RadioGroup,
    Radio,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import { Rnd } from 'react-rnd'
import ReactPlayer from 'react-player'
import { RootStateType } from '../../store'
import { CloudDownload } from '@material-ui/icons'
import { createActionClipUrl } from '../../services/create_action_clip'
import { VideoPlayerWithPictureSnapshot } from './video/VideoPlayerWithPictureSnapshot'
import getRace from '../../services/get_race'
import { Race } from '../../models/race'
import { RunnersSilksAndNumbers } from './RunnersSilksAndNumbers'
import { HarnessLandmarkDisplay } from '../generic_historical_tracker/HarnessLandmarkDisplay'

export interface GenericActionVideoProps {
    RaceID: number
    RaceDate: number
    TrackCode: string
    RaceNumber: number
    RaceStateID: number | null | undefined
    RunnerEventID: number | null | undefined
    HideUserClickVideo?: boolean
    HideFullVideo?: boolean
    Height?: number
    Width?: number
    FullVideoTitleOverride?: string
    UserClickVideoTitleOverride?: string
    InitialPlaybackRate?: string
    Autoplay?: boolean
    onProgress?(event: SyntheticEvent<HTMLVideoElement>): void
}
const calculateSource = (raceState: RaceStateVideo | null, runnerEvent: RunnerEventVideo | null) => {
    if ((raceState?.videoSource?.length ?? 0) > 0) {
        return raceState!.videoSource
    }
    if ((runnerEvent?.videoSource?.length ?? 0) > 0) {
        return runnerEvent!.videoSource
    }
    return 'xpressbet'
}

export const GenericActionVideo = (props: GenericActionVideoProps) => {
    const [runnerEvent, updateRunnerEvent] = useState(null as RunnerEventVideo | null)
    const [raceState, updateRaceState] = useState(null as RaceStateVideo | null)

    const [downloading, updateDownloading] = useState(false)

    const [source, updateSource] = useState(calculateSource(raceState, runnerEvent))
    const [video, updateVideo] = useState(null as Video | null)
    const [race, updateRace] = useState(null as Race | null)

    const [view, updateView] = useState(!props.HideFullVideo ? 'full' : 'user')

    const dispatch = useDispatch()

    const hlsVideoRef = useRef<HTMLVideoElement>() as RefObject<HTMLVideoElement>

    const userIsAdmin = useSelector((state: RootStateType) => state.meStore.me?.team === 'admin')
    useEffect(() => {
        updateSource(calculateSource(raceState, runnerEvent))
    }, [raceState, runnerEvent])

    useEffect(() => {
        let populateRunnerEventVideo = async () => {
            const runnerEventVideo = await getRunnerEventVideo(props.RaceID, props.RunnerEventID!)
            updateRunnerEvent(runnerEventVideo)
        }
        if (props.RunnerEventID) {
            populateRunnerEventVideo()
        }
    }, [props.RaceID, props.RunnerEventID])

    useEffect(() => {
        let populateRaceStateVideo = async () => {
            const raceStateVideo = await getRaceStateVideo(props.RaceStateID!)
            updateRaceState(raceStateVideo)
        }
        if (props.RaceStateID) {
            populateRaceStateVideo()
        }
    }, [props.RaceStateID])

    useEffect(() => {
        const fetchVideo = async () => {
            const video = await getRaceHistoricalVideo(
                props.TrackCode,
                props.RaceDate,
                props.RaceNumber,
                source,
                dispatch
            )
            if (!video) {
                return
            }
            updateVideo(video)
        }
        if (raceState || runnerEvent) {
            fetchVideo()
        }
    }, [props.TrackCode, props.RaceDate, props.RaceNumber, dispatch, raceState, runnerEvent, source])

    useEffect(() => {
        const fetchRace = async () => {
            const fullRace = await getRace(props.RaceDate.toString(10), props.TrackCode, props.RaceNumber)
            updateRace(fullRace)
        }
        fetchRace()
    }, [props.TrackCode, props.RaceDate, props.RaceNumber])

    useEffect(() => {
        const scrubToTimestamp = () => {
            hlsVideoRef!.current!.currentTime = runnerEvent?.videoTimestamp ?? raceState?.videoTimestamp ?? 0
        }
        hlsVideoRef?.current?.addEventListener('loadedmetadata', scrubToTimestamp)
        return () => hlsVideoRef?.current?.removeEventListener('loadedmetadata', scrubToTimestamp)
    }, [hlsVideoRef, runnerEvent, raceState])

    const videoStartTime = () => {
        if (!video) {
            return 0
        }
        if (!(runnerEvent || raceState)) {
            return 0
        }

        const bufferSecondsToSeeHorseAction = 10

        if (runnerEvent && runnerEvent.videoTimestamp > 0) {
            return runnerEvent.videoTimestamp - bufferSecondsToSeeHorseAction
        }
        if (raceState && raceState.videoTimestamp > 0) {
            return raceState.videoTimestamp - bufferSecondsToSeeHorseAction
        }

        const actionTime = (raceState?.time ?? runnerEvent?.createdAt)!
        const videoStartTime = Date.parse(video.StartTime)

        return (actionTime.getTime() - videoStartTime) / 1000 - bufferSecondsToSeeHorseAction
    }

    const postTimestamp = () => {
        if (!video) {
            return 0
        }
        if (!race) {
            return 0
        }
        const videoStartTime = Date.parse(video.StartTime)

        return (race.actualPostTime.getTime() - videoStartTime) / 1000
    }

    return (
        <>
            {!props.HideFullVideo && !props.HideUserClickVideo && (
                <Grid item>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="view"
                            name="action-view-selection"
                            value={view}
                            onChange={(event) => updateView(event.target.value)}
                            row
                        >
                            <FormControlLabel
                                value="full"
                                control={<Radio />}
                                label={props.FullVideoTitleOverride ?? 'Video clip of action'}
                            />
                            <FormControlLabel
                                value="user"
                                control={<Radio />}
                                label={props.UserClickVideoTitleOverride ?? 'Screen recording of action'}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            )}

            {view === 'user' && (
                <Grid item>
                    <Grid container direction={'column'} spacing={2}>
                        <Grid item>
                            {(runnerEvent?.videoUrl ?? raceState?.videoUrl !== undefined) && (
                                <Rnd
                                    default={{
                                        x: 0,
                                        y: 0,
                                        width: props.Width ?? 400,
                                        height: props.Height ?? 300,
                                    }}
                                    disableDragging={true}
                                    lockAspectRatio={4 / 3}
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <ReactPlayer
                                        controls
                                        playing
                                        url={runnerEvent?.videoUrl ?? raceState?.videoUrl ?? ''}
                                        height={'100%'}
                                        width={'100%'}
                                    />
                                </Rnd>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {view === 'full' && (
                <>
                    {race?.country === 'FRANCE' && (
                        <Grid item>
                            <FormControl>
                                <InputLabel id="video-source-label">Video Source</InputLabel>
                                <Select
                                    labelId="video-source"
                                    id="video-source"
                                    value={source}
                                    label="Video Source"
                                    onChange={(event: any) => updateSource(event.target.value as string)}
                                >
                                    <MenuItem value={'xpressbet'}>Normal</MenuItem>
                                    <MenuItem value={'equidia'}>HD</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item>
                        {video && (
                            <VideoPlayerWithPictureSnapshot
                                historical={true}
                                url={video.URL + `#t=${videoStartTime()}`}
                                onProgress={(event) => {
                                    if (props.onProgress) {
                                        props.onProgress(event)
                                    }
                                }}
                                width={props.Width ?? 400}
                                height={props.Height ?? 300}
                                scrubLocations={[
                                    {
                                        timestamp: videoStartTime(),
                                        timestampName: 'Action Time - 10 seconds',
                                    },
                                    {
                                        timestamp: postTimestamp(),
                                        timestampName: 'Post - 10 seconds',
                                    },
                                ]}
                                initialPlaybackRate={props.InitialPlaybackRate}
                                autoplay={props.Autoplay}
                                format={video.Format}
                                disableControls={false}
                            />
                        )}
                    </Grid>
                    {userIsAdmin && (
                        <Grid item>
                            {!downloading && (
                                <IconButton
                                    onClick={async () => {
                                        updateDownloading(true)
                                        const url = await createActionClipUrl(
                                            props.RaceID,
                                            props.RaceStateID,
                                            props.RunnerEventID
                                        )
                                        updateDownloading(false)
                                        const anchor = document.createElement('a')
                                        anchor.href = url
                                        anchor.download = url

                                        // Append to the DOM
                                        document.body.appendChild(anchor)

                                        // Trigger `click` event
                                        anchor.click()

                                        // Remove element from DOM
                                        document.body.removeChild(anchor)
                                    }}
                                >
                                    <CloudDownload />
                                </IconButton>
                            )}
                            {downloading && <CircularProgress />}
                        </Grid>
                    )}
                    {race && (
                        <Grid item>
                            <RunnersSilksAndNumbers race={race!} />
                        </Grid>
                    )}
                    {race && race.raceType === 'HARNESS' && (
                        <HarnessLandmarkDisplay trackCode={race.trackCode} runners={race.runners} />
                    )}
                    {race && race.raceType === 'TROT' && race.startType === 'AUTOMOBILE' && (
                        <HarnessLandmarkDisplay trackCode={race.trackCode} distance={race.distance} />
                    )}
                </>
            )}
        </>
    )
}

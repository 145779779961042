import React from 'react'
import { Race } from '../../../models/race'
import { GenericRealTimeTracker } from '../../../services/generic_real_time_tracker'
import { createRaceState } from '../../../services/create_race_state'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from '../../../store'
import { Button, Grid } from '@material-ui/core'
import { AudioHeader } from './AudioHeader'
import { AudioEmittedActionsList } from './AudioEmittedActionsList'
import { AudioCommandOptions } from './AudioCommandOptions'
import { AudioEventsEmitter } from './AudioEventsEmitter'

export const AudioRaceCard = ({
    race,
    tracker,
    updateUpcomingRaces,
}: {
    race: Race
    tracker: GenericRealTimeTracker
    updateUpcomingRaces: () => void
}) => {
    const dispatch = useDispatch()
    const latency = useSelector((state: RootStateType) => state.latencyStore?.latencyInSeconds ?? 0)
    const startTrackingRaceState = `${tracker.TrackingType.toUpperCase()}_START_TRACKING`
    const isTracking = race.hasRaceState(startTrackingRaceState)

    const emitStartTracking = async () => {
        await createRaceState(
            {
                raceID: race.id,
                type: startTrackingRaceState,
                latencyInSeconds: latency,
                practiceMode: tracker.PracticeMode,
                videoTimestamp: null,
                videoSource: 'on-track',
                genericHistoricalTrackerID: null,
                genericRealTimeTrackerID: tracker.ID,
            },
            dispatch
        )
        updateUpcomingRaces()
    }

    return (
        <Grid container direction={'column'} spacing={2}>
            <Grid item container direction={'row'} spacing={1} alignItems={'center'}>
                <AudioHeader race={race} />
            </Grid>
            {!isTracking && (
                <Grid item>
                    <Button color={'primary'} variant="contained" size={'small'} onClick={emitStartTracking}>
                        Start Tracking
                    </Button>
                </Grid>
            )}
            {isTracking && (
                <Grid item container direction={'column'} spacing={1} justifyContent={'center'}>
                    <AudioCommandOptions tracker={tracker} race={race} />
                    <AudioEventsEmitter race={race} tracker={tracker} updateUpcomingRaces={updateUpcomingRaces} />
                    <AudioEmittedActionsList race={race} />
                </Grid>
            )}
        </Grid>
    )
}

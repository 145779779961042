import axios, { isAxiosError } from 'axios'
import { userService } from './login'
import { Dispatch } from 'react'
import { getErrorData } from './get_error'

export interface HarnessLandmark {
    ImageURL: string
    LandmarkName: string
    LandmarkDescription: string
    LandmarkLocationImageUrl: string
    GateWidth: number
    HasEarlyLandmark: boolean
    EarlyLandmarkName: string
    EarlyLandmarkDescription: string
    EarlyImageURL: string
    EarlyLandmarkLocationImageUrl: string
}
export interface HarnessLandmarkJson {
    image_url: string
    landmark_name: string
    landmark_description: string
    landmark_location_image_url: string
    gate_width: number
    has_early_landmark: boolean
    early_landmark_name: string
    early_landmark_description: string
    early_image_url: string
    early_landmark_location_image_url: string
}

const getHarnessLandmark = async (trackCode: string, distance: number | null, dispatch: Dispatch<any>) => {
    let url = `/api/v2/harness_landmark?track_code=${trackCode}`
    if (distance) {
        url += `&distance=${distance}`
    }

    try {
        const response = await axios.get(url, {
            headers: userService.authHeader(),
            timeout: 1000 * 30,
        })
        const landmarkJson = response.data as HarnessLandmarkJson
        return {
            ImageURL: landmarkJson.image_url,
            LandmarkName: landmarkJson.landmark_name,
            LandmarkDescription: landmarkJson.landmark_description,
            LandmarkLocationImageUrl: landmarkJson.landmark_location_image_url,
            GateWidth: landmarkJson.gate_width,
            HasEarlyLandmark: landmarkJson.has_early_landmark,
            EarlyLandmarkName: landmarkJson.early_landmark_name,
            EarlyLandmarkDescription: landmarkJson.early_landmark_description,
            EarlyImageURL: landmarkJson.early_image_url,
            EarlyLandmarkLocationImageUrl: landmarkJson.early_landmark_location_image_url,
        } as HarnessLandmark
    } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
            return null
        }

        getErrorData(error, dispatch)
        return null
    }
}

export default getHarnessLandmark

import React from 'react'
import { Race } from '../../../models/race'
import { Collapse, Grid, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

export const AudioEmittedActionsList = ({ race }: { race: Race }) => {
    const [actionsOpen, setActionsOpen] = React.useState(false)
    return (
        <>
            <Grid item>
                <label onClick={() => setActionsOpen(!actionsOpen)}>
                    Actions
                    {actionsOpen ? <ExpandLess /> : <ExpandMore />}
                </label>
                <Collapse in={actionsOpen} timeout="auto" unmountOnExit>
                    {actionsOpen && (
                        <>
                            <Typography>Race States</Typography>
                            <ul>
                                {race.raceStates
                                    .sort((a, b) => a.time.getTime() - b.time.getTime())
                                    .map((raceState) => (
                                        <li key={raceState.id}>
                                            {raceState.type} - {raceState.time.toString()}
                                        </li>
                                    ))}
                            </ul>
                            <Typography>Runner Events</Typography>
                            <ul>
                                {race.runners
                                    .flatMap((runner) =>
                                        runner.runnerEvents.map((runnerEvent) => ({
                                            runnerEvent,
                                            runner,
                                        }))
                                    )
                                    .sort(
                                        (a, b) => a.runnerEvent.createdAt.getTime() - b.runnerEvent.createdAt.getTime()
                                    )
                                    .map((obj) => (
                                        <li key={obj.runnerEvent.id}>
                                            {obj.runner.programNumber} - {obj.runnerEvent.type} -{' '}
                                            {obj.runnerEvent.createdAt.toString()}
                                        </li>
                                    ))}
                            </ul>
                        </>
                    )}
                </Collapse>
            </Grid>
        </>
    )
}

import React, { useState } from 'react'
import { Button, Grid, TextField, Tooltip } from '@material-ui/core'
import { approveRejectAssessmentResponse, UnifiedAssessment } from '../../services/tracker_assessment'

export interface Props {
    Assessment: UnifiedAssessment
    OnApprovedRejected: () => void
}

export const AssessmentResponseApproveReject = ({ Assessment, OnApprovedRejected }: Props) => {
    const [comment, setComment] = useState('')

    if (!Assessment.RaceTrackingAssessmentResponseID) {
        return null
    }

    let approveTooltip = ''
    let rejectTooltip = ''
    if (Assessment.Approved) {
        approveTooltip = 'Already approved mistake'
        rejectTooltip = 'Already approved mistake'
    } else if (Assessment.Rejected) {
        approveTooltip = 'Already rejected mistake'
        rejectTooltip = 'Already rejected mistake'
    } else if (!commentIsValid(comment)) {
        rejectTooltip = 'You must provide a comment to reject'
    }

    return (
        <Grid container direction={'column'} spacing={4}>
            <Grid item>{}</Grid>
            <Grid item key={'comment'}>
                <TextField
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                    variant={'outlined'}
                    label={'Comment (required for rejection)'}
                    size={'medium'}
                    className={'comment'}
                    data-testid={`assessment-${Assessment.RaceTrackingRaceStateAssessmentID}-rs-${Assessment.RaceTrackingRunnerEventAssessmentID}-re`}
                    rows={4}
                    multiline
                />
            </Grid>
            <Grid item key={'approve'}>
                <Tooltip title={approveTooltip}>
                    <span>
                        <Button
                            size={'large'}
                            color={'primary'}
                            disabled={approveTooltip !== ''}
                            variant={'contained'}
                            onClick={async () => {
                                await approveRejectAssessmentResponse(
                                    Assessment.RaceTrackingAssessmentResponseID!,
                                    true,
                                    false,
                                    comment
                                )
                                setComment('')
                                OnApprovedRejected()
                            }}
                        >
                            Approve
                        </Button>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item key={'reject'}>
                <Tooltip title={rejectTooltip}>
                    <span>
                        <Button
                            size={'large'}
                            color={'secondary'}
                            variant={'contained'}
                            disabled={rejectTooltip !== ''}
                            onClick={async () => {
                                await approveRejectAssessmentResponse(
                                    Assessment.RaceTrackingAssessmentResponseID!,
                                    false,
                                    true,
                                    comment
                                )
                                setComment('')
                                OnApprovedRejected()
                            }}
                        >
                            Reject
                        </Button>
                    </span>
                </Tooltip>
            </Grid>
        </Grid>
    )
}

const commentIsValid = (comment: string): boolean => {
    return comment.length > 3
}

import React, { useRef } from 'react'
import { Race } from '../../../models/race'
import { GenericRealTimeTracker } from '../../../services/generic_real_time_tracker'
import { createRaceState } from '../../../services/create_race_state'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from '../../../store'
import { Grid, Typography } from '@material-ui/core'
import { AudioTranscription, TextReturnedResult } from './AudioTranscription'
import { addRunnerEvent } from '../../../services/create_runner_event'
import { parseRunnerEventAudio } from './ParseAudio'
import { successDing } from './SuccessDing'
import { saveFile } from '../../../services/save_file'

export const AudioEventsEmitter = ({
    race,
    tracker,
    updateUpcomingRaces,
}: {
    race: Race
    tracker: GenericRealTimeTracker
    updateUpcomingRaces: () => void
}) => {
    const dispatch = useDispatch()
    const latency = useSelector((state: RootStateType) => state.latencyStore?.latencyInSeconds ?? 0)
    const userName = useSelector((state: RootStateType) => state?.meStore?.me?.userName ?? '')

    const [fullTranscript, setFullTranscript] = React.useState<any[]>([])
    const [rollingRunner, setRollingRunner] = React.useState<string>('')
    const [mostRecentCompleteMatch, setMostRecentCompleteMatch] = React.useState<string>('')
    const [currentTranscript, setCurrentTranscript] = React.useState<string>('')
    const [currentTranscriptMatchesAction, setCurrentTranscriptMatchesAction] = React.useState<boolean>(false)
    const [audioStartTime, setAudioStartTime] = React.useState<Date | null>(null)

    let audioStartTimeRef = useRef() as any
    audioStartTimeRef.current = audioStartTime
    let fullTranscriptRef = useRef() as any
    fullTranscriptRef.current = fullTranscript
    let rollingRunnerRef = useRef() as any
    rollingRunnerRef.current = rollingRunner
    let mostRecentCompleteMatchRef = useRef() as any
    mostRecentCompleteMatchRef.current = mostRecentCompleteMatch

    const onStopRecording = async (blob: any) => {
        const audioStartTimeString = audioStartTimeRef.current?.toISOString()?.replace(/(\.\d{3})|[^\d]/g, '')
        const transcriptData = {
            recordingStartTime: audioStartTimeRef.current,
            recordingDoneTime: new Date(),
            fullTranscript: fullTranscriptRef.current,
        }
        const transcriptBlob = new Blob([JSON.stringify(transcriptData)], {
            type: 'text/json',
        })
        saveFile(
            `lde_transcriptions/${race.raceDate}/${race.trackCode}/${race.raceNumber}/${userName}/${audioStartTimeString}/audio.webm`,
            'audio/webm',
            Buffer.from(await blob.arrayBuffer())
        )
        saveFile(
            `lde_transcriptions/${race.raceDate}/${race.trackCode}/${race.raceNumber}/${userName}/${audioStartTimeString}/transcription.json`,
            'text/json',
            Buffer.from(await transcriptBlob.arrayBuffer())
        )
    }

    const onTextReceived = async ({ transcript, isFinal, startTime, endTime, stopRecording }: TextReturnedResult) => {
        const now = new Date()
        const lag = (now.getTime() - audioStartTimeRef.current.getTime()) / 1000 - endTime
        setFullTranscript([
            ...fullTranscriptRef.current,
            {
                transcript,
                isFinal,
                startTime,
                endTime,
                timestamp: now,
                latency: lag,
            },
        ])

        let { foundRaceState, raceState, doneTracking, matchedTranscriptRaceState } = matchRaceState(
            transcript,
            tracker,
            race,
            mostRecentCompleteMatchRef.current
        )
        if (foundRaceState) {
            setCurrentTranscriptMatchesAction(true)
            setCurrentTranscript(matchedTranscriptRaceState!)
            setMostRecentCompleteMatch(matchedTranscriptRaceState!)
            setRollingRunner('')
            successDing.play()
            if (doneTracking) {
                await stopRecording()
            }
            emitRaceState(race, tracker, raceState!, dispatch, latency, updateUpcomingRaces)
            setTimeout(() => {
                setMostRecentCompleteMatch('')
            }, 2500)
            return
        }

        let { foundRunnerEvent, number, runnerEvent, matchedTranscriptRunnerEvent } = matchRunnerEvent(
            transcript,
            tracker,
            race,
            rollingRunnerRef.current,
            mostRecentCompleteMatchRef.current,
            setRollingRunner
        )
        if (foundRunnerEvent) {
            setCurrentTranscriptMatchesAction(true)
            setCurrentTranscript(matchedTranscriptRunnerEvent!)
            setMostRecentCompleteMatch(matchedTranscriptRunnerEvent!)
            successDing.play()
            emitRunnerEvent(race, tracker, number.toString(), runnerEvent!, dispatch, latency, updateUpcomingRaces)
            setTimeout(() => {
                setMostRecentCompleteMatch('')
            }, 2500)
            return
        }

        if (transcript === 'test') {
            setCurrentTranscriptMatchesAction(true)
            setCurrentTranscript(transcript)
            setMostRecentCompleteMatch('')
            successDing.play()
            return
        }

        setCurrentTranscriptMatchesAction(false)
        setCurrentTranscript(transcript)
        return
    }

    return (
        <>
            <Grid item style={{ textAlign: 'center' }}>
                <AudioTranscription
                    keywords={getKeywords(tracker, race)}
                    onStartRecording={() => setAudioStartTime(new Date())}
                    onStopRecording={onStopRecording}
                    onTextReturned={onTextReceived}
                />
            </Grid>
            <Grid item style={{ textAlign: 'center' }}>
                <Typography variant={'h3'} color={currentTranscriptMatchesAction ? 'primary' : 'secondary'}>
                    {currentTranscript}
                </Typography>
            </Grid>
        </>
    )
}

const getKeywords = (tracker: GenericRealTimeTracker, race: Race) => {
    const raceStates = tracker.GenericRealTimeTrackerRaceStateGroups.filter(
        (rsg) =>
            (rsg.RaceType === null || rsg.RaceType === race.raceType) &&
            (rsg.SubRaceType === null || rsg.SubRaceType === race.raceSubType) &&
            (rsg.StartType === null || rsg.StartType === race.startType)
    )
        .flatMap((g) => g.GenericRealTimeTrackerRaceStates)
        .map((raceState) => raceState.ButtonText)

    const runnerEvents = tracker.GenericRealTimeTrackerRunnerEvents.filter(
        (re) =>
            (re.RaceType === null || re.RaceType === race.raceType) &&
            (re.SubRaceType === null || re.SubRaceType === race.raceSubType) &&
            (re.StartType === null || re.StartType === race.startType)
    ).map((g) => g.RunnerEvent)
    return [...raceStates, ...runnerEvents]
}

const emitRaceState = async (
    race: Race,
    tracker: GenericRealTimeTracker,
    raceState: string,
    dispatch: any,
    latency: number,
    updateUpcomingRaces: () => void
) => {
    await createRaceState(
        {
            raceID: race.id,
            type: raceState,
            latencyInSeconds: latency,
            practiceMode: tracker.PracticeMode,
            videoTimestamp: null,
            videoSource: 'on-track',
            genericHistoricalTrackerID: null,
            genericRealTimeTrackerID: tracker.ID,
        },
        dispatch
    )
    updateUpcomingRaces()
}

const emitRunnerEvent = async (
    race: Race,
    tracker: GenericRealTimeTracker,
    runnerNumber: string,
    runnerEvent: string,
    dispatch: any,
    latency: number,
    updateUpcomingRaces: () => void
) => {
    const runner = race.runners.find((r) => r.programNumber === runnerNumber.toString())
    if (!runner) {
        return
    }
    await addRunnerEvent(
        {
            runner: runner,
            eventType: runnerEvent,
            raceID: race.id,
            practiceMode: tracker.PracticeMode,
            videoTimestamp: null,
            videoSource: 'on-track',
            genericRealTimeTrackerID: tracker.ID,
        },
        dispatch
    )
    updateUpcomingRaces()
}

const matchRaceState = (
    transcript: string,
    tracker: GenericRealTimeTracker,
    race: Race,
    mostRecentCompleteMatch: string
) => {
    const words = transcript.split(' ')
    const raceStates = tracker.GenericRealTimeTrackerRaceStateGroups.filter(
        (rsg) =>
            (rsg.RaceType === null || rsg.RaceType === race.raceType) &&
            (rsg.SubRaceType === null || rsg.SubRaceType === race.raceSubType) &&
            (rsg.StartType === null || rsg.StartType === race.startType)
    ).flatMap((g) => g.GenericRealTimeTrackerRaceStates)
    const raceState = raceStates.find(
        (rs) => words.includes(rs.ButtonText) && rs.ButtonText !== mostRecentCompleteMatch
    )
    return {
        foundRaceState: !!raceState,
        raceState: raceState?.RaceState,
        doneTracking: raceState?.IsStopTracking,
        matchedTranscriptRaceState: raceState?.ButtonText,
    }
}

const matchRunnerEvent = (
    transcript: string,
    tracker: GenericRealTimeTracker,
    race: Race,
    rollingRunnerNumber: string,
    mostRecentCompleteMatch: string,
    setRollingRunner: (number: string) => void
) => {
    const { runnerNumber, runnerEvent, buttonText } = parseRunnerEventAudio(transcript, race, tracker)

    const number = runnerNumber ?? rollingRunnerNumber
    const newTranscript = `${number} ${buttonText}`
    const transcriptIsSame = newTranscript === mostRecentCompleteMatch
    if (number) {
        setRollingRunner(number)
    }

    return {
        foundRunnerEvent: !!number && !!runnerEvent && !transcriptIsSame,
        runnerEvent: runnerEvent?.RunnerEvent,
        number: number,
        matchedTranscriptRunnerEvent: newTranscript,
    }
}

import { Button, Grid, Tooltip } from '@material-ui/core'
import React from 'react'
import { GenericActionReviewCommentForUser } from '../../services/generic_action_review'
import { GenericActionVideo } from '../common/GenericActionVideo'

export const VideoAcknowledgement = ({
    comment,
    onAcknowledgedComment,
    hideAcknowledgement,
}: {
    hideAcknowledgement?: boolean
    comment: GenericActionReviewCommentForUser
    onAcknowledgedComment: (commentID: number) => void
}) => {
    return (
        <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            spacing={6}
            className={`details-action-${comment.RunnerEventID}-${comment.RaceStateID}`}
            data-testid={`details-action-${comment.RunnerEventID}-${comment.RaceStateID}`}
        >
            <GenericActionVideo {...comment} Height={600} Width={800} />
            {!hideAcknowledgement && (
                <Grid item>
                    <Tooltip title={comment.Acknowledged ? 'Already acknowledged' : ''}>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => onAcknowledgedComment(comment.GenericActionCommentID)}
                            disabled={comment.Acknowledged}
                        >
                            Acknowledge Learning
                        </Button>
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    )
}

import axios from 'axios'
import { userService } from './login'
export interface TypedActionForMontage {
    runner_event_id: number | null
    race_state_id: number | null
    comment: string
    seconds_before: number
    seconds_after: number
}

export const createActionClipUrl = async (
    raceID: number,
    raceStateID: number | null | undefined,
    runnerEventID: number | null | undefined
) => {
    const url = '/api/v2/create_action_clip_url'
    const data = {
        race_id: raceID,
        race_state_id: raceStateID,
        runner_event_id: runnerEventID,
    }
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return response.data
}

export const createActionMontageUrl = async (clipKey: string, actionsForMontage: TypedActionForMontage[]) => {
    const url = '/api/v2/create_video_montage_job'
    const data = {
        key: clipKey,
        actions_for_montage: actionsForMontage,
    }
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 60 * 10 /*10 minutes*/,
    })
    return response.data
}

export const getVideoMontage = async (clipKey: string) => {
    const url = `/api/v2/video_montage?key=${clipKey}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 60 * 10 /*10 minutes*/,
    })
    return {
        status: response.data.status,
        progressPercent: response.data.progress_percent,
        url: response.data.url,
    }
}

export const VideoMontageStatuses = {
    Submitted: 'SUBMITTED',
    Started: 'STARTED',
    StatusInProgress: 'IN_PROGRESS',
    StatusComplete: 'COMPLETE',
    StatusFailure: 'FAILURE',
}

import React from 'react'
import { Race } from '../../models/race'
import { GenericHistoricalTracker } from '../../services/generic_historical_tracker'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { GenericTrackerSingleRunnerButtonsIconList } from './GenericHistoricalTrackerSingleRunnerButtonsIconList'

export interface GenericHistoricalTrackerRunnerButtonsProps {
    Tracker: GenericHistoricalTracker
    Race: Race
    VideoTimestamp: number | null
    VideoSource: string
    ShowJockeySilks: boolean
    UpdateRace: () => void
    RunnerButtonsWidth: number
    SortByDrawNumber: boolean
    ShowRunnerNames: boolean
    RunnerNumberFilter: string[]
    RunnerButtonFilter: string[]
}

const useStyles = makeStyles((theme) => ({
    slider: {
        height: 200,
    },
    runnerNumber: {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1.5rem !important',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    buttonContainer: ({ runnerButtonsWidth }: { runnerButtonsWidth: number }) => ({
        width: 60 * runnerButtonsWidth,
    }),
    popupContainer: {
        padding: theme.spacing(1),
    },
}))

export const GenericHistoricalTrackerRunnerButtons = ({
    Tracker,
    Race,
    VideoTimestamp,
    VideoSource,
    ShowJockeySilks,
    SortByDrawNumber,
    UpdateRace,
    RunnerButtonsWidth,
    RunnerNumberFilter,
    ShowRunnerNames,
    RunnerButtonFilter,
}: GenericHistoricalTrackerRunnerButtonsProps) => {
    const classes = useStyles({ runnerButtonsWidth: RunnerButtonsWidth })
    const orderedRunners = [...Race.runners]
        .sort((a, b) => {
            if (SortByDrawNumber) {
                if (Tracker.ReverseRunnerButtons) {
                    return b.drawNumber - a.drawNumber
                } else {
                    return a.drawNumber - b.drawNumber
                }
            }

            const aNumber = parseInt(a.programNumber, 10)
            const bNumber = parseInt(b.programNumber, 10)
            if (Tracker.ReverseRunnerButtons) {
                return bNumber - aNumber
            } else {
                return aNumber - bNumber
            }
        })
        .filter((r) => RunnerNumberFilter.length === 0 || RunnerNumberFilter.includes(r.programNumber))
    return (
        <>
            <Grid container direction={'row'} spacing={1} justifyContent={'center'} className={classes.buttonContainer}>
                {orderedRunners.map((r) => (
                    <Grid item key={r.programNumber}>
                        <GenericTrackerSingleRunnerButtonsIconList
                            tracker={Tracker}
                            race={Race}
                            runner={r}
                            videoTimestamp={VideoTimestamp}
                            videoSource={VideoSource}
                            showJockeySilks={ShowJockeySilks}
                            showRunnerNames={ShowRunnerNames}
                            runnerButtonsFilter={RunnerButtonFilter}
                            updateRace={UpdateRace}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

import { getUpcomingRaces, UpcomingRacesParams } from './get_upcoming_races'
import CONFIG from '../settings/config'
import { Race } from '../models/race'

export const pollUpcomingRaces = (
    { dispatch, mtp, raceType, onlyAssigned, trackingType, country }: UpcomingRacesParams,
    onRacesUpdated: (races: Race[]) => void
) => {
    if (localStorage.getItem('user')) {
        getUpcomingRaces({
            dispatch,
            mtp,
            raceType,
            onlyAssigned,
            trackingType,
            country,
            afterRecall: true,
        }).then((races) => onRacesUpdated(races))
    }

    return window.setInterval(async () => {
        if (localStorage.getItem('user')) {
            const races = await getUpcomingRaces({
                dispatch,
                mtp,
                raceType,
                onlyAssigned,
                trackingType,
                country,
                afterRecall: true,
            })
            onRacesUpdated(races)
        }
    }, CONFIG.RACES_POLL_INTERVAL)
}

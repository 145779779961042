import { Race } from '../../../models/race'
import { GenericRealTimeTracker } from '../../../services/generic_real_time_tracker'

const numberMapping = {
    one: 1,
    two: 2,
    to: 2,
    too: 2,
    three: 3,
    four: 4,
    for: 4,
    five: 5,
    six: 6,
    seven: 7,
    eight: 8,
    nine: 9,
    ten: 10,
    eleven: 11,
    twelve: 12,
    thirteen: 13,
    fourteen: 14,
    fifteen: 15,
    sixteen: 16,
    seventeen: 17,
    eighteen: 18,
    nineteen: 19,
    twenty: 20,
}

export const parseRunnerEventAudio = (transcription: string, race: Race, tracker: GenericRealTimeTracker) => {
    const words = transcription.split(' ')
    const runnerNumber = words
        ?.map((word) => numberMapping[word] ?? parseInt(word, 10))
        ?.find((number) => number !== undefined && race.runners.some((r) => r.programNumber === number.toString()))
        ?.toString()

    const wordsMatchedToRunnerEvents = words.map((word) => {
        return {
            word: word,
            runnerEvent: tracker.GenericRealTimeTrackerRunnerEvents.filter(
                (re) =>
                    (re.RaceType === null || re.RaceType === race.raceType) &&
                    (re.SubRaceType === null || re.SubRaceType === race.raceSubType) &&
                    (re.StartType === null || re.StartType === race.startType)
            ).find((e) => e.ButtonText === word),
        }
    })

    const firstMatch = wordsMatchedToRunnerEvents.find((e) => e.runnerEvent !== undefined)

    return {
        runnerNumber,
        runnerEvent: firstMatch?.runnerEvent,
        buttonText: firstMatch?.word,
    }
}

export const numbers = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
    'twenty',
]

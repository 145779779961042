import axios from 'axios'
import { userService } from './login'
import { getRealTimeTrackers as realTimeTrackers } from '../reducers/get_real_time_trackers'
import CONFIG from '../settings/config'
import { Dispatch } from 'redux'
import { getErrorData } from './get_error'

export interface GenericRealTimeTracker {
    ID: number
    Name: string
    TrackingType: string
    GuideURL: string
    RaceType: string | null
    Country: string
    GenericRealTimeTrackerRaceStateGroups: GenericRealTimeTrackerRaceStateGroup[]
    GenericRealTimeTrackerRunnerEvents: GenericRealTimeTrackerRunnerEvent[]
    GenericRealTimeTrackerRunnerEventGroups: GenericRealTimeTrackerRunnerEventGroup[]
    VideoSources: VideoSource[]
    IsActive: boolean
    RunnerButtonsEventType: string
    UseDropDownForRunnerButtons: boolean
    PracticeMode: boolean
    TimedMessages: GenericRealTimeTrackerTimedMessage[]
    IsGrader: boolean
}

export interface GenericRealTimeTrackerRaceStateGroup {
    Name: string
    IsOptionsGroup: boolean
    RaceType: string | null
    SubRaceType: string | null
    StartType: string | null
    ButtonColumn: number
    ButtonColumnOrder: number | null
    GenericRealTimeTrackerRaceStates: GenericRealTimeTrackerRaceState[]
}

export interface GenericRealTimeTrackerRaceState {
    Name: string
    RaceState: string
    ButtonText: string
    Explanation: string
    IsSecondaryButton: boolean
    IsStopTracking: boolean
    RequireConfirmation: boolean
    Order: number
    HorizontalOrder: number
    IsScored: boolean
    IsCorrectnessScore: boolean
    IsFScore: boolean
    ScoreWeight: number | null
}

export interface GenericRealTimeTrackerRunnerEventGroup {
    Name: string
    IsOptionsGroup: boolean
    RaceType: string | null
    SubRaceType: string | null
    StartType: string | null
    GenericRealTimeTrackerRunnerEvents: GenericRealTimeTrackerRunnerEvent[]
}

export interface GenericRealTimeTrackerRunnerEvent {
    Name: string
    RaceType: string | null
    SubRaceType: string | null
    StartType: string | null
    IsCheckbox: boolean
    RunnerEvent: string
    RunnerEventChecked: string | null
    IconChecked: string | null
    ButtonText: string
    ShortTextButton: string
    Explanation: string
    Order: number
    IsScored: boolean
    IsCorrectnessScore: boolean
    IsFScore: boolean
    ScoreWeight: number | null
}

export const GenericRealTimeTrackerRecall = (tracker: GenericRealTimeTracker) => {
    return `${tracker.TrackingType.toUpperCase()}_RECALL`
}

export const YesHasRunnerEvent = (runnerEvent: GenericRealTimeTrackerRunnerEvent) => {
    if (runnerEvent.IsCheckbox) {
        return runnerEvent.RunnerEventChecked
    }
    return runnerEvent.RunnerEvent
}

export interface GenericRealTimeTrackerJson {
    id: number
    name: string
    tracking_type: string
    guide_url: string
    race_type: string | null
    country: string
    generic_real_time_tracker_race_state_groups: GenericRealTimeTrackerRaceStateGroupJson[]
    generic_real_time_tracker_runner_events: GenericRealTimeTrackerRunnerEventJson[]
    generic_real_time_tracker_runner_event_groups: GenericRealTimeTrackerRunnerEventGroupJson[]
    video_sources: VideoSourceJson[]
    is_active: boolean
    runner_buttons_event_type: string
    practice_mode: boolean
    use_drop_down_for_runner_buttons: boolean
    timed_messages: GenericRealTimeTrackerTimedMessageJson[]
    is_grader: boolean
}

interface GenericRealTimeTrackerRaceStateGroupJson {
    name: string
    is_options_group: boolean
    race_type: string | null
    sub_race_type: string | null
    start_type: string | null
    button_column: number
    button_column_order: number | null
    generic_real_time_tracker_race_states: GenericRealTimeTrackerRaceStateJson[]
}

interface GenericRealTimeTrackerRaceStateJson {
    name: string
    race_state: string
    button_text: string
    explanation: string
    is_secondary_button: boolean
    is_stop_tracking: boolean
    require_confirmation: boolean
    order: number
    horizontal_order: number
    is_scored: boolean
    is_correctness_score: boolean
    is_f_score: boolean
    score_weight: number | null
}

interface GenericRealTimeTrackerRunnerEventGroupJson {
    name: string
    is_options_group: boolean
    race_type: string | null
    sub_race_type: string | null
    start_type: string | null
    generic_real_time_tracker_runner_events: GenericRealTimeTrackerRunnerEventJson[]
}

interface GenericRealTimeTrackerRunnerEventJson {
    name: string
    race_type: string | null
    sub_race_type: string | null
    start_type: string | null
    is_checkbox: boolean
    runner_event: string
    runner_event_checked: string | null
    icon_checked: string | null
    button_text: string
    short_text_button: string
    explanation: string
    order: number
    is_scored: boolean
    is_correctness_score: boolean
    is_f_score: boolean
    score_weight: number | null
}

export interface GenericRealTimeTrackerTimedMessage {
    Message: string
    OffsetSeconds: number
    DurationSeconds: number
    GenericRealTimeTrackerTimedMessageTrackOverrides: GenericRealTimeTrackerTimedMessageTrackOverride[]
}

export interface GenericRealTimeTrackerTimedMessageTrackOverride {
    TrackCode: string
    ExtraOffsetSeconds: number
}

interface GenericRealTimeTrackerTimedMessageJson {
    message: string
    offset_seconds: number
    duration_seconds: number
    generic_real_time_tracker_timed_message_track_overrides: GenericRealTimeTrackerTimedMessageTrackOverrideJson[]
}

export interface GenericRealTimeTrackerTimedMessageTrackOverrideJson {
    track_code: string
    extra_offset_seconds: number
}

export interface VideoSource {
    Source: string
}

interface VideoSourceJson {
    source: string
}

export const pollRealTimeTrackers = (dispatch: Dispatch<any>) => {
    const initializeRealTimeTrackers = async () => {
        try {
            const trackers = await getRealTimeTrackers()
            dispatch(realTimeTrackers(trackers))
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }

    initializeRealTimeTrackers()

    return window.setInterval(async () => {
        try {
            const trackers = await getRealTimeTrackers()
            dispatch(realTimeTrackers(trackers))
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }, CONFIG.TRACKERS_POLL_INTERVAL)
}

export const getRealTimeTrackers = async () => {
    const url = '/api/v2/real_time_trackers'
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const trackers = response.data as GenericRealTimeTrackerJson[]
    return trackers
        .filter((t) => t.is_active)
        .map(
            (t) =>
                ({
                    ID: t.id,
                    Name: t.name,
                    TrackingType: t.tracking_type,
                    GuideURL: t.guide_url,
                    RaceType: t.race_type,
                    Country: t.country,
                    UseDropDownForRunnerButtons: t.use_drop_down_for_runner_buttons,
                    VideoSources: t.video_sources.map(
                        (source: VideoSourceJson) =>
                            ({
                                Source: source.source,
                            } as VideoSource)
                    ),
                    TimedMessages: t.timed_messages.map(
                        (tm: GenericRealTimeTrackerTimedMessageJson) =>
                            ({
                                Message: tm.message,
                                OffsetSeconds: tm.offset_seconds,
                                DurationSeconds: tm.duration_seconds,
                                GenericRealTimeTrackerTimedMessageTrackOverrides:
                                    tm.generic_real_time_tracker_timed_message_track_overrides.map((trackOverride) => ({
                                        TrackCode: trackOverride.track_code,
                                        ExtraOffsetSeconds: trackOverride.extra_offset_seconds,
                                    })),
                            } as GenericRealTimeTrackerTimedMessage)
                    ),
                    GenericRealTimeTrackerRaceStateGroups: t.generic_real_time_tracker_race_state_groups.map(
                        (rsg) =>
                            ({
                                Name: rsg.name,
                                IsOptionsGroup: rsg.is_options_group,
                                RaceType: rsg.race_type,
                                SubRaceType: rsg.sub_race_type,
                                StartType: rsg.start_type,
                                ButtonColumn: rsg.button_column,
                                ButtonColumnOrder: rsg.button_column_order,
                                GenericRealTimeTrackerRaceStates: rsg.generic_real_time_tracker_race_states.map(
                                    (rs) =>
                                        ({
                                            Name: rs.name,
                                            RaceState: rs.race_state,
                                            ButtonText: rs.button_text,
                                            Explanation: rs.explanation,
                                            IsSecondaryButton: rs.is_secondary_button,
                                            IsStopTracking: rs.is_stop_tracking,
                                            RequireConfirmation: rs.require_confirmation,
                                            Order: rs.order,
                                            HorizontalOrder: rs.horizontal_order,
                                            IsScored: rs.is_scored,
                                            IsCorrectnessScore: rs.is_correctness_score,
                                            IsFScore: rs.is_f_score,
                                            ScoreWeight: rs.score_weight,
                                        } as GenericRealTimeTrackerRaceState)
                                ),
                            } as GenericRealTimeTrackerRaceStateGroup)
                    ),
                    GenericRealTimeTrackerRunnerEvents: t.generic_real_time_tracker_runner_events.map(
                        (re) =>
                            ({
                                Name: re.name,
                                RaceType: re.race_type,
                                SubRaceType: re.sub_race_type,
                                StartType: re.start_type,
                                IsCheckbox: re.is_checkbox,
                                RunnerEvent: re.runner_event,
                                RunnerEventChecked: re.runner_event_checked,
                                IconChecked: re.icon_checked,
                                ButtonText: re.button_text,
                                ShortTextButton: re.short_text_button,
                                Explanation: re.explanation,
                                Order: re.order,
                                IsScored: re.is_scored,
                                IsCorrectnessScore: re.is_correctness_score,
                                IsFScore: re.is_f_score,
                                ScoreWeight: re.score_weight,
                            } as GenericRealTimeTrackerRunnerEvent)
                    ),
                    GenericRealTimeTrackerRunnerEventGroups: t.generic_real_time_tracker_runner_event_groups.map(
                        (reg) =>
                            ({
                                Name: reg.name,
                                IsOptionsGroup: reg.is_options_group,
                                RaceType: reg.race_type,
                                SubRaceType: reg.sub_race_type,
                                StartType: reg.start_type,
                                GenericRealTimeTrackerRunnerEvents: reg.generic_real_time_tracker_runner_events.map(
                                    (re) =>
                                        ({
                                            Name: re.name,
                                            RaceType: re.race_type,
                                            SubRaceType: re.sub_race_type,
                                            StartType: re.start_type,
                                            IsCheckbox: re.is_checkbox,
                                            RunnerEvent: re.runner_event,
                                            RunnerEventChecked: re.runner_event_checked,
                                            IconChecked: re.icon_checked,
                                            ButtonText: re.button_text,
                                            ShortTextButton: re.short_text_button,
                                            Explanation: re.explanation,
                                            Order: re.order,
                                            IsScored: re.is_scored,
                                            IsCorrectnessScore: re.is_correctness_score,
                                            IsFScore: re.is_f_score,
                                            ScoreWeight: re.score_weight,
                                        } as GenericRealTimeTrackerRunnerEvent)
                                ),
                            } as GenericRealTimeTrackerRunnerEventGroup)
                    ),
                    IsActive: t.is_active,
                    RunnerButtonsEventType: t.runner_buttons_event_type,
                    PracticeMode: t.practice_mode,
                    IsGrader: t.is_grader,
                } as GenericRealTimeTracker)
        ) as GenericRealTimeTracker[]
}

import React, { useEffect } from 'react'
import {
    Grid,
    Typography,
    FormControl,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    Radio,
    TextField,
    Button,
} from '@material-ui/core'
import { ActionReview, ActionReviewAction, getAllActionReviewActions } from '../../services/action_review'
import { GenericActionVideo } from '../common/GenericActionVideo'

export const VideoViewer = ({ actionReview }: { actionReview: ActionReview }) => {
    const [allActions, setAllActions] = React.useState([] as ActionReviewAction[])
    const [actionIndex, setActionIndex] = React.useState(0)
    const [playbackRate, setPlaybackRate] = React.useState('1')
    const [duration, setDuration] = React.useState(5)
    const [skipTo, setSkipTo] = React.useState(0)
    const [initialTimestamp, setInitialTimestamp] = React.useState(null as number | null)
    useEffect(() => {
        setInitialTimestamp(null)
        setActionIndex(0)
        const fetchAllActions = async () => {
            const actions = await getAllActionReviewActions(actionReview.ID)
            setAllActions(actions)
        }
        fetchAllActions()
    }, [actionReview.ID])

    return (
        <Grid container direction={'column'} spacing={4} justifyContent={'center'} className={'my-performance-root'}>
            <Grid item>
                <Typography variant={'h2'}>Video Viewer</Typography>
            </Grid>
            <Grid item>
                <Typography variant={'h6'}>Current Action {actionIndex}</Typography>
            </Grid>
            <Grid item>
                <Grid item>
                    <FormControl>
                        <FormLabel>Playback Speed</FormLabel>
                        <RadioGroup
                            onChange={(event) => {
                                setPlaybackRate(event.currentTarget.value)
                            }}
                            row={true}
                            value={playbackRate}
                        >
                            <FormControlLabel value={'0.5'} control={<Radio />} label="1/2x" />
                            <FormControlLabel value={'1'} control={<Radio />} label="1x" />
                            <FormControlLabel value={'1.5'} control={<Radio />} label="1.5x" />
                            <FormControlLabel value={'2'} control={<Radio />} label="2x" />
                            <FormControlLabel value={'5'} control={<Radio />} label="5x" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item>
                <TextField
                    onChange={(e) => setDuration(+e.target.value)}
                    value={duration}
                    variant={'outlined'}
                    size={'medium'}
                    data-testid={'duration'}
                    label={'Duration Seconds'}
                />
            </Grid>
            <Grid item container direction={'row'} spacing={1} alignItems={'center'}>
                <Grid item>
                    <TextField
                        onChange={(e) => setSkipTo(+e.target.value)}
                        value={skipTo}
                        variant={'outlined'}
                        size={'medium'}
                        data-testid={'skip'}
                        label={'Skip to index'}
                    />
                </Grid>
                <Grid item>
                    <Button
                        size={'large'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => {
                            setInitialTimestamp(null)
                            setActionIndex(skipTo)
                        }}
                    >
                        Confirm skip to index
                    </Button>
                </Grid>
            </Grid>
            {allActions.length > 0 && (
                <Grid item>
                    <GenericActionVideo
                        RaceID={allActions[actionIndex].RaceID}
                        RaceDate={allActions[actionIndex].RaceDate}
                        TrackCode={allActions[actionIndex].TrackCode}
                        RaceNumber={allActions[actionIndex].RaceNumber}
                        RaceStateID={allActions[actionIndex].RaceStateID}
                        RunnerEventID={allActions[actionIndex].RunnerEventID}
                        InitialPlaybackRate={playbackRate}
                        Autoplay={true}
                        onProgress={(event) => {
                            if (!event.currentTarget.currentTime) {
                                return
                            }
                            if (initialTimestamp === null) {
                                setInitialTimestamp(event.currentTarget.currentTime)
                                return
                            }
                            if (event.currentTarget.currentTime - initialTimestamp > duration) {
                                setInitialTimestamp(null)
                                setActionIndex(actionIndex + 1)
                            }
                        }}
                    />
                </Grid>
            )}
        </Grid>
    )
}

import axios from 'axios'
import { User } from '../models/user'
import { getMe } from '../reducers/get_me'
import { getErrorData } from './get_error'
import { userService } from './login'
import { datadogLogs } from '@datadog/browser-logs'
import { push } from 'connected-react-router'
import { Dispatch } from 'react'
import { datadogRum } from '@datadog/browser-rum'

declare var __insp: any

export function getMeData(dispatch: Dispatch<any>) {
    const url = '/api/v2/me'

    const request = axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })

    return request
        .then((response) => {
            if (response.status === 401) {
                userService.logout()
                dispatch(push('/login'))
                return
            }

            if (!response.data) {
                return
            }

            const me = new User(response.data)
            datadogLogs.addLoggerGlobalContext('username', me.userName)
            datadogRum.setUser({
                name: me.userName,
                team: me.team,
                beta: `${me.betaReleaseChannel}`,
            })
            dispatch(getMe(me))

            // eslint-disable-next-line no-restricted-globals
            parent.postMessage(
                {
                    type: 'me',
                    userName: me.userName,
                    betaReleaseChannel: me.betaReleaseChannel,
                },
                '*'
            )

            if (typeof __insp !== 'undefined') {
                __insp.push(['identify', me.userName])
            }
            return response
        })
        .catch((error) => {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 401) {
                    userService.logout()
                    dispatch(push('/login'))
                }
                getErrorData(error, dispatch)
            }
        })
}

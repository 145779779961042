import { Grid } from '@material-ui/core'
import * as React from 'react'
import { Rnd } from 'react-rnd'
import ReactHlsPlayer from 'react-hls-player'

interface VideoProps {
    url: string
    format: string
    height: number
    width: number
    idForIFrame?: string
    videoRef: React.RefObject<HTMLVideoElement>
}

export const RealTimeVideoPlayer = ({ url, format, height, width, idForIFrame, videoRef }: VideoProps) => {
    return (
        <Grid item={true} style={{ WebkitBorderRadius: 5 }}>
            <Rnd
                default={{
                    x: 0,
                    y: 0,
                    width,
                    height,
                }}
                disableDragging={true}
                lockAspectRatio={width / height}
                style={{
                    position: 'relative',
                }}
            >
                {format === 'iframe' && (
                    <iframe
                        src={url}
                        height={'100%'}
                        width={'100%'}
                        allowFullScreen={true}
                        title={'video'}
                        id={idForIFrame}
                        referrerPolicy={'no-referrer'}
                    />
                )}
                {format === 'm3u8' && (
                    <ReactHlsPlayer
                        src={url}
                        autoPlay={false}
                        controls={true}
                        width="100%"
                        height="100%"
                        playerRef={videoRef}
                        id={idForIFrame}
                    />
                )}
            </Rnd>
        </Grid>
    )
}

import { useSelector } from 'react-redux'
import { RootStateType } from '../../store'
import { Grid, TextField } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useState } from 'react'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { ArrowDropDown } from '@material-ui/icons'

interface SelectDateRangeProps {
    startDate: Date
    endDate: Date
    setStartDate: (d: Date) => void
    setEndDate: (d: Date) => void
}

const lastTwoDays = {
    value: 'lastTwoDays',
    label: 'previous two days',
}
const lastWeek = {
    value: 'lastWeek',
    label: 'previous week',
}
const lastTwoWeeks = {
    value: 'lastTwoWeeks',
    label: 'previous two weeks',
}
const options = [lastTwoDays, lastWeek, lastTwoWeeks]

export const SelectDateRange = ({ startDate, endDate, setStartDate, setEndDate }: SelectDateRangeProps) => {
    const userIsAdmin = useSelector((state: RootStateType) => state.meStore.me?.team === 'admin')
    const [presetValue, setPresetValue] = useState('')
    return (
        <Grid container direction={'row'} justifyContent={'center'} spacing={2} alignItems={'center'}>
            {userIsAdmin && (
                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="start-date-picker"
                            label="Start Date (inclusive)"
                            value={startDate}
                            onChange={(date: MaterialUiPickersDate) => {
                                if (date instanceof Date && !isNaN(date.getTime())) {
                                    setStartDate(date as Date)
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change start date',
                            }}
                        />
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="end-date-picker"
                            label="End Date (exclusive)"
                            value={endDate}
                            onChange={(date: MaterialUiPickersDate) => {
                                if (date instanceof Date && !isNaN(date.getTime())) {
                                    setEndDate(date as Date)
                                }
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change end date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            )}
            <Grid item>
                <Autocomplete
                    style={{ width: 350 }}
                    multiple={false}
                    id="date-preset-selector"
                    options={options}
                    getOptionLabel={(option: { value: string; label: string }) => option.label}
                    value={options.find((option) => option.value === presetValue)}
                    onChange={(event: any, option: { value: string; label: string } | null) => {
                        if (option == null) {
                            setPresetValue('')
                            return
                        }
                        if (option.value === lastTwoDays.value) {
                            setStartDate(getDaysAgoDate(2))
                            setEndDate(getDaysAgoDate(0))
                        }
                        if (option.value === lastWeek.value) {
                            setStartDate(getDaysAgoDate(7))
                            setEndDate(getDaysAgoDate(0))
                        }
                        if (option.value === lastTwoWeeks.value) {
                            setStartDate(getDaysAgoDate(14))
                            setEndDate(getDaysAgoDate(0))
                        }
                        setPresetValue(option.value)
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Date range preset"
                            placeholder="select a date range..."
                        />
                    )}
                    popupIcon={<ArrowDropDown className={'date-range-preset'} />}
                />
            </Grid>
        </Grid>
    )
}

const getDaysAgoDate = (daysAgo: number) => {
    const today = new Date()
    const millisecondsInDay = 24 * 60 * 60 * 1000
    return new Date(today.getTime() - daysAgo * millisecondsInDay)
}

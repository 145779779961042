import { FormControlLabel, Grid, Switch, Typography } from '@material-ui/core'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { RootStateType } from '../../store'
import { useParams } from 'react-router'
import { ActionReviewComponent } from '../action_review/ActionReviewComponent'
import { VideoViewer } from '../action_review/VideoViewer'

const notFoundJsx = (
    <Grid container direction={'column'} spacing={2} justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item>
            <Typography variant={'h4'}>Action Review Module not found</Typography>
        </Grid>
    </Grid>
)

const ActionReview = () => {
    const isAdmin = useSelector((state: RootStateType) => state?.meStore?.me?.team === 'admin')
    const [isVideoMontageView, setIsVideoMontageView] = React.useState(false)
    const { actionReviewID }: { actionReviewID: string } = useParams()
    const actionReview = useSelector((state: RootStateType) =>
        state?.actionReviewsStore?.actionReviews?.find(
            (actionReview) => actionReview.ID === parseInt(actionReviewID, 10)
        )
    )
    if (!actionReview) {
        return notFoundJsx
    }
    return (
        <>
            {isAdmin && (
                <FormControlLabel
                    control={
                        <Switch
                            checked={isVideoMontageView}
                            onChange={() => setIsVideoMontageView(!isVideoMontageView)}
                            name="video-montage-toggle"
                            color="primary"
                        />
                    }
                    label="Video Montage View"
                />
            )}
            {!isVideoMontageView && <ActionReviewComponent actionReview={actionReview} />}
            {isVideoMontageView && <VideoViewer actionReview={actionReview} />}
        </>
    )
}

export default ActionReview

import { getMeData } from './get_me'
import { getUserLatencyInSeconds } from './get_latency'
import CONFIG from '../settings/config'
import { Dispatch } from 'react'
import { refreshToken } from './login'

export const pollMeData = (dispatch: Dispatch<any>): number => {
    getMeData(dispatch)
    getUserLatencyInSeconds(dispatch)
    return window.setInterval(() => {
        if (localStorage.getItem('user')) {
            refreshToken(dispatch)
            getMeData(dispatch)
            getUserLatencyInSeconds(dispatch)
        }
    }, CONFIG.ME_POLL_INTERVAL)
}

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Card } from '@material-ui/core'
import { CardContent } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { CssBaseline } from '@material-ui/core'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { App } from './App'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { isElectron } from './services/electron'
import { Grid } from '@material-ui/core'
import { Provider } from 'react-redux'
import { createReduxStore } from './store'

if (process.env.REACT_APP_ENV === 'production') {
    datadogLogs.init({
        clientToken: 'pub1bd4446c9e5812582566fefa81303a06',
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: 'live-data-entry-frontend',
        env: 'production',
    })

    datadogRum.init({
        applicationId: '95ed6c2f-66da-475a-ae72-9683d46fed1f',
        clientToken: 'pubdfa500d9aa5d89aed2becea093bcf351',
        site: 'datadoghq.com',
        service: 'live-data-entry',
        env: 'production',
        sampleRate: 50,
        sessionReplaySampleRate: 0,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
        allowedTracingOrigins: [/https:\/\/.*\.eochgroup\.com/],
        useCrossSiteSessionCookie: true,
    })

    datadogRum.startSessionReplayRecording()
}

const theme = createMuiTheme({
    typography: {
        fontSize: 12,
    },
})

if (!isElectron()) {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container={true} justifyContent={'center'} spacing={10}>
                <Card style={{ marginTop: '5em' }}>
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom={true}>
                            Please download the latest version of the desktop app from:
                            <br />
                            <a href="https://live-data-entry-client.s3.amazonaws.com/index.html">
                                https://live-data-entry-client.s3.amazonaws.com/index.html
                            </a>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </MuiThemeProvider>,
        document.getElementById('root') as HTMLElement
    )
} else {
    const store = createReduxStore()
    ReactDOM.render(
        <>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500" />
            <Provider store={store}>
                <App />
            </Provider>
        </>,
        document.getElementById('root') as HTMLElement
    )
}

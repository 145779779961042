import { ReorderRounded } from '@material-ui/icons'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { Grid } from '@material-ui/core'
import * as React from 'react'

export const DragHandle = SortableHandle(() => (
    <ReorderRounded data-testid={'reorder-handle'} style={{ cursor: 'move' }} />
))

export const SortableRunnerTrackerRaceCard = SortableElement(
    ({ raceId, order, children }: SortableRunnerTrackerRaceCardProps) => {
        let extraProps = {}
        return (
            <>
                <Grid
                    key={raceId}
                    item
                    data-testid={`race-runner-card-${raceId}`}
                    style={{ order: order }}
                    {...extraProps}
                >
                    {children}
                </Grid>
            </>
        )
    }
)

export const SortableRacesContainer = SortableContainer(({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <Grid container justifyContent={'flex-start'} spacing={4} data-testid={'races-container'}>
                {children}
            </Grid>
        </>
    )
})

interface SortableRunnerTrackerRaceCardProps {
    raceId: number
    order: number
    children: React.ReactNode
}

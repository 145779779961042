export interface IRaceState {
    id: number
    time: Date
    type: string
    user: ISimpleUser
    videoTimestamp: number | null
    videoSource: string
    genericRealTimeTrackerID: number | null
    genericHistoricalTrackerID: number | null
}

export interface ISimpleUser {
    userName: string
    active: boolean
    team: string
}

export interface ISimpleUserJson {
    user_name: string
    active: boolean
    team: string
}

export interface IRaceStateJson {
    id: number
    time: string
    type: string
    user: ISimpleUserJson
    video_timestamp: number | null
    video_source: string
    generic_real_time_tracker_id: number | null
    generic_historical_tracker_id: number | null
}

export enum RaceStateTypes {
    GRUMPY_TRACKING_STARTED = 'START_GRUMPY_TRACKING',
    BANDAGES_TRACKED = 'BANDAGES_TRACKED',
    CAR_GATE_DOWN = 'CAR_GATE_DOWN',
    CAR_DRIVING = 'CAR_DRIVING',
    RACE_AUTOMATIC = 'RACE_AUTOMATIC',
    RACE_POST = 'RACE_POST',
    SKIP_RACE = 'SKIP_RACE',
    NULL = '',
    HARNESS_LANDMARK = 'HARNESS_LANDMARK',
    HARNESS_LANDMARK_OBSCURED = 'HARNESS_LANDMARK_OBSCURED',

    ALL_HORSES_IN_STARTING_AREA = 'ALL_HORSES_IN_STARTING_AREA',
    HORSES_GROUPED = 'HORSES_GROUPED',
    FIRST_HORSE_ON_TRACK = 'FIRST_HORSE_ON_TRACK',
    FIRST_HORSE_TURNED_CORNER = 'FIRST_HORSE_TURNED_CORNER',

    FALSE_START = 'FALSE_START',

    HISTORICAL_HARNESS_START_TRACKING_WITH_BROKE_STRIDE = 'HISTORICAL_HARNESS_START_TRACKING_WITH_BROKE_STRIDE',
    HISTORICAL_HARNESS_LENGTHS_BACK_TRACKED = 'HISTORICAL_HARNESS_LENGTHS_BACK_TRACKED',

    MTP_START_TRACKING = 'MTP_START_TRACKING',

    BROKE_STRIDE_TRACKING_STARTED = 'BROKE_STRIDE_TRACKING_STARTED',

    HORSES_AT_STARTING_GATE = 'HORSES_AT_STARTING_GATE',
}

import React, { useEffect, useState } from 'react'
import { createAnswerKey, Quiz } from '../../services/quizzes'
import {
    AnswerKey,
    AnswerKeyExpectedRaceState,
    AnswerKeyExpectedRunnerEvent,
    fetchAnswerKey,
} from '../../services/answer_keys'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { getErrorData } from '../../services/get_error'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import { QuizAnswersTable } from './QuizAnswersTable'
import { GenericSingleActionAdjudication } from './GenericSingleActionAdjudication'
import { useSnackbar } from 'notistack'

export interface Props {
    selectedQuiz: Quiz
    onAdjudicationComplete: () => void
    genericHistoricalTrackerID: number
}

export const QuizAdjudicationGrid = ({ selectedQuiz, onAdjudicationComplete, genericHistoricalTrackerID }: Props) => {
    const [answerKey, setAnswerKey] = useState(null as AnswerKey | null)
    const [recreating, setRecreating] = useState(false)
    const [shouldFetchAnswerKey, setShouldFetchAnswerKey] = useState(false)
    const [selectedRunnerEvent, setSelectedRunnerEvent] = useState(null as AnswerKeyExpectedRunnerEvent | null)
    const [selectedRaceState, setSelectedRaceState] = useState(null as AnswerKeyExpectedRaceState | null)
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        if (shouldFetchAnswerKey) {
            setShouldFetchAnswerKey(false)
            fetchAnswerKeyForQuiz(selectedQuiz, genericHistoricalTrackerID, setAnswerKey, dispatch)
        }
    }, [shouldFetchAnswerKey, selectedQuiz, genericHistoricalTrackerID, dispatch])
    useEffect(() => {
        if (answerKey?.RaceID !== selectedQuiz.RaceID) {
            setShouldFetchAnswerKey(true)
        }
    }, [answerKey, selectedQuiz])

    return (
        <Grid container direction={'column'} spacing={3} alignItems={'center'}>
            <Grid item>
                {!recreating && (
                    <Button
                        size={'large'}
                        color={'primary'}
                        variant={'contained'}
                        onClick={async () => {
                            try {
                                setRecreating(true)
                                const { success, failureText } = await createAnswerKey(
                                    genericHistoricalTrackerID,
                                    selectedQuiz.RaceID
                                )
                                if (success) {
                                    enqueueSnackbar('Successfully created quiz', {
                                        variant: 'success',
                                    })
                                } else {
                                    enqueueSnackbar(`Failed to create quiz ${failureText}`, { variant: 'warning' })
                                }
                            } catch (err) {
                                getErrorData(err, dispatch)
                            } finally {
                                setShouldFetchAnswerKey(true)
                                setRecreating(false)
                            }
                        }}
                    >
                        Recreate answer key
                    </Button>
                )}
                {recreating && <CircularProgress />}
            </Grid>
            <Grid item>
                <QuizAnswersTable
                    answerKey={answerKey}
                    genericHistoricalTrackerID={genericHistoricalTrackerID}
                    onRunnerEventSelected={(runnerEvent) => {
                        setSelectedRunnerEvent(runnerEvent)
                        setSelectedRaceState(null)
                    }}
                    onRaceStateSelected={(raceState) => {
                        setSelectedRunnerEvent(null)
                        setSelectedRaceState(raceState)
                    }}
                />
            </Grid>
            <Grid item>
                <GenericSingleActionAdjudication
                    genericHistoricalTrackerID={genericHistoricalTrackerID}
                    quiz={selectedQuiz}
                    selectedRunnerEvent={selectedRunnerEvent}
                    selectedRaceState={selectedRaceState}
                    onActionAdjudicated={() => {
                        setSelectedRaceState(null)
                        setSelectedRunnerEvent(null)
                        fetchAnswerKeyForQuiz(
                            selectedQuiz,
                            genericHistoricalTrackerID,
                            setAnswerKey,
                            dispatch,
                            onAdjudicationComplete
                        )
                    }}
                />
            </Grid>
            <Grid item></Grid>
        </Grid>
    )
}

const fetchAnswerKeyForQuiz = async (
    quiz: Quiz,
    genericHistoricalTrackerID: number,
    setAnswerKey: (a: AnswerKey) => void,
    dispatch: Dispatch<any>,
    onAdjudicationComplete?: () => void
) => {
    try {
        const answerKey = await fetchAnswerKey(quiz.RaceID, genericHistoricalTrackerID)
        setAnswerKey(answerKey)
        if (!onAdjudicationComplete) {
            return
        }
        if (answerKey.AnswerKeyInvalidReason === null || answerKey.AnswerKeyInvalidReason === '') {
            onAdjudicationComplete()
        }
    } catch (e) {
        getErrorData(e, dispatch)
    }
}

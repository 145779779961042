import React from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { LearningCenterTable } from '../learning_center/LearningCenterTable'
import { RootStateType } from '../../store'
import { useSelector } from 'react-redux'

export const LearningCenterFeedback = () => {
    const genericActionCommentsForUser = useSelector(
        (state: RootStateType) => state?.userActionCommentsStore?.userActionComments ?? []
    )

    return (
        <>
            <Grid container spacing={4} direction={'column'}>
                <Grid item>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid item className={'learning-center-header'}>
                            <Typography variant={'h2'}>Learning center - feedback</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Paper>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid item>
                                <LearningCenterTable genericActionComments={genericActionCommentsForUser} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

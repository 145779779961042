import CONFIG from '../settings/config'
import { Dispatch } from 'react'
import { fetchToggles } from './get_toggles'
import { getErrorData } from './get_error'
import { getToggles } from '../reducers/get_toggles'

export const pollToggles = (dispatch: Dispatch<any>): number => {
    ;(async () => {
        try {
            const toggles = await fetchToggles()
            dispatch(getToggles(toggles))
        } catch (e) {
            getErrorData(e, dispatch)
        }
    })()
    return window.setInterval(async () => {
        if (localStorage.getItem('user')) {
            try {
                const toggles = await fetchToggles()
                dispatch(getToggles(toggles))
            } catch (e) {
                getErrorData(e, dispatch)
            }
        }
    }, CONFIG.TOGGLES_POLL_INTERVAL)
}

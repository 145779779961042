import { Button } from '@material-ui/core'
import { Card } from '@material-ui/core'
import { CardContent } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { push } from 'connected-react-router'
import * as React from 'react'
import { connect } from 'react-redux'
import { getErrorData } from '../../services/get_error'
import { userService } from '../../services/login'
import { IRace } from '../../types'
import { RootStateType } from '../../store'
import { getMeData } from '../../services/get_me'
import { getUserLatencyInSeconds } from '../../services/get_latency'

interface Iprops {
    race: IRace
    dispatch: any
}

interface IState {
    password: string
    username: string
}

class Login extends React.Component<Iprops, IState> {
    public render() {
        return (
            <Grid container={true} justifyContent="center">
                <Card>
                    <CardContent>
                        <form onSubmit={this.handleSubmit}>
                            <Grid item={true} xs={6}>
                                <TextField
                                    id="username-input"
                                    label="Username"
                                    type="text"
                                    margin="normal"
                                    onChange={this.handleUserNameChange()}
                                />
                            </Grid>
                            <Grid item={true} xs={6}>
                                <TextField
                                    id="password-input"
                                    label="Password"
                                    type="password"
                                    margin="normal"
                                    onChange={this.handlePasswordChange()}
                                />
                            </Grid>
                            <Button variant="contained" color="primary" type="submit">
                                Login
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (this.state) {
            userService
                .login(this.state.username, this.state.password)
                .then(() => {
                    getMeData(this.props.dispatch)
                    getUserLatencyInSeconds(this.props.dispatch)
                    this.props.dispatch(push('/'))
                })
                .catch((error: string) => {
                    getErrorData(error, this.props.dispatch)
                    this.props.dispatch(push('/login'))
                })
        }
    }

    private handleUserNameChange = () => (event: any) => {
        this.setState({
            username: event.target.value,
        })
    }

    private handlePasswordChange = () => (event: any) => {
        this.setState({
            password: event.target.value,
        })
    }
}

export default connect((state: RootStateType) => state)(Login)

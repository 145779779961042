import { Quiz } from '../../services/quizzes'
import { Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@material-ui/core'
import { Check, Close } from '@material-ui/icons'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { addDays } from '../../services/race_date'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

const NOT_ENOUGH_TRACKERS = 'not enough trackers'

export interface QuizzesListProps {
    quizzes: Quiz[]
    selectedQuiz: Quiz | null
    setSelectedQuiz: (quiz: Quiz) => void
}

const useStyles = makeStyles((theme) => ({
    finalized: {
        backgroundColor: theme.palette.success.light,
    },
    notEnoughTrackers: {
        backgroundColor: theme.palette.warning.light,
    },
    disagreement: {
        backgroundColor: theme.palette.error.light,
    },
}))

const getClassForQuiz = (classes: any, quiz: Quiz) => {
    if (quiz.IsFinalized) {
        return classes.finalized
    }
    if (quiz.NotFinalizedReason === NOT_ENOUGH_TRACKERS) {
        return classes.notEnoughTrackers
    }
    return classes.disagreement
}

const lastTwoDaysFilter = 'only last two days'
const nonFinalizedFilter = 'only non-finalized'

export const QuizzesList = ({ quizzes, selectedQuiz, setSelectedQuiz }: QuizzesListProps) => {
    const classes = useStyles()
    const [filters, setFilters] = useState([lastTwoDaysFilter])
    const twoDaysAgo = addDays(new Date(), -2)
    const displayQuizzes = quizzes
        .filter((q) => q.IsFinalized || (q.NotFinalizedReason !== null && q.NotFinalizedReason !== ''))
        .filter((q) => !filters.includes(nonFinalizedFilter) || !q.IsFinalized)
        .filter((q) => !filters.includes(lastTwoDaysFilter) || q.CreatedAt > twoDaysAgo)
        .sort((a, b) => parseInt(a.RaceDate) - parseInt(b.RaceDate))

    return (
        <Grid container direction={'column'} spacing={2}>
            <Grid item>
                <ToggleButtonGroup value={filters} onChange={(event, newFilters) => setFilters(newFilters)}>
                    <ToggleButton value={lastTwoDaysFilter}>{lastTwoDaysFilter}</ToggleButton>
                    <ToggleButton value={nonFinalizedFilter}>{nonFinalizedFilter}</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            {displayQuizzes.length === 0 && (
                <Grid item>
                    <Typography variant={'h6'}>No quizzes match the filters</Typography>
                </Grid>
            )}
            <Grid item>
                <List component="nav" aria-label="quizzes">
                    {displayQuizzes.map((quiz) => (
                        <Tooltip title={quiz.NotFinalizedReason ?? ''} key={quiz.RaceID}>
                            <ListItem
                                button
                                selected={quiz.RaceID === selectedQuiz?.RaceID}
                                onClick={() => setSelectedQuiz(quiz)}
                                className={getClassForQuiz(classes, quiz)}
                            >
                                <ListItemText primary={`${quiz.RaceDate} ${quiz.TrackCode} ${quiz.RaceNumber}`} />
                                <ListItemIcon>
                                    {quiz.IsFinalized && <Check />}
                                    {!quiz.IsFinalized && <Close />}
                                </ListItemIcon>
                            </ListItem>
                        </Tooltip>
                    ))}
                </List>
            </Grid>
        </Grid>
    )
}

import * as React from 'react'
import {
    getUnifiedAssessments,
    RaceStateAssessment,
    RunnerEventAssessment,
    TrackerAssessment,
} from '../../services/tracker_assessment'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Grid,
    Typography,
    TableContainer,
    Paper,
    Button,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core'
import { Check, Clear, OpenInNew } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { SingleActionReviewDetail } from '../new_performance/SingleActionReviewDetail'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    Correct: {
        backgroundColor: theme.palette.success.light,
    },
    Incorrect: {
        backgroundColor: theme.palette.error.light,
    },
    IncorrectReviewed: {
        backgroundColor: theme.palette.warning.light,
    },
    SampleColor: {
        width: theme.spacing(1),
        height: theme.spacing(1),
    },
}))

export interface Props {
    QuizResults: TrackerAssessment
    OnQuizReviewed: () => void
}

export const QuizResultsReview = ({ QuizResults, OnQuizReviewed }: Props) => {
    const [runnerEventUnderReview, setRunnerEventUnderReview] = useState(null as RunnerEventAssessment | null)
    const [raceStateUnderReview, setRaceStateUnderReview] = useState(null as RaceStateAssessment | null)
    const [reviewedRunnerEventMistakes, setReviewedRunnerEventMistakes] = useState(new Set<number>())
    const [reviewedRaceStateMistakes, setReviewedRaceStateMistakes] = useState(new Set<number>())
    const allMistakesHaveBeenReviewed =
        QuizResults.RunnerEventAssessments.filter((runnerEvent) => !userIsCorrect(runnerEvent)).every((mistake) =>
            reviewedRunnerEventMistakes.has(mistake.ID)
        ) &&
        QuizResults.RaceStateAssessments.filter((raceState) => !userIsCorrect(raceState)).every((mistake) =>
            reviewedRaceStateMistakes.has(mistake.ID)
        )
    return (
        <>
            <Grid container direction={'column'} justifyContent={'center'} spacing={1} alignItems={'center'}>
                <Grid item>
                    <Typography variant={'h4'}>The previous race was a quiz!</Typography>
                </Grid>
                <Grid item>
                    <Grid container direction={'row'} spacing={5}>
                        <Grid item>
                            <QuizResultsTable
                                QuizResults={QuizResults}
                                ReviewedRunnerEventMistakes={reviewedRunnerEventMistakes}
                                ReviewedRaceStateMistakes={reviewedRaceStateMistakes}
                                SetRunnerEventUnderReview={(runnerEvent) => {
                                    setRaceStateUnderReview(null)
                                    setRunnerEventUnderReview(runnerEvent)
                                }}
                                SetRaceStateUnderReview={(raceState) => {
                                    setRaceStateUnderReview(raceState)
                                    setRunnerEventUnderReview(null)
                                }}
                            />
                        </Grid>
                        {(runnerEventUnderReview || raceStateUnderReview) && (
                            <Grid item>
                                <QuizActionUnderReview
                                    QuizResults={QuizResults}
                                    RaceStateAssessment={raceStateUnderReview}
                                    RunnerEventAssessment={runnerEventUnderReview}
                                    OnReviewComplete={() => {
                                        if (runnerEventUnderReview !== null) {
                                            setReviewedRunnerEventMistakes(
                                                new Set([...reviewedRunnerEventMistakes, runnerEventUnderReview.ID])
                                            )
                                            setRunnerEventUnderReview(null)
                                        }
                                        if (raceStateUnderReview !== null) {
                                            setReviewedRaceStateMistakes(
                                                new Set([...reviewedRaceStateMistakes, raceStateUnderReview.ID])
                                            )
                                            setRaceStateUnderReview(null)
                                        }
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Tooltip
                        title={
                            !allMistakesHaveBeenReviewed
                                ? 'you must review all mistakes before moving on to the next race'
                                : ''
                        }
                    >
                        <span>
                            <Button
                                size={'large'}
                                color={'primary'}
                                disabled={!allMistakesHaveBeenReviewed}
                                variant={'contained'}
                                onClick={OnQuizReviewed}
                            >
                                Done Reviewing Quiz Results
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    )
}

interface TableProps {
    QuizResults: TrackerAssessment
    SetRunnerEventUnderReview: (RunnerEventAssessment: RunnerEventAssessment | null) => void
    SetRaceStateUnderReview: (RaceStateAssessment: RaceStateAssessment | null) => void
    ReviewedRunnerEventMistakes: Set<number>
    ReviewedRaceStateMistakes: Set<number>
}

const QuizResultsTable = ({
    QuizResults,
    SetRunnerEventUnderReview,
    SetRaceStateUnderReview,
    ReviewedRunnerEventMistakes,
    ReviewedRaceStateMistakes,
}: TableProps) => {
    const distinctRunnerEvents = [
        ...new Set(
            QuizResults.RunnerEventAssessments.filter((runnerEvent) => runnerEvent.RunnerEventGroupName === null).map(
                (a) => a.Type
            )
        ),
    ]
    const distinctRaceStates = [...new Set(QuizResults.RaceStateAssessments.map((a) => a.Type))]
    const distinctRunnerEventGroups = [
        ...new Set(
            QuizResults.RunnerEventAssessments.filter((runnerEvent) => runnerEvent.RunnerEventGroupName !== null).map(
                (a) => a.RunnerEventGroupName
            )
        ),
    ]
    const distinctRunners = [...new Set(QuizResults.RunnerEventAssessments.map((a) => a.ProgramNumber))]
    const classes = useStyles()
    const [dialogOpen, setDialogOpen] = useState(false)
    return (
        <Grid container direction={'column'} spacing={2} justifyContent={'center'} alignItems={'center'}>
            <Grid item>
                <Button variant="text" color="default" onClick={() => setDialogOpen(true)}>
                    <Grid container={true} direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item>How to review quiz results</Grid>
                        <Grid item>
                            <OpenInNew />
                        </Grid>
                    </Grid>
                </Button>
                <Dialog
                    onClose={() => setDialogOpen(false)}
                    aria-labelledby="customized-dialog-title"
                    open={dialogOpen}
                >
                    <DialogTitle id="customized-dialog-title">Reviewing Quiz Results</DialogTitle>
                    <DialogContent dividers>
                        <Typography variant={'h6'}>Correct answers</Typography>
                        <Typography gutterBottom>
                            <ul>
                                <li>
                                    The table contains the correct answers for every possible runner and every possible
                                    marking. Each row contains all the correct answers for a given horse.
                                </li>
                                <li>
                                    If the cell contains the icon <Check /> then the correct answer for the horse was
                                    YES, the signal should have been marked.
                                </li>
                                <li>
                                    If the cell contains the icon <Clear /> then the correct answer for the horse was
                                    NO, the signal should NOT have been marked.
                                </li>
                            </ul>
                        </Typography>
                        <Typography variant={'h6'}>Reviewing answers</Typography>
                        <Typography gutterBottom>
                            <ul>
                                <li>
                                    You can click on mistakes (click on the <Check /> or <Clear /> icon) to review
                                    mistakes
                                </li>
                                <li>You must review all mistakes before you can move on to the next race</li>
                            </ul>
                        </Typography>
                        <Typography variant={'h6'}>Assessment of your performance on the quiz</Typography>
                        <Typography gutterBottom>
                            <ul>
                                <li>
                                    If the cell is{' '}
                                    <span className={clsx(classes.SampleColor, classes.Correct)}>green</span> then you
                                    were correct for this horses and marking.
                                </li>
                                <li>
                                    If the cell is{' '}
                                    <span className={clsx(classes.SampleColor, classes.Incorrect)}>red</span> then you
                                    were wrong for this horses and marking.
                                </li>
                                <li>
                                    If the cell is{' '}
                                    <span className={clsx(classes.SampleColor, classes.IncorrectReviewed)}>yellow</span>{' '}
                                    then you were wrong for this horses and marking and you have reviewed the mistake.
                                </li>
                            </ul>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => setDialogOpen(false)} color="primary">
                            Got it!
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            <Grid item container direction={'row'} spacing={2}>
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Runner Number</TableCell>
                                    {distinctRunnerEvents.map((signal) => (
                                        <TableCell variant={'head'} key={signal}>
                                            {signal}
                                        </TableCell>
                                    ))}
                                    {distinctRunnerEventGroups.map((signal) => (
                                        <TableCell variant={'head'} key={signal}>
                                            {signal}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {distinctRunners
                                    .sort(
                                        (programNumberA, programNumberB) =>
                                            parseInt(programNumberA, 10) - parseInt(programNumberB, 10)
                                    )
                                    .map((programNumber) => (
                                        <TableRow key={programNumber}>
                                            <TableCell variant={'head'}>{programNumber}</TableCell>
                                            {distinctRunnerEvents.map((signal) => {
                                                const runnerEventAssessment = findRunnerEventAssessment(
                                                    QuizResults.RunnerEventAssessments,
                                                    programNumber,
                                                    signal
                                                )!
                                                return displayCellForRunnerEventAssessment(
                                                    classes,
                                                    runnerEventAssessment,
                                                    ReviewedRunnerEventMistakes,
                                                    signal,
                                                    programNumber,
                                                    SetRunnerEventUnderReview
                                                )
                                            })}
                                            {distinctRunnerEventGroups.map((runnerEventGroupName) => {
                                                const runnerEventAssessment = findRunnerEventAssessmentForGroup(
                                                    QuizResults.RunnerEventAssessments,
                                                    programNumber,
                                                    runnerEventGroupName
                                                )!
                                                return displayCellForRunnerEventAssessment(
                                                    classes,
                                                    runnerEventAssessment,
                                                    ReviewedRunnerEventMistakes,
                                                    runnerEventGroupName!,
                                                    programNumber,
                                                    SetRunnerEventUnderReview
                                                )
                                            })}
                                        </TableRow>
                                    ))}
                                <TableRow>
                                    <TableCell variant={'head'}>Total Correct</TableCell>
                                    {distinctRunnerEvents.map((signal) => {
                                        const assessmentsForType = QuizResults.RunnerEventAssessments.filter(
                                            (assessment) => assessment.Type === signal
                                        )
                                        return displayAggregatesCell(assessmentsForType, signal)
                                    })}
                                    {distinctRunnerEventGroups.map((runnerEventGroupName) => {
                                        const assessmentsForType = QuizResults.RunnerEventAssessments.filter(
                                            (assessment) => assessment.RunnerEventGroupName === runnerEventGroupName
                                        )
                                        return displayAggregatesCell(assessmentsForType, runnerEventGroupName!)
                                    })}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item>
                    <TableContainer component={Paper}>
                        <Table size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Race State</TableCell>
                                    <TableCell>Assessment</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {distinctRaceStates.sort().map((raceState) => {
                                    const raceStateAssessment = QuizResults.RaceStateAssessments.find(
                                        (assessment) => assessment.Type === raceState
                                    )!

                                    return (
                                        <TableRow key={raceState}>
                                            <TableCell variant={'head'}>{raceState}</TableCell>
                                            {displayCellForRaceStateAsssessment(
                                                classes,
                                                raceStateAssessment,
                                                ReviewedRaceStateMistakes,
                                                raceState,
                                                SetRaceStateUnderReview
                                            )}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    )
}

const QuizActionUnderReview = ({
    QuizResults,
    RaceStateAssessment,
    RunnerEventAssessment,
    OnReviewComplete,
}: {
    QuizResults: TrackerAssessment
    RaceStateAssessment: RaceStateAssessment | null
    RunnerEventAssessment: RunnerEventAssessment | null
    OnReviewComplete: () => void
}) => {
    if (RaceStateAssessment === null && RunnerEventAssessment === null) {
        return null
    }
    const unifiedAssessment = getUnifiedAssessments(QuizResults).find((assessment) => {
        if (RaceStateAssessment !== null) {
            return assessment.RaceTrackingRaceStateAssessmentID === RaceStateAssessment.ID
        }
        if (RunnerEventAssessment !== null) {
            return assessment.RaceTrackingRunnerEventAssessmentID === RunnerEventAssessment.ID
        }
        return false
    })!
    return (
        <Grid container={true} direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
            <Grid item>
                <Typography variant={'h6'}>
                    {unifiedAssessment.RaceDate} {unifiedAssessment.TrackCode} {unifiedAssessment.RaceNumber}{' '}
                    {unifiedAssessment.ProgramNumber ? `: Runner ${unifiedAssessment.ProgramNumber}` : ''}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={'h6'}>
                    {RunnerEventAssessment?.AssessmentText}
                    {RaceStateAssessment?.AssessmentText}
                </Typography>
            </Grid>
            <Grid item>
                <SingleActionReviewDetail
                    {...QuizResults}
                    Assessment={unifiedAssessment}
                    OnResponded={OnReviewComplete}
                    OnApprovedRejected={() => {}}
                    Height={600}
                    Width={800}
                    AllowApproveReject={false}
                />
            </Grid>
        </Grid>
    )
}

const displayCellForRaceStateAsssessment = (
    classes: any,
    raceStateAssessment: RaceStateAssessment,
    ReviewedMistakes: Set<number>,
    signal: string,
    SetRaceStateUnderReview: (raceStateAssessment: RaceStateAssessment) => void
) => {
    const correct = userIsCorrect(raceStateAssessment)
    const assessmentID = raceStateAssessment?.ID ?? 0
    let className = classes.Correct
    if (!correct && ReviewedMistakes.has(assessmentID)) {
        className = classes.IncorrectReviewed
    }
    if (!correct && !ReviewedMistakes.has(assessmentID)) {
        className = classes.Incorrect
    }
    const icon = correctAnswerHasSignal(raceStateAssessment) ? (
        <Check fontSize={'small'} className={'check'} />
    ) : (
        <Clear fontSize={'small'} className={'clear'} />
    )
    return (
        <React.Fragment key={signal}>
            <TableCell className={clsx(className, `quiz-review-cell-race-state-${signal}`)}>
                <Tooltip title={raceStateAssessment.AssessmentText}>
                    <IconButton onClick={() => SetRaceStateUnderReview(raceStateAssessment)}>{icon}</IconButton>
                </Tooltip>
            </TableCell>
        </React.Fragment>
    )
}

const displayCellForRunnerEventAssessment = (
    classes: any,
    runnerEventAssessment: RunnerEventAssessment,
    ReviewedMistakes: Set<number>,
    signal: string,
    programNumber: string,
    SetRunnerEventUnderReview: (runnerEventAssessment: RunnerEventAssessment) => void
) => {
    const correct = userIsCorrect(runnerEventAssessment)
    const assessmentID = runnerEventAssessment?.ID ?? 0
    let className = classes.Correct
    if (!correct && ReviewedMistakes.has(assessmentID)) {
        className = classes.IncorrectReviewed
    }
    if (!correct && !ReviewedMistakes.has(assessmentID)) {
        className = classes.Incorrect
    }
    const icon = correctAnswerHasSignal(runnerEventAssessment) ? (
        <Check fontSize={'small'} className={'check'} />
    ) : (
        <Clear fontSize={'small'} className={'clear'} />
    )
    return (
        <React.Fragment key={signal}>
            <TableCell className={clsx(className, `quiz-review-cell-${programNumber}-${signal.replace(' ', '-')}`)}>
                <Tooltip title={runnerEventAssessment.AssessmentText}>
                    <IconButton onClick={() => SetRunnerEventUnderReview(runnerEventAssessment)}>{icon}</IconButton>
                </Tooltip>
            </TableCell>
        </React.Fragment>
    )
}

const displayAggregatesCell = (assessmentsForType: RunnerEventAssessment[], signal: string) => {
    const aggregates = assessmentsForType.reduce(
        (accumulated, current) => {
            const horseIsYes = correctAnswerHasSignal(current)
            const correct = userIsCorrect(current)
            return {
                numHorsesYes: accumulated.numHorsesYes + (horseIsYes ? 1 : 0),
                numHorsesNo: accumulated.numHorsesNo + (!horseIsYes ? 1 : 0),
                numHorsesCorrectYes: accumulated.numHorsesCorrectYes + (horseIsYes && correct ? 1 : 0),
                numHorsesCorrectNo: accumulated.numHorsesCorrectNo + (!horseIsYes && correct ? 1 : 0),
            }
        },
        {
            numHorsesYes: 0,
            numHorsesNo: 0,
            numHorsesCorrectYes: 0,
            numHorsesCorrectNo: 0,
        }
    )
    const yesPercent =
        aggregates.numHorsesYes > 0
            ? `(${((100 * aggregates.numHorsesCorrectYes) / aggregates.numHorsesYes).toFixed(0)}%)`
            : ''

    const noPercent =
        aggregates.numHorsesNo > 0
            ? `(${((100 * aggregates.numHorsesCorrectNo) / aggregates.numHorsesNo).toFixed(0)}%)`
            : ''

    return (
        <React.Fragment key={signal}>
            <TableCell>
                <Grid container direction={'column'}>
                    <Grid item>
                        {aggregates.numHorsesCorrectYes} / {aggregates.numHorsesYes} correct YES {yesPercent}
                    </Grid>
                    <Grid item>
                        {aggregates.numHorsesCorrectNo} / {aggregates.numHorsesNo} correct NO {noPercent}
                    </Grid>
                </Grid>
            </TableCell>
        </React.Fragment>
    )
}

const findRunnerEventAssessment = (
    runnerEventAssessments: RunnerEventAssessment[],
    programNumber: string,
    signal: string
) => {
    return runnerEventAssessments?.find(
        (assessment) => assessment.ProgramNumber === programNumber && assessment.Type === signal
    )
}

const findRunnerEventAssessmentForGroup = (
    runnerEventAssessments: RunnerEventAssessment[],
    programNumber: string,
    runnerEventGroupName: string | null
) => {
    return runnerEventAssessments?.find(
        (assessment) =>
            assessment.ProgramNumber === programNumber && assessment.RunnerEventGroupName === runnerEventGroupName
    )
}

const correctAnswerHasSignal = (assessment: RunnerEventAssessment | RaceStateAssessment) => {
    return !assessment.ShouldNotHaveType
}

const userIsCorrect = (assessment: RunnerEventAssessment | RaceStateAssessment) => {
    return assessment.AssessmentText === 'Correct'
}

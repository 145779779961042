import React, { useEffect, useState } from 'react'
import { getUpcomingRaces, UpcomingRacesParams } from '../../services/get_upcoming_races'
import { Race } from '../../models/race'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    footer: {
        position: 'fixed',
        bottom: 0,
        width: 600,
    },
}))
export const UpcomingRacesTable = ({ upcomingRacesParams }: { upcomingRacesParams: UpcomingRacesParams }) => {
    const [races, updateRaces] = useState([] as Race[])
    const [isOpen, updateIsOpen] = useState(false)
    useEffect(() => {
        getUpcomingRaces(upcomingRacesParams).then((r) => updateRaces(r))
        const upcomingTimer = window.setInterval(() => {
            getUpcomingRaces(upcomingRacesParams).then((r) => updateRaces(r))
        }, 5000)
        return () => {
            clearInterval(upcomingTimer)
        }
    }, [upcomingRacesParams])

    const classes = useStyles()

    return (
        <>
            <Accordion
                className={classes.footer}
                expanded={isOpen}
                onChange={(event, expanded) => updateIsOpen(expanded)}
            >
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography variant={'body1'}>All Upcoming Races</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {isOpen && (
                        <Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Race</TableCell>
                                        <TableCell>Race Type</TableCell>
                                        <TableCell>MTP</TableCell>
                                        <TableCell>Number of horses</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {races
                                        .filter((r) => r.actualPostTime < new Date('2000-01-01'))
                                        .map((r) => (
                                            <TableRow key={r.id}>
                                                <TableCell>
                                                    {r.longTrackName} {r.raceNumber}
                                                </TableCell>
                                                <TableCell>{r.raceType}</TableCell>
                                                <TableCell>{r.mtp}</TableCell>
                                                <TableCell>{r.numberOfHorses}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

import axios, { isAxiosError } from 'axios'
import { Race } from '../models/race'
import { getErrorData } from './get_error'
import { userService } from './login'
import { Dispatch } from 'redux'

export interface UpcomingRacesParams {
    mtp: number
    raceType: string | null
    onlyAssigned: boolean
    trackingType: string
    country: string
    dispatch: Dispatch<any>
    afterRecall?: boolean
}

export const getUpcomingRaces = async ({
    mtp,
    raceType,
    onlyAssigned,
    trackingType,
    country,
    dispatch,
    afterRecall,
}: UpcomingRacesParams) => {
    const url = '/api/v2/races/upcoming'

    const params = {
        mtp_maximum: mtp,
        race_type: raceType,
        only_assigned: onlyAssigned,
        tracking_type: trackingType,
        country: country,
        after_recall: afterRecall,
    }

    try {
        const response = await axios.get(url, {
            headers: userService.authHeader(),
            params,
            timeout: 1000 * 30,
        })

        const races: Race[] = response.data.map((jsonRace: any): Race => {
            return new Race(jsonRace)
        })

        return races
    } catch (error) {
        if (isAxiosError(error) && error.response) {
            getErrorData(error, dispatch)
        }

        return []
    }
}

import * as React from 'react'
import { ActionReviewAction } from '../../services/action_review'
import { Grid, Typography, Card } from '@material-ui/core'
import { GenericActionVideo } from '../common/GenericActionVideo'
import { makeStyles } from '@material-ui/core/styles'
import { Race } from '../../models/race'
import { getSilkColor, getSilkColorsForRaceType } from '../../services/silk_colors'
import { JockeySilk } from '../common/JockeySilk'

const useStyles = makeStyles((theme) => ({
    videoCard: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    runnerNumber: {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1.5rem !important',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    jockeySilksImage: {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer',
    },
}))

const getRaceTypeString = (race: Race) => {
    let raceTypeString = `RaceType: ${race.raceType}`
    if ((race?.raceSubType?.length ?? 0) > 0) {
        raceTypeString += `, RaceSubType: ${race.raceSubType}`
    }
    if ((race?.startType?.length ?? 0) > 0) {
        raceTypeString += `, StartType: ${race.startType}`
    }
    return raceTypeString
}

const findRunner = (race: Race, programNumber: number) => {
    return race.runners.find((runner) => runner.programNumber === programNumber.toString(10))
}

export const ActionReviewVideo = ({ action, race }: { action: ActionReviewAction; race: Race }) => {
    const classes = useStyles()

    let actionText: string
    if (action.RunnerEventID) {
        actionText = `${action.CollapsedType} for runner ${action.ProgramNumber}`
    } else {
        actionText = action.CollapsedType
    }

    const runner = findRunner(race, action.ProgramNumber)
    const silkColors = getSilkColorsForRaceType(race.country, race.raceType)

    return (
        <Grid item container direction={'column'} alignItems={'center'} spacing={3}>
            <Card className={classes.videoCard}>
                <Grid>
                    <Grid item container direction={'row'} justifyContent={'center'}>
                        <Grid item>
                            <Typography variant={'h5'}>
                                Race: {action.RaceDate} {action.TrackCode} {action.RaceNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                    {race && (
                        <Grid item container direction={'row'} justifyContent={'center'}>
                            <Grid item>
                                <Typography variant={'h6'}>{getRaceTypeString(race)}</Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item container direction={'row'} justifyContent={'center'}>
                        <Grid item>
                            <Typography variant={'h5'}>Action: {actionText}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction={'row'} justifyContent={'center'}>
                        <Grid item>
                            <GenericActionVideo
                                RaceID={action.RaceID}
                                RaceDate={action.RaceDate}
                                TrackCode={action.TrackCode}
                                RaceNumber={action.RaceNumber}
                                Height={600}
                                Width={800}
                                RaceStateID={action.RaceStateID ?? undefined}
                                RunnerEventID={action.RunnerEventID ?? undefined}
                            />
                        </Grid>
                    </Grid>
                    {(race?.country === 'FRANCE' || race?.country === 'USA') && runner && (
                        <Grid item container direction={'row'} justifyContent={'center'}>
                            <Grid item container direction={'column'} spacing={1} alignItems={'center'}>
                                <Grid item>
                                    <JockeySilk race={race} runner={runner} />
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        color: getSilkColor(silkColors, runner.programNumber).color,
                                        backgroundColor: getSilkColor(silkColors, runner.programNumber).silkColor,
                                    }}
                                    className={`${classes.runnerNumber} generic-tracker-number-${runner.programNumber}`}
                                >
                                    {runner.programNumber}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Card>
        </Grid>
    )
}

import axios, { AxiosRequestConfig } from 'axios'
import { Dispatch } from 'react'
import { getErrorData } from './get_error'

export const userService = {
    authHeader,
    login,
    logout,
}

function login(username: string, password: string) {
    const body = JSON.stringify({ username, password })
    const requestOptions: AxiosRequestConfig = {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
    }

    return axios
        .post('/login', body, requestOptions)
        .then(handleResponse)
        .then((response) => {
            const user = {
                token: response.token,
            }
            if (response.token) {
                localStorage.setItem('user', JSON.stringify(user))
            }

            return user
        })
}

function logout() {
    localStorage.removeItem('user')
}

function handleResponse(response: any) {
    const data = response.data

    if (response.status !== 200) {
        if (response.status === 401) {
            logout()
            // eslint-disable-next-line no-restricted-globals
            location.reload()
        }

        const error = data?.message || response.statusText
        return Promise.reject(error)
    }

    return data
}

function authHeader() {
    const userString = localStorage.getItem('user')

    if (!userString) {
        return {}
    }

    const user = JSON.parse(userString)

    if (user?.token) {
        return { Authorization: 'Bearer ' + user.token }
    } else {
        return {}
    }
}

export const refreshToken = async (dispatch: Dispatch<any>) => {
    try {
        const url = '/api/v2/refresh_token'
        const response = await axios.get(url, {
            headers: userService.authHeader(),
            timeout: 1000 * 30,
        })
        if (response.data.token) {
            const user = {
                token: response.data.token,
            }
            localStorage.setItem('user', JSON.stringify(user))
        }
    } catch (error) {
        getErrorData(error, dispatch)
    }
}

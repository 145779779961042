export function isElectron(): boolean {
    const turnOffDesktop = new URLSearchParams(window.location.search).get('debug')
    const userAgent = navigator.userAgent.toLowerCase()
    return (
        userAgent.indexOf(' electron/') > -1 ||
        process.env.REACT_APP_ENV === 'test' ||
        process.env.REACT_APP_ENV === 'development' ||
        turnOffDesktop !== null
    )
}

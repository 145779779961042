import { AppBar, Card, Grid, Tab, Tabs } from '@material-ui/core'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { getHistoricalTrackers, GenericHistoricalTracker } from '../../services/generic_historical_tracker'
import { GenericHistoricalTrackerComponent } from '../generic_historical_tracker/GenericHistoricalTracker'
import { makeStyles } from '@material-ui/core/styles'
import { getErrorData } from '../../services/get_error'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: theme.spacing(1),
    },
    header: {
        width: '100%',
        marginRight: 'auto',
    },
}))

const GenericHistoricalTrackerContainer = () => {
    const [trackers, setTrackers] = useState(null as GenericHistoricalTracker[] | null)
    const [noTrackers, setNoTrackers] = useState(false)
    const [currentTracker, setCurrentTracker] = React.useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchTrackers = async () => {
            try {
                const trackers = await getHistoricalTrackers()
                if (trackers && trackers.length > 0) {
                    setTrackers(trackers)
                    setCurrentTracker(trackers[0].Name)
                } else {
                    setNoTrackers(true)
                }
            } catch (err) {
                getErrorData(err, dispatch)
                setNoTrackers(true)
            }
        }
        if (!trackers) {
            fetchTrackers()
        }
    }, [trackers, dispatch])

    const classes = useStyles()
    return (
        <>
            <Card className={classes.cardRoot}>
                <Grid container justifyContent={'center'} direction={'row'}>
                    <Grid item>
                        {noTrackers && 'No Historical Trackers'}
                        {trackers && currentTracker !== '' && (
                            <>
                                <AppBar position="static">
                                    <Tabs
                                        value={currentTracker}
                                        onChange={(event, newValue) => setCurrentTracker(newValue)}
                                        aria-label="historical trackers"
                                    >
                                        {trackers.map((t) => (
                                            <Tab label={t.Name} value={t.Name} key={t.Name} />
                                        ))}
                                    </Tabs>
                                </AppBar>
                                {trackers.map((t) => {
                                    if (t.Name !== currentTracker) {
                                        return null
                                    }
                                    return (
                                        <GenericHistoricalTrackerComponent
                                            tracker={t}
                                            key={t.Name}
                                            isEmbeddedTracker={true}
                                            isQuizPracticeMode={false}
                                        />
                                    )
                                })}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default GenericHistoricalTrackerContainer

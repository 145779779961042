import { IRunnerEvent, IRunnerEventJson } from '../types'

export class RunnerEvent implements IRunnerEvent {
    public id: number
    public runnerID: number
    public type: string
    public videoTimestamp: number
    public videoSource: string
    public genericRealTimeTrackerID: number | null
    public genericHistoricalTrackerID: number | null
    public createdAt: Date
    public userName: string
    public teamName: string

    constructor(runnerEventJSON: IRunnerEventJson) {
        this.id = runnerEventJSON.id
        this.runnerID = runnerEventJSON.runner_id
        this.type = runnerEventJSON.type
        this.videoTimestamp = runnerEventJSON.video_timestamp
        this.videoSource = runnerEventJSON.video_source
        this.createdAt = new Date(runnerEventJSON.created_at)
        this.userName = runnerEventJSON.user_name
        this.teamName = runnerEventJSON.team_name
        this.genericRealTimeTrackerID = runnerEventJSON.generic_real_time_tracker_id
        this.genericHistoricalTrackerID = runnerEventJSON.generic_historical_tracker_id
    }
}

import React from 'react'
import { Mic } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { RecordRTCPromisesHandler } from 'recordrtc'
import { getDeepgramAccessKey } from '../../../services/get_deepgram_access_key'
import { useDispatch } from 'react-redux'
import { numbers } from './ParseAudio'
import { successDing } from './SuccessDing'

export interface TextReturnedResult {
    transcript: string
    isFinal: boolean
    startTime: number
    endTime: number
    stopRecording: () => void
}

interface AudioTranscriptionProps {
    keywords: string[]
    onTextReturned: (result: TextReturnedResult) => void
    onStartRecording: () => void
    onStopRecording: (blob: any) => void
}

export const AudioTranscription = ({
    keywords,
    onTextReturned,
    onStartRecording,
    onStopRecording,
}: AudioTranscriptionProps) => {
    const dispatch = useDispatch()

    const [webSocket, setWebSocket] = React.useState<WebSocket | null>(null)
    const webSocketRef = React.useRef<WebSocket | null>(null)
    webSocketRef.current = webSocket

    const [mediaRecorder, setMediaRecorder] = React.useState<any>()
    const mediaRecorderRef = React.useRef<any>()
    mediaRecorderRef.current = mediaRecorder

    const setupDeepgramWebSocket = async (stream: any) => {
        const mediaRecorder = new MediaRecorder(stream)
        const accessToken = await getDeepgramAccessKey(dispatch)
        const keywordsParam = buildKeyWordsParam(keywords)
        const socket = new WebSocket(`wss://api.deepgram.com/v1/listen?${keywordsParam}`, ['token', accessToken])
        setWebSocket(socket)

        socket.onopen = () => {
            onStartRecording()
            mediaRecorder.addEventListener('dataavailable', (event: any) => {
                if (event.data.size > 0 && socket.readyState === 1) {
                    socket.send(event.data)
                }
            })
            mediaRecorder.start(100)
        }

        socket.onmessage = (message) => {
            const received = JSON.parse(message.data)
            const transcript = received.channel.alternatives[0].transcript
            if (transcript) {
                onTextReturned({
                    transcript,
                    isFinal: received.is_final,
                    startTime: received.start,
                    endTime: received.start + received.duration,
                    stopRecording: stopWebsocket,
                })
            }
        }
    }

    const recordAudioData = async (stream: MediaStream) => {
        const recorder = new RecordRTCPromisesHandler(stream, {
            type: 'audio',
            disableLogs: true,
        })
        recorder.startRecording()
        setMediaRecorder(recorder)
        return stream
    }

    const startWebSocket = () => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(recordAudioData).then(setupDeepgramWebSocket)

        setTimeout(stopWebsocket, 1_200_000 /*20 minutes max record time*/)
    }

    const stopWebsocket = async () => {
        if (webSocketRef.current) {
            webSocketRef.current.close()
            await mediaRecorderRef.current.stopRecording()
            const blob = await mediaRecorderRef.current.getBlob()
            await onStopRecording(blob)
            setWebSocket(null)
        }
    }

    return (
        <>
            <IconButton
                size={'medium'}
                onClick={async () => {
                    successDing.play()
                    if (webSocket) {
                        await stopWebsocket()
                    } else {
                        startWebSocket()
                    }
                }}
                color={webSocket ? 'secondary' : 'primary'}
            >
                <Mic style={{ fontSize: '250px' }} />
            </IconButton>
        </>
    )
}

const buildKeyWordsParam = (keywords: string[]) => {
    const keywordsParam = keywords.map((keyword) => `keywords=${keyword}:2`).join('&')
    const numbersParam = numbers.map((num) => `keywords=${num}:2`).join('&')
    return `${keywordsParam}&${numbersParam}`
}

import React, { useEffect, useState } from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { ArrowDropDown } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { MistakesList } from './MistakesList'
import { UnifiedAssessment } from '../../services/tracker_assessment'

export interface Props {
    assessments: UnifiedAssessment[]
    selectedAssessment: UnifiedAssessment | null
    setSelectedAssessment: (assessment: UnifiedAssessment | null) => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}))

export const MistakeSelector = ({ assessments, selectedAssessment, setSelectedAssessment }: Props) => {
    const types = [...new Set(assessments.map((a) => a.Type))]
    const classes = useStyles()
    const [selectedAction, setSelectedAction] = useState(null as string | null)
    const mistakesToReview = assessments.filter((assessment) => {
        return assessment.Points !== assessment.PossiblePoints && assessment.Type === selectedAction
    })
    useEffect(() => {
        setSelectedAssessment(null)
    }, [assessments, setSelectedAssessment])
    return (
        <Grid container direction={'column'} spacing={1} className={classes.root}>
            <Grid item>
                {!types && <Typography>No actions found</Typography>}
                {types && (
                    <Autocomplete
                        style={{ width: 350 }}
                        multiple={false}
                        id="quiz-tracking-module-selection"
                        options={types}
                        getOptionLabel={(type: string) => type}
                        value={selectedAction}
                        onChange={(event: any, type: any) => {
                            setSelectedAssessment(null)
                            setSelectedAction(type)
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Action mistakes to review"
                                placeholder="action"
                            />
                        )}
                        renderOption={(type: string) => (
                            <span className={`action-selector-option-${type}`}>{type}</span>
                        )}
                        popupIcon={<ArrowDropDown className={'action-type'} />}
                    />
                )}
            </Grid>
            <Grid item>
                {!(assessments && assessments.length > 0) && <Typography>No mistakes found</Typography>}
                {mistakesToReview && mistakesToReview.length > 0 && (
                    <MistakesList
                        mistakes={mistakesToReview}
                        selectedAssessment={selectedAssessment}
                        setSelectedAssessment={setSelectedAssessment}
                    />
                )}
            </Grid>
        </Grid>
    )
}

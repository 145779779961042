import * as React from 'react'
import { Suspense } from 'react'
import { Race } from '../../models/race'
import { Runner } from '../../models/runner'
import { makeStyles } from '@material-ui/core/styles'
import { useImage } from 'react-image'
import { CircularProgress } from '@material-ui/core'

const jockeySilksS3BaseURL = 'https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS'

interface Props {
    race: Race
    runner: Runner
}
const useStyles = makeStyles((theme) => ({
    jockeySilksImage: {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer',
    },
}))

export const JockeySilk = ({ race, runner }: Props) => {
    return (
        <Suspense fallback={<CircularProgress />}>
            <JockeySilkImage race={race} runner={runner} />
        </Suspense>
    )
}

export const JockeySilkImage = ({ race, runner }: Props) => {
    const classes = useStyles()
    const jockeySilksS3Image = getJockeySilkS3Image({ race, runner })
    const defaultSilkURL = getDefaultSilkURL()

    const { src, error } = useImage({
        srcList: [jockeySilksS3Image, defaultSilkURL],
    })

    if (process.env.REACT_APP_ENV === 'test') {
        return null
    }

    if (error !== null) {
        return null
    }

    return (
        <>
            <img
                className={classes.jockeySilksImage}
                src={src}
                alt="jockey silk"
                onError={({ currentTarget }) => {
                    if (currentTarget.src !== defaultSilkURL) {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = defaultSilkURL
                    }
                }}
            />
        </>
    )
}

const getJockeySilkS3Image = ({ race, runner }: Props) => {
    return `${jockeySilksS3BaseURL}/${race.country}/${race.raceDate}/${race.trackCode}/${race.raceNumber}/${race.raceDate}_${race.trackCode}_${race.raceNumber}_runner_${runner.programNumber}.png`
}

const getDefaultSilkURL = () => {
    return `${jockeySilksS3BaseURL}/DEFAULT_SILK.svg`
}

import axios from 'axios'
import { userService } from './login'

export interface TrackerAssessment {
    RaceID: number
    RaceDate: number
    TrackCode: string
    RaceNumber: number
    TrackingType: string
    GenericRealTimeTrackerID: number
    GenericHistoricalTrackerID: number
    UserID: number
    UserName: string
    AssessedAt: Date
    AnswerKeyCreatedAt: Date
    TrackedRace: boolean
    TrackedPoints: number
    PossibleTrackedPoints: number
    CorrectnessPoints: number
    PossibleCorrectnessPoints: number
    RaceStateAssessments: RaceStateAssessment[]
    RunnerEventAssessments: RunnerEventAssessment[]
}

export interface RaceStateAssessment {
    ID: number
    Type: string
    ShouldNotHaveType: boolean
    ExpectedRaceStateID: number
    ActualRaceStateID: number
    RaceTrackingAssessmentResponseID: number | null
    AssessmentText: string
    Points: number
    PossiblePoints: number
    Acknowledged: boolean
    Objected: boolean
    ResponseComment: string
    Approved: boolean
    Rejected: boolean
    RejectionComment: string
}

export interface RunnerEventAssessment {
    ID: number
    RunnerID: number
    ProgramNumber: string
    RunnerEventGroupName: string | null
    Type: string
    ExampleExpectedRunnerEventID: number | null
    ActualRunnerEventID: number | null
    RaceTrackingAssessmentResponseID: number | null
    AssessmentText: string
    Points: number
    PossiblePoints: number
    Acknowledged: boolean
    Objected: boolean
    ResponseComment: string
    Approved: boolean
    Rejected: boolean
    RejectionComment: string
    ShouldNotHaveType: boolean
}

export interface TrackerAssessmentJson {
    race_id: number
    race_date: number
    track_code: string
    race_number: number
    tracking_type: string
    generic_real_time_tracker_id: number
    generic_historical_tracker_id: number
    user_id: number
    user_name: string
    assessed_at: Date
    answer_key_created_at: Date
    tracked_race: boolean
    tracked_points: number
    possible_tracked_points: number
    correctness_points: number
    possible_correctness_points: number
    race_state_assessments: RaceStateAssessmentJson[]
    runner_event_assessments: RunnerEventAssessmentJson[]
}

export interface RaceStateAssessmentJson {
    id: number
    type: string
    should_not_have_type: boolean
    expected_race_state_id: number
    actual_race_state_id: number
    race_tracking_assessment_response_id: number | null
    assessment_text: string
    points: number
    possible_points: number
    acknowledged: boolean
    objected: boolean
    response_comment: string
    approved: boolean
    rejected: boolean
    rejection_comment: string
}

export interface RunnerEventAssessmentJson {
    id: number
    runner_id: number
    program_number: string
    runner_event_group_name: string | null
    type: string
    expected_runner_event_id: number | null
    actual_runner_event_id: number | null
    race_tracking_assessment_response_id: number | null
    assessment_text: string
    points: number
    possible_points: number
    acknowledged: boolean
    objected: boolean
    response_comment: string
    approved: boolean
    rejected: boolean
    rejection_comment: string
    should_not_have_type: boolean
}

export interface UnifiedAssessment {
    GenericHistoricalTrackerID: number | null
    GenericRealTimeTrackerID: number | null
    RaceID: number
    RaceDate: number
    TrackCode: string
    RaceNumber: number
    UserID: number
    UserName: string
    TeamID: number
    TeamName: string
    RaceTrackingRaceStateAssessmentID: number | null
    RaceTrackingRunnerEventAssessmentID: number | null
    RaceTrackingAssessmentResponseID: number | null
    Type: string
    ShouldNotHaveType: boolean
    RunnerID: number | null
    ProgramNumber: string | null
    ExampleExpectedRaceStateID: number | null
    ActualRaceStateID: number | null
    ExampleExpectedRunnerEventID: number | null
    ActualRunnerEventID: number | null
    AssessmentText: string
    Points: number
    PossiblePoints: number
    Acknowledged: boolean
    Objected: boolean
    ResponseComment: string
    Approved: boolean
    Rejected: boolean
    RejectionComment: string
}

export interface UnifiedAssessmentJson {
    generic_historical_tracker_id: number | null
    generic_real_time_tracker_id: number | null
    race_id: number
    race_date: number
    track_code: string
    race_number: number
    user_id: number
    user_name: string
    team_id: number
    team_name: string
    race_tracking_race_state_assessment_id: number | null
    race_tracking_runner_event_assessment_id: number | null
    race_tracking_assessment_response_id: number | null
    type: string
    should_not_have_type: boolean
    runner_id: number | null
    program_number: string | null
    example_expected_race_state_id: number | null
    actual_race_state_id: number | null
    expected_runner_event_id: number | null
    actual_runner_event_id: number | null
    assessment_text: string
    points: number
    possible_points: number
    acknowledged: boolean
    objected: boolean
    response_comment: string
    approved: boolean
    rejected: boolean
    rejection_comment: string
}

export const getTrackerMistakes = async (minTrackedDate: Date, maxTrackedDate: Date) => {
    const url = `/api/v2/tracker_mistakes?min_tracked_date=${
        minTrackedDate.toISOString().split('T')[0]
    }&max_tracked_date=${maxTrackedDate.toISOString().split('T')[0]}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const unifiedAssessmentsJson = response.data as UnifiedAssessmentJson[]
    return unifiedAssessmentsFromJson(unifiedAssessmentsJson)
}

export const getTrackerObjections = async (minTrackedDate: Date, maxTrackedDate: Date) => {
    const url = `/api/v2/tracker_objections?min_tracked_date=${
        minTrackedDate.toISOString().split('T')[0]
    }&max_tracked_date=${maxTrackedDate.toISOString().split('T')[0]}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const unifiedAssessmentsJson = response.data as UnifiedAssessmentJson[]
    return unifiedAssessmentsFromJson(unifiedAssessmentsJson)
}

export const createAssessmentResponse = async (
    raceStateAssessmentID: number | null,
    runnerEventAssessmentID: number | null,
    acknowledged: boolean,
    objected: boolean,
    comment: string
) => {
    const url = '/api/v2/assessment_response'
    const data = {
        race_tracking_race_state_assessment_id: raceStateAssessmentID,
        race_tracking_runner_event_assessment_id: runnerEventAssessmentID,
        acknowledged: acknowledged,
        objected: objected,
        comment: comment,
    }
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return response.status === 200
}

export const approveRejectAssessmentResponse = async (
    assessmentResponseID: number,
    approved: boolean,
    rejected: boolean,
    comment: string
) => {
    const url = `/api/v2/approve_reject_assessment_response`
    const data = {
        assessment_response_id: assessmentResponseID,
        approved: approved,
        rejected: rejected,
        comment: comment,
    }
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return response.status === 200
}

export const UserMarked = (assessment: UnifiedAssessment) => {
    return (
        (assessment.ShouldNotHaveType && assessment.Points === 0) ||
        (!assessment.ShouldNotHaveType && assessment.Points === assessment.PossiblePoints)
    )
}

export const flattenToActionAssessments = (assessments: TrackerAssessment[]) => {
    return assessments
        .map((assessment) => getUnifiedAssessments(assessment))
        .reduce((accumulator, current) => [...accumulator, ...current], [] as UnifiedAssessment[])
}

export const unifiedAssessmentsFromJson = (unifiedAssessmentsJson: UnifiedAssessmentJson[]) => {
    return unifiedAssessmentsJson.map((assessmentJson) => ({
        GenericHistoricalTrackerID: assessmentJson.generic_historical_tracker_id,
        GenericRealTimeTrackerID: assessmentJson.generic_real_time_tracker_id,
        RaceID: assessmentJson.race_id,
        RaceDate: assessmentJson.race_date,
        TrackCode: assessmentJson.track_code,
        RaceNumber: assessmentJson.race_number,
        UserID: assessmentJson.user_id,
        UserName: assessmentJson.user_name,
        TeamID: assessmentJson.team_id,
        TeamName: assessmentJson.team_name,
        RaceTrackingRaceStateAssessmentID: assessmentJson.race_tracking_race_state_assessment_id,
        RaceTrackingRunnerEventAssessmentID: assessmentJson.race_tracking_runner_event_assessment_id,
        RaceTrackingAssessmentResponseID: assessmentJson.race_tracking_assessment_response_id,
        Type: assessmentJson.type,
        ShouldNotHaveType: assessmentJson.should_not_have_type,
        RunnerID: assessmentJson.runner_id,
        ProgramNumber: assessmentJson.program_number,
        ExampleExpectedRaceStateID: assessmentJson.example_expected_race_state_id,
        ActualRaceStateID: assessmentJson.actual_race_state_id,
        ExampleExpectedRunnerEventID: assessmentJson.expected_runner_event_id,
        ActualRunnerEventID: assessmentJson.actual_runner_event_id,
        AssessmentText: assessmentJson.assessment_text,
        Points: assessmentJson.points,
        PossiblePoints: assessmentJson.possible_points,
        Acknowledged: assessmentJson.acknowledged,
        Objected: assessmentJson.objected,
        ResponseComment: assessmentJson.response_comment,
        Approved: assessmentJson.approved,
        Rejected: assessmentJson.rejected,
        RejectionComment: assessmentJson.rejection_comment,
    }))
}

export const assessmentFromJson = (assessment: TrackerAssessmentJson) => {
    return {
        RaceID: assessment.race_id,
        RaceDate: assessment.race_date,
        TrackCode: assessment.track_code,
        RaceNumber: assessment.race_number,
        TrackingType: assessment.tracking_type,
        GenericRealTimeTrackerID: assessment.generic_real_time_tracker_id,
        GenericHistoricalTrackerID: assessment.generic_historical_tracker_id,
        UserID: assessment.user_id,
        UserName: assessment.user_name,
        AssessedAt: assessment.assessed_at,
        AnswerKeyCreatedAt: assessment.answer_key_created_at,
        TrackedRace: assessment.tracked_race,
        TrackedPoints: assessment.tracked_points,
        PossibleTrackedPoints: assessment.possible_tracked_points,
        CorrectnessPoints: assessment.correctness_points,
        PossibleCorrectnessPoints: assessment.possible_correctness_points,
        RaceStateAssessments: assessment.race_state_assessments.map(
            (rs) =>
                ({
                    ID: rs.id,
                    Type: rs.type,
                    ShouldNotHaveType: rs.should_not_have_type,
                    ExpectedRaceStateID: rs.expected_race_state_id,
                    ActualRaceStateID: rs.actual_race_state_id,
                    RaceTrackingAssessmentResponseID: rs.race_tracking_assessment_response_id,
                    AssessmentText: rs.assessment_text,
                    Points: rs.points,
                    PossiblePoints: rs.possible_points,
                    Acknowledged: rs.acknowledged,
                    Objected: rs.objected,
                    ResponseComment: rs.response_comment,
                    Approved: rs.approved,
                    Rejected: rs.rejected,
                    RejectionComment: rs.rejection_comment,
                } as RaceStateAssessment)
        ),
        RunnerEventAssessments: assessment.runner_event_assessments.map(
            (re) =>
                ({
                    ID: re.id,
                    RunnerID: re.runner_id,
                    ProgramNumber: re.program_number,
                    RunnerEventGroupName: re.runner_event_group_name,
                    Type: re.type,
                    ExampleExpectedRunnerEventID: re.expected_runner_event_id,
                    ActualRunnerEventID: re.actual_runner_event_id,
                    RaceTrackingAssessmentResponseID: re.race_tracking_assessment_response_id,
                    AssessmentText: re.assessment_text,
                    Points: re.points,
                    PossiblePoints: re.possible_points,
                    Acknowledged: re.acknowledged,
                    Objected: re.objected,
                    ResponseComment: re.response_comment,
                    Approved: re.approved,
                    Rejected: re.rejected,
                    RejectionComment: re.rejection_comment,
                    ShouldNotHaveType: re.should_not_have_type,
                } as RunnerEventAssessment)
        ),
    } as TrackerAssessment
}

export const getUnifiedAssessments = (assessment: TrackerAssessment) => {
    const unifiedAssessments = [
        ...assessment.RaceStateAssessments.map(
            (sa) =>
                ({
                    GenericHistoricalTrackerID: assessment.GenericHistoricalTrackerID,
                    GenericRealTimeTrackerID: assessment.GenericRealTimeTrackerID,
                    RaceID: assessment.RaceID,
                    RaceDate: assessment.RaceDate,
                    TrackCode: assessment.TrackCode,
                    RaceNumber: assessment.RaceNumber,
                    UserID: assessment.UserID,
                    UserName: assessment.UserName,
                    TeamID: 0,
                    TeamName: 'N/A',
                    RaceTrackingRaceStateAssessmentID: sa.ID,
                    RaceTrackingRunnerEventAssessmentID: null,
                    RaceTrackingAssessmentResponseID: sa.RaceTrackingAssessmentResponseID,
                    Type: sa.Type,
                    ShouldNotHaveType: sa.ShouldNotHaveType,
                    RunnerID: null,
                    ProgramNumber: null,
                    ExampleExpectedRaceStateID: sa.ExpectedRaceStateID,
                    ActualRaceStateID: sa.ActualRaceStateID,
                    ExampleExpectedRunnerEventID: null,
                    ActualRunnerEventID: null,
                    AssessmentText: sa.AssessmentText,
                    Points: sa.Points,
                    PossiblePoints: sa.PossiblePoints,
                    Acknowledged: sa.Acknowledged,
                    Objected: sa.Objected,
                    ResponseComment: sa.ResponseComment,
                    Approved: sa.Approved,
                    Rejected: sa.Rejected,
                    RejectionComment: sa.RejectionComment,
                } as UnifiedAssessment)
        ),
        ...assessment.RunnerEventAssessments.map(
            (ra) =>
                ({
                    GenericHistoricalTrackerID: assessment.GenericHistoricalTrackerID,
                    GenericRealTimeTrackerID: assessment.GenericRealTimeTrackerID,
                    RaceID: assessment.RaceID,
                    RaceDate: assessment.RaceDate,
                    TrackCode: assessment.TrackCode,
                    RaceNumber: assessment.RaceNumber,
                    UserID: assessment.UserID,
                    UserName: assessment.UserName,
                    TeamID: 0,
                    TeamName: 'N/A',
                    RaceTrackingRaceStateAssessmentID: null,
                    RaceTrackingRunnerEventAssessmentID: ra.ID,
                    RaceTrackingAssessmentResponseID: ra.RaceTrackingAssessmentResponseID,
                    Type: ra.Type,
                    ShouldNotHaveType: ra.ShouldNotHaveType,
                    RunnerID: ra.RunnerID,
                    ProgramNumber: ra.ProgramNumber,
                    ExampleExpectedRaceStateID: null,
                    ActualRaceStateID: null,
                    ExampleExpectedRunnerEventID: ra.ExampleExpectedRunnerEventID,
                    ActualRunnerEventID: ra.ActualRunnerEventID,
                    AssessmentText: ra.AssessmentText,
                    Points: ra.Points,
                    PossiblePoints: ra.PossiblePoints,
                    Acknowledged: ra.Acknowledged,
                    Objected: ra.Objected,
                    ResponseComment: ra.ResponseComment,
                    Approved: ra.Approved,
                    Rejected: ra.Rejected,
                    RejectionComment: ra.RejectionComment,
                } as UnifiedAssessment)
        ),
    ]
    return unifiedAssessments
}

import axios from 'axios'
import { userService } from './login'

export const fetchToggles = async () => {
    const url = '/api/v2/toggles'

    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })

    return response.data as Record<string, boolean>
}

export const toggleIsOn = (toggle: string, toggles: Record<string, boolean>) => {
    if (Object.keys(toggles).some((toggleName) => toggleName === toggle)) {
        return toggles[toggle]
    }
    return false
}

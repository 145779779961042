import { createAction, handleAction } from 'redux-actions'
import { TrackerAssessment } from '../services/tracker_assessment'

export const getTrackerAssessments = createAction('GET_TRACKER_ASSESSMENTS')

export const getTrackerAssessmentsReducer = handleAction(
    getTrackerAssessments,
    (state, action) => ({
        ...state,
        trackerAssessments: action.payload as TrackerAssessment[],
    }),
    { trackerAssessments: [] } as { trackerAssessments: TrackerAssessment[] }
)

import { createAction, handleAction } from 'redux-actions'
import { GenericRealTimeTracker } from '../services/generic_real_time_tracker'
import deepEqual from 'deep-equal'

export const getRealTimeTrackers = createAction('GET_REAL_TIME_TRACKERS')

export const getRealTimeTrackersReducer = handleAction(
    getRealTimeTrackers,
    (state, action) => {
        if (deepEqual(state.realTimeTrackers, action.payload)) {
            return state
        }
        return {
            ...state,
            realTimeTrackers: action.payload as GenericRealTimeTracker[] | null,
        }
    },
    { realTimeTrackers: null } as {
        realTimeTrackers: GenericRealTimeTracker[] | null
    }
)

import { createAction, handleAction } from 'redux-actions'
import { GenericHistoricalTracker } from '../services/generic_historical_tracker'
import deepEqual from 'deep-equal'

export const getHistoricalTrackers = createAction('GET_HISTORICAL_TRACKERS')

export const getHistoricalTrackersReducer = handleAction(
    getHistoricalTrackers,
    (state, action) => {
        if (deepEqual(state.historicalTrackers, action.payload)) {
            return state
        }
        return {
            ...state,
            historicalTrackers: action.payload as GenericHistoricalTracker[] | null,
        }
    },
    { historicalTrackers: null } as {
        historicalTrackers: GenericHistoricalTracker[] | null
    }
)

import { createAction, handleAction } from 'redux-actions'
import { User } from '../models/user'

export const getMe = createAction('GET_ME')

export const getMeReducer = handleAction(
    getMe,
    (state, action) => ({
        ...state,
        me: action.payload as User,
    }),
    { me: null } as { me: User | null }
)

import { Race } from '../models/race'
import arrayMove from 'array-move'

export const mergeRaceIdToOrderMap = (
    newRaces: Race[],
    oldRaceIdToIndexMap: Record<number, number>
): Record<number, number> => {
    const raceIds = newRaces.map((r) => r.id)
    const orderedRaceIds = sortRaceIdsByExistingIndexWithNewRacesAtEnd(raceIds, oldRaceIdToIndexMap)
    return getRaceIdToOrderMapFromSortedRaceIds(orderedRaceIds)
}

export const moveRace = (
    raceIdToIndexMap: Record<number, number>,
    oldIndex: number,
    newIndex: number
): Record<number, number> => {
    if (oldIndex === newIndex) {
        return raceIdToIndexMap
    }
    const sortedRaceIdsAfterMove = getSortedRaceIdsAfterMove(raceIdToIndexMap, oldIndex, newIndex)
    return getRaceIdToOrderMapFromSortedRaceIds(sortedRaceIdsAfterMove)
}

export const mapsHaveSameKeys = (firstMap: Record<number, number>, secondMap: Record<number, number>): boolean => {
    if (Object.keys(firstMap).length !== Object.keys(secondMap).length) {
        return false
    }
    return Object.keys(firstMap).every((key) => key in secondMap)
}

function sortRaceIdsByExistingIndexWithNewRacesAtEnd(
    raceIds: number[],
    oldRaceIdToIndexMap: Record<number, number>
): number[] {
    const oldNumberOfRaces = Object.keys(oldRaceIdToIndexMap).length
    const raceIdsWithOrder = raceIds.map((raceId: number) => {
        return {
            raceId: raceId,
            order: raceId in oldRaceIdToIndexMap ? oldRaceIdToIndexMap[raceId] : oldNumberOfRaces + 1,
        }
    })
    raceIdsWithOrder.sort((r1, r2) => r1.order - r2.order)
    return raceIdsWithOrder.map((r) => r.raceId)
}

function getRaceIdToOrderMapFromSortedRaceIds(orderedRaceIds: number[]) {
    return orderedRaceIds.reduce((map: Record<number, number>, raceId: number, index: number) => {
        map[raceId] = index
        return map
    }, {} as Record<number, number>)
}

function getSortedRacesIds(raceIdToIndexMap: Record<number, number>) {
    const sortedRaceIds = Object.keys(raceIdToIndexMap)
        .map((raceId) => parseInt(raceId))
        .sort((raceIdA, raceIdB) => raceIdToIndexMap[raceIdA] - raceIdToIndexMap[raceIdB])
    return sortedRaceIds
}

function getSortedRaceIdsAfterMove(raceIdToIndexMap: Record<number, number>, oldIndex: number, newIndex: number) {
    const sortedRaceIds = getSortedRacesIds(raceIdToIndexMap)
    const sortedRaceIdsAfterMove = arrayMove(sortedRaceIds, oldIndex, newIndex)
    return sortedRaceIdsAfterMove
}

import { AnswerKeyExpectedRaceState, AnswerKeyExpectedRunnerEvent } from '../../services/answer_keys'
import React, { useEffect, useState } from 'react'
import { Race } from '../../models/race'
import {
    createAnswerKey,
    createRaceStateAdjudication,
    createRunnerEventAdjudication,
    Quiz,
} from '../../services/quizzes'
import getRace from '../../services/get_race'
import { useDispatch, useSelector } from 'react-redux'
import { getErrorData } from '../../services/get_error'
import { Button, Grid, Typography } from '@material-ui/core'
import { RootStateType } from '../../store'
import {
    GenericHistoricalTracker,
    GenericHistoricalTrackerRunnerEvent,
    GenericHistoricalTrackerStartTracking,
} from '../../services/generic_historical_tracker'
import { IRaceState, IRunnerEvent } from '../../types'
import { Dispatch } from 'redux'
import { useSnackbar } from 'notistack'
import { GenericActionVideo } from '../common/GenericActionVideo'

export interface RunnerEventReviewProps {
    genericHistoricalTrackerID: number
    quiz: Quiz
    selectedRunnerEvent: AnswerKeyExpectedRunnerEvent | null
    selectedRaceState: AnswerKeyExpectedRaceState | null
    onActionAdjudicated: () => void
}

export const GenericSingleActionAdjudication = ({
    genericHistoricalTrackerID,
    quiz,
    selectedRunnerEvent,
    selectedRaceState,
    onActionAdjudicated,
}: RunnerEventReviewProps) => {
    const [race, setRace] = useState(null as Race | null)
    const [runnerEventUnderReview, setRunnerEventUnderReview] = useState(null as number | null)
    const [raceStateUnderReview, setRaceStateUnderReview] = useState(null as number | null)
    const dispatch = useDispatch()
    const tracker = useSelector((state: RootStateType) =>
        state?.historicalTrackersStore?.historicalTrackers?.find((t) => t.ID === genericHistoricalTrackerID)
    )

    useEffect(() => {
        fetchFullRace(quiz, setRace, dispatch)
    }, [quiz, dispatch])

    const { enqueueSnackbar } = useSnackbar()

    if (!tracker) {
        return <Typography> Tracking module not found </Typography>
    }
    if (!race || !(selectedRunnerEvent || selectedRaceState)) {
        return <Typography> Select a disagreement to review </Typography>
    }

    const allUsersThatTrackedRace = getAllTrackers(race, tracker)
    const selectionByUser = getSelectionsByUser(
        race,
        allUsersThatTrackedRace,
        selectedRunnerEvent,
        selectedRaceState,
        tracker
    )

    return (
        <Grid container direction={'column'} spacing={2}>
            {selectedRaceState && displayCurrentAnswerRaceState(race, selectedRaceState)}
            {selectedRunnerEvent && displayCurrentAnswerRunnerEvent(race, selectedRunnerEvent)}
            {allUsersThatTrackedRace.map((tracker) => {
                const videoTimestamp = selectionByUser[tracker]?.videoTimestamp
                return (
                    <React.Fragment key={tracker}>
                        <Grid item>
                            <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                                <Grid item>
                                    <Typography variant={'body1'}>
                                        {tracker}
                                        {selectionByUser[tracker] ? ' marked ' : ' did not mark '}
                                        {selectionByUser[tracker]?.type ??
                                            selectedRunnerEvent?.Type ??
                                            selectedRaceState?.Type}
                                        {videoTimestamp ? ` at ${formatSecondsAsMinutesSeconds(videoTimestamp)}` : '.'}
                                    </Typography>
                                </Grid>
                                {selectionByUser[tracker] && (
                                    <Grid item>
                                        <Button
                                            variant={'text'}
                                            onClick={() => {
                                                if (selectedRunnerEvent) {
                                                    setRunnerEventUnderReview(selectionByUser[tracker]!.id)
                                                }
                                                if (selectedRaceState) {
                                                    setRaceStateUnderReview(selectionByUser[tracker]!.id)
                                                }
                                            }}
                                        >
                                            Review
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={async () => {
                                            const { success, failureText } = await submitAdjudicationAndRegradeQuiz(
                                                !!selectedRunnerEvent,
                                                (selectedRunnerEvent?.ID ?? selectedRaceState?.ID)!,
                                                selectionByUser[tracker],
                                                genericHistoricalTrackerID,
                                                quiz.RaceID
                                            )
                                            if (success) {
                                                enqueueSnackbar('Successfully created quiz', {
                                                    variant: 'success',
                                                })
                                            } else {
                                                enqueueSnackbar(`Failed to create quiz ${failureText}`, {
                                                    variant: 'warning',
                                                })
                                            }
                                            onActionAdjudicated()
                                        }}
                                        className={`mark-correct-${tracker}`}
                                    >
                                        Mark as correct
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )
            })}
            {runnerEventUnderReview && (
                <GenericActionVideo
                    RaceID={race.id}
                    RaceDate={parseInt(race.raceDate)}
                    TrackCode={race.trackCode}
                    RaceNumber={race.raceNumber}
                    RaceStateID={undefined}
                    RunnerEventID={runnerEventUnderReview}
                    Height={600}
                    Width={800}
                />
            )}
            {raceStateUnderReview && (
                <GenericActionVideo
                    RaceID={race.id}
                    RaceDate={parseInt(race.raceDate)}
                    TrackCode={race.trackCode}
                    RaceNumber={race.raceNumber}
                    RaceStateID={raceStateUnderReview}
                    RunnerEventID={undefined}
                    Height={600}
                    Width={800}
                />
            )}
        </Grid>
    )
}

const fetchFullRace = async (quiz: Quiz, setRace: (r: Race) => void, dispatch: Dispatch<any>) => {
    try {
        const fullRace = await getRace(quiz.RaceDate, quiz.TrackCode, quiz.RaceNumber, true)
        setRace(fullRace)
    } catch (e) {
        getErrorData(e, dispatch)
    }
}

const getAllTrackers = (race: Race, tracker: GenericHistoricalTracker) => {
    return race.raceStates
        .filter((state) => state.type === GenericHistoricalTrackerStartTracking(tracker))
        .map((s) => s.user.userName)
}

const getSelectionsByUser = (
    race: Race,
    allTrackers: string[],
    selectedRunnerEvent: AnswerKeyExpectedRunnerEvent | null,
    selectedRaceState: AnswerKeyExpectedRaceState | null,
    tracker: GenericHistoricalTracker
) => {
    let actionsByUser = {} as Record<string, IRunnerEvent | IRaceState | null>
    allTrackers.forEach((user) => {
        actionsByUser[user] = getActionForUser(user, race, selectedRunnerEvent, selectedRaceState, tracker)
    })
    return actionsByUser
}

const getRaceStateForUser = (
    user: string,
    race: Race,
    selectedRaceState: AnswerKeyExpectedRaceState,
    tracker: GenericHistoricalTracker
) => {
    const sortedRaceStates = race.raceStates.sort((a, b) => b.time.getTime() - a.time.getTime())
    return sortedRaceStates.find((rs) => rs.user.userName === user && rs.type === selectedRaceState.Type) ?? null
}

function isMarkingFromRunnerEventGroup(
    runnerEvent: IRunnerEvent,
    tracker: GenericHistoricalTracker,
    genericHistoricalRunnerEventGroupID: number
) {
    const runnerEventGroup = tracker.GenericHistoricalTrackerRunnerEventGroups.find(
        (group) => group.ID === genericHistoricalRunnerEventGroupID
    )!
    for (let i = 0; i < runnerEventGroup.GenericHistoricalTrackerRunnerEvents.length; i++) {
        let genericRunnerEvent = runnerEventGroup.GenericHistoricalTrackerRunnerEvents[i]
        const [yesType, noType] = getYesTypeNoTypeForRunnerEvent(genericRunnerEvent)
        if (runnerEvent.type === yesType) {
            return { isRunnerEventGroup: true, isRemoveMarking: false }
        }
        if (runnerEvent.type === noType) {
            return { isRunnerEventGroup: true, isRemoveMarking: true }
        }
    }
    return { isRunnerEventGroup: false, isRemoveMarking: false }
}

const getRunnerEventGroupForUser = (
    user: string,
    race: Race,
    selectedRunnerEvent: AnswerKeyExpectedRunnerEvent,
    tracker: GenericHistoricalTracker
) => {
    const runnersEvents =
        race.runners.find((runner) => runner.programNumber === selectedRunnerEvent.ProgramNumber)?.runnerEvents ?? []
    const sortedRunnerEvents = runnersEvents.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
    for (let i = 0; i < sortedRunnerEvents.length; i++) {
        let runnerEvent = sortedRunnerEvents[i]
        if (runnerEvent.userName !== user) {
            continue
        }
        let { isRunnerEventGroup, isRemoveMarking } = isMarkingFromRunnerEventGroup(
            runnerEvent,
            tracker,
            selectedRunnerEvent.GenericHistoricalRunnerEventGroupID!
        )
        if (isRunnerEventGroup) {
            if (isRemoveMarking) {
                return null
            } else {
                return runnerEvent
            }
        }
    }
    return null
}

const getRunnerEventForUser = (
    user: string,
    race: Race,
    selectedRunnerEvent: AnswerKeyExpectedRunnerEvent,
    tracker: GenericHistoricalTracker
) => {
    const runnersEvents =
        race.runners.find((runner) => runner.programNumber === selectedRunnerEvent.ProgramNumber)?.runnerEvents ?? []
    const sortedRunnerEvents = runnersEvents.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
    const [yesType, noType] = getYesTypeNoTypeForRunnerEventAnswerKey(selectedRunnerEvent, tracker)
    const runnerEvent =
        sortedRunnerEvents.find(
            (runnerEvent) =>
                runnerEvent.userName === user && (runnerEvent.type === yesType || runnerEvent.type === noType)
        ) ?? null
    if (runnerEvent?.type === noType) {
        return null
    }
    return runnerEvent
}

const getActionForUser = (
    user: string,
    race: Race,
    selectedRunnerEvent: AnswerKeyExpectedRunnerEvent | null,
    selectedRaceState: AnswerKeyExpectedRaceState | null,
    tracker: GenericHistoricalTracker
) => {
    if (selectedRaceState) {
        return getRaceStateForUser(user, race, selectedRaceState, tracker)
    }

    if (!selectedRunnerEvent) {
        return null
    }

    if (selectedRunnerEvent?.GenericHistoricalRunnerEventGroupID) {
        return getRunnerEventGroupForUser(user, race, selectedRunnerEvent, tracker)
    }

    return getRunnerEventForUser(user, race, selectedRunnerEvent, tracker)
}

const getYesTypeNoTypeForRunnerEventAnswerKey = (
    selectedRunnerEvent: AnswerKeyExpectedRunnerEvent,
    tracker: GenericHistoricalTracker
) => {
    const genericRunnerEvent = tracker.GenericHistoricalTrackerRunnerEvents.find(
        (re) => re.RunnerEvent === selectedRunnerEvent.Type || re.RunnerEventChecked === selectedRunnerEvent.Type
    )!
    return getYesTypeNoTypeForRunnerEvent(genericRunnerEvent)
}

const getYesTypeNoTypeForRunnerEvent = (genericRunnerEvent: GenericHistoricalTrackerRunnerEvent) => {
    if (genericRunnerEvent.IsCheckbox) {
        return [genericRunnerEvent.RunnerEventChecked, genericRunnerEvent.RunnerEvent]
    } else {
        return [genericRunnerEvent.RunnerEvent, 'REMOVE_' + genericRunnerEvent.RunnerEvent]
    }
}

const submitAdjudicationAndRegradeQuiz = async (
    isRunnerEventAdjudication: boolean,
    answerKeyActionID: number,
    usersSelection: IRunnerEvent | IRaceState | null,
    genericHistoricalTrackerID: number,
    raceID: number
) => {
    if (isRunnerEventAdjudication) {
        const { success, failureText } = await createRunnerEventAdjudication(
            answerKeyActionID,
            !usersSelection,
            usersSelection?.id ?? null
        )
        if (success) {
            return await createAnswerKey(genericHistoricalTrackerID, raceID)
        }
        return { success, failureText }
    }

    const { success, failureText } = await createRaceStateAdjudication(
        answerKeyActionID,
        !usersSelection,
        usersSelection?.id ?? null
    )
    if (success) {
        return await createAnswerKey(genericHistoricalTrackerID, raceID)
    }
    return { success, failureText }
}

const displayCurrentAnswerRaceState = (race: Race, selectedRaceState: AnswerKeyExpectedRaceState) => {
    return (
        <>
            <Grid item>
                <Typography variant={'h6'}>
                    {race.raceKey()} - {selectedRaceState.Type}{' '}
                    {selectedRaceState.ShouldNotHaveType
                        ? ' should NOT have been clicked'
                        : ' should have been clicked '}
                    {selectedRaceState.MinTimestamp && selectedRaceState.MaxTimestamp
                        ? `between ${formatSecondsAsMinutesSeconds(
                              selectedRaceState.MinTimestamp
                          )} and ${formatSecondsAsMinutesSeconds(selectedRaceState.MaxTimestamp)}`
                        : ''}
                    {selectedRaceState.AnswerKeyInvalidReason
                        ? `answer key invalid because ${selectedRaceState.AnswerKeyInvalidReason}`
                        : ''}
                </Typography>
            </Grid>
            {selectedRaceState.AnswerKeyInvalidReason && (
                <Grid item>
                    <Typography variant={'h6'}>
                        Answer key invalid because {selectedRaceState.AnswerKeyInvalidReason}
                    </Typography>
                </Grid>
            )}
        </>
    )
}

const formatSecondsAsMinutesSeconds = (seconds: number) => {
    return new Date(seconds * 1000).toISOString().slice(14, 19)
}

const displayCurrentAnswerRunnerEvent = (race: Race, selectedRunnerEvent: AnswerKeyExpectedRunnerEvent) => {
    return (
        <>
            <Grid item>
                <Typography variant={'h6'}>
                    {race.raceKey()}, Runner {selectedRunnerEvent.ProgramNumber} - {selectedRunnerEvent.Type}{' '}
                    {selectedRunnerEvent.ShouldNotHaveType
                        ? ' should NOT have been clicked'
                        : ' should have been clicked '}
                    {selectedRunnerEvent.MinTimestamp && selectedRunnerEvent.MaxTimestamp
                        ? `between ${formatSecondsAsMinutesSeconds(
                              selectedRunnerEvent.MinTimestamp
                          )} and ${formatSecondsAsMinutesSeconds(selectedRunnerEvent.MaxTimestamp)}`
                        : ''}
                </Typography>
                {selectedRunnerEvent.AnswerKeyInvalidReason && (
                    <Grid item>
                        <Typography variant={'h6'}>
                            Answer key invalid because {selectedRunnerEvent.AnswerKeyInvalidReason}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </>
    )
}

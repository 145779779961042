import { AssessmentDisplayProps } from './AggregatePerformanceTable'
import { Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { SingleActionReviewDetail } from './SingleActionReviewDetail'
import { MistakeSelector } from './MistakeSelector'
import { UnifiedAssessment } from '../../services/tracker_assessment'
import { RootStateType } from '../../store'
import { useSelector } from 'react-redux'

interface MistakeReviewProps extends AssessmentDisplayProps {
    onReviewComplete: () => void
    onApprovedRejected: () => void
}

export const MistakeReview = ({ assessments, onReviewComplete, onApprovedRejected }: MistakeReviewProps) => {
    const [selectedAssessment, setSelectedAssessment] = useState(null as UnifiedAssessment | null)
    return (
        <>
            <Grid container direction={'row'} spacing={2} justifyContent={'flex-start'} className={'quiz-adjudication'}>
                <Grid item>
                    <MistakeSelector
                        assessments={assessments}
                        selectedAssessment={selectedAssessment}
                        setSelectedAssessment={setSelectedAssessment}
                    />
                </Grid>
                {selectedAssessment && (
                    <Grid item>
                        <MistakeUnderReview
                            assessment={selectedAssessment}
                            onReviewComplete={() => {
                                setSelectedAssessment(null)
                                onReviewComplete()
                            }}
                            onApprovedRejected={() => {
                                setSelectedAssessment(null)
                                onApprovedRejected()
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    )
}

interface MistakeUnderReviewProps {
    assessment: UnifiedAssessment
    onReviewComplete: () => void
    onApprovedRejected: () => void
}

export const MistakeUnderReview = ({ assessment, onReviewComplete, onApprovedRejected }: MistakeUnderReviewProps) => {
    const userName = useSelector((state: RootStateType) => state?.meStore?.me?.userName)

    return (
        <Grid
            container
            style={{ width: '100%' }}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={2}
        >
            <Grid item>
                <Typography variant={'h6'}>
                    Action: {assessment.RaceDate} {assessment.TrackCode} {assessment.RaceNumber}{' '}
                    {assessment.ProgramNumber ? `Runner ${assessment.ProgramNumber}` : ''} {assessment.Type}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={'h6'}>Assessment: {assessment.AssessmentText}</Typography>
            </Grid>
            {assessment.Objected && (
                <Grid item>
                    <Typography variant={'h6'}>
                        {userName === assessment.UserName ? 'you' : assessment.UserName} objected with comment:{' '}
                        {assessment.ResponseComment !== '' ? assessment.ResponseComment : 'no comment'}
                    </Typography>
                </Grid>
            )}
            {assessment.Acknowledged && (
                <Grid item>
                    <Typography variant={'h6'}>You acknowledged this assessment</Typography>
                </Grid>
            )}
            {assessment.Approved && (
                <Grid item>
                    <Typography variant={'h6'}>The admin team approved this objection</Typography>
                </Grid>
            )}
            {assessment.Rejected && (
                <Grid item>
                    <Typography variant={'h6'}>
                        The admin team rejected this assessment with comment:{' '}
                        {assessment.RejectionComment !== '' ? assessment.RejectionComment : 'no comment'}
                    </Typography>
                </Grid>
            )}
            <Grid item>
                <SingleActionReviewDetail
                    Assessment={assessment}
                    OnResponded={onReviewComplete}
                    OnApprovedRejected={onApprovedRejected}
                    Height={600}
                    Width={800}
                    AllowApproveReject={false}
                />
            </Grid>
        </Grid>
    )
}

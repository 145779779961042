import {
    GenericHistoricalTracker,
    GenericHistoricalTrackerRaceStateBase,
    GenericHistoricalTrackerStartTracking,
    getHistoricalTrackerNextRace,
    RaceTrio,
} from '../../services/generic_historical_tracker'
import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Race } from '../../models/race'
import getRace from '../../services/get_race'
import { makeStyles } from '@material-ui/core/styles'
import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import { GenericHistoricalTrackerRunnerButtons } from './GenericHistoricalTrackerRunnerButtons'
import { createRaceState } from '../../services/create_race_state'
import { useDispatch, useSelector } from 'react-redux'
import { getErrorData } from '../../services/get_error'
import { RootStateType } from '../../store'
import { useParams } from 'react-router'
import { RaceButtonsLayout } from './RaceLevelButtonsLayout'
import { pollCreatingUserState } from '../../services/create_user_state'
import { createAnswerKey, gradeRace } from '../../services/quizzes'
import { useSnackbar } from 'notistack'
import { TrackerAssessment } from '../../services/tracker_assessment'
import { QuizResultsReview } from './QuizResultsReview'
import { HistoricalTrackerVideoController } from './HistoricalTrackerVideoController'
import { TrackerInfoHeader } from './TrackingModuleInfoHeader'
import { Dispatch } from 'redux'
import {
    defaultButtonsBeside,
    defaultShowJockeySilks,
    defaultSortByDrawNumber,
    defaultShowRunnerNames,
    setDefaultButtonsBeside,
    setDefaultShowJockeySilks,
    setDefaultShowRunnerNames,
    setDefaultSortByDrawNumber,
} from '../generic_real_time_tracker/RaceCard'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { MoreVert } from '@material-ui/icons'
import { GenericHistoricalTrackerHeaderButtons } from './GenericHistoricalTrackerHeaderButtons'
import { hasRaceState, hasRaceStateForHistoricalModule } from '../generic_real_time_tracker/RaceButtons'
import createPersistedState from 'use-persisted-state'
import { MenuSettingWithDefault } from '../common/MenuSettingWithDefault'
import { isAxiosError } from 'axios'
import { RaceStateTypes } from '../../types'

const useStyles = makeStyles((theme) => ({
    CardRoot: {
        padding: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
    activeToggle: {
        marginLeft: 'auto',
    },
    flexOne: {
        flex: 1,
    },
    hidden: {
        display: 'none',
    },
}))

const useRunnerButtonsWidthState = createPersistedState<number>('hist-runner-buttons-width')

export const GenericHistoricalTrackerComponentFromParams = () => {
    const { trackerName }: { trackerName: string } = useParams()
    const tracker = useSelector((state: RootStateType) =>
        state?.historicalTrackersStore?.historicalTrackers?.find((t) => t.Name.split(' ').join('_') === trackerName)
    )
    if (!tracker) {
        return (
            <Grid container direction={'column'} spacing={2} justifyContent={'flex-start'} alignItems={'center'}>
                <Grid item>
                    <Typography variant={'h4'}>Tracker Not Found</Typography>
                </Grid>
            </Grid>
        )
    }
    return <GenericHistoricalTrackerComponent tracker={tracker} isEmbeddedTracker={false} isQuizPracticeMode={false} />
}

export const GenericHistoricalTrackerComponent = ({
    tracker,
    isEmbeddedTracker,
    isQuizPracticeMode,
}: {
    tracker: GenericHistoricalTracker
    isEmbeddedTracker: boolean
    isQuizPracticeMode: boolean
}) => {
    const trackerRaceStatePrefix = GenericHistoricalTrackerRaceStateBase(tracker)
    const [isActive, setIsActive] = useState(false)
    const [videoSource, setVideoSource] = useState(tracker.VideoSources[0].Source)
    const [shouldFetchNewRace, setShouldFetchNewRace] = useState(true)
    const [noNextRace, setNoNextRace] = useState(false)
    const [fullRace, setFullRace] = useState(null as Race | null)
    const [videoTimestamp, setVideoTimestamp] = useState(null as number | null)
    const [startedTracking, setStartedTracking] = useState(false)
    const [isLoadingNewRace, setIsLoadingNewRace] = useState(false)
    const [isQuizCreationMode, setIsQuizCreationMode] = useState(false)
    const [providedRaceTrio, setProvidedRaceTrio] = useState(null as RaceTrio | null)
    const dispatch = useDispatch()
    const { latency, isAdmin } = useSelector((rootState: RootStateType) => ({
        latency: rootState?.latencyStore?.latencyInSeconds ?? 0,
        isAdmin: rootState?.meStore?.me?.team === 'admin',
    }))
    const [trackerName, setTrackerName] = useState(tracker.Name)
    const [quizAssessment, setQuizAssessment] = useState(null as TrackerAssessment | null)
    const [showJockeySilks, setShowJockeySilks] = useState(true)
    const [buttonsBeside, setButtonsBeside] = useState(true)
    const [runnerButtonsWidth, setRunnerButtonsWidth] = useRunnerButtonsWidthState(8)
    const [sortByDrawNumber, setSortByDrawNumber] = useState(true)
    const [runnerNumberFilter, setRunnerNumberFilter] = useState<string[]>([])
    const [runnerButtonFilter, setRunnerButtonFilter] = useState<string[]>([])
    const [showRunnerNames, setShowRunnerNames] = useState(false)
    useEffect(() => {
        if (isActive) {
            const timerMilliseconds = 10000
            const embeddedSuffix = isEmbeddedTracker ? '_EMBEDDED' : ''
            const userStateTimer = pollCreatingUserState(
                `ACTIVE_${trackerRaceStatePrefix}${embeddedSuffix}`,
                dispatch,
                timerMilliseconds
            )
            return () => {
                clearInterval(userStateTimer)
            }
        }
        return () => {}
    }, [isActive, trackerRaceStatePrefix, isEmbeddedTracker, dispatch])

    useEffect(() => {
        if (trackerName !== tracker.Name) {
            setTrackerName(tracker.Name)
            setFullRace(null)
            setVideoTimestamp(null)
            setStartedTracking(false)
            setNoNextRace(false)
            setShouldFetchNewRace(true)
            setIsQuizCreationMode(false)
            setVideoSource(tracker.VideoSources[0].Source)
        }
        if (shouldFetchNewRace || providedRaceTrio) {
            fetchNextRace(
                setStartedTracking,
                setShouldFetchNewRace,
                setIsLoadingNewRace,
                setFullRace,
                tracker,
                isQuizCreationMode,
                isQuizPracticeMode,
                dispatch,
                setNoNextRace,
                providedRaceTrio,
                setProvidedRaceTrio,
                setShowJockeySilks,
                setSortByDrawNumber,
                setShowRunnerNames,
                setButtonsBeside
            )
        }
    }, [
        tracker,
        dispatch,
        shouldFetchNewRace,
        trackerName,
        tracker.Name,
        tracker.VideoStartOffsetSeconds,
        tracker.PracticeMode,
        isQuizPracticeMode,
        tracker.UseActualPostTimeAsPublicTimeMarker,
        tracker.VideoStartPublicTimeMarker,
        latency,
        trackerRaceStatePrefix,
        tracker.ID,
        isQuizCreationMode,
        providedRaceTrio,
    ])

    useEffect(() => {
        if (isQuizCreationMode) {
            setShouldFetchNewRace(true)
        }
    }, [isQuizCreationMode])

    const classes = useStyles()

    const { enqueueSnackbar } = useSnackbar()

    const handleRunnerNumberFilterChange = (event: any) => {
        setRunnerNumberFilter(event.target.value)
    }

    const handleRunnerButtonFilterChange = (event: any) => {
        setRunnerButtonFilter(event.target.value)
    }

    const updateRace = useCallback(async () => {
        if (!fullRace) {
            return
        }
        const updatedRace = await getRace(
            fullRace.raceDate,
            fullRace.trackCode,
            fullRace.raceNumber,
            false,
            true,
            null,
            tracker.ID
        )
        setFullRace(updatedRace)

        if (hasRaceState(updatedRace.raceStates, GenericHistoricalTrackerStartTracking(tracker))) {
            setStartedTracking(true)
        }

        if (
            tracker.VideoSources.length === 1 &&
            hasRaceState(updatedRace.raceStates, updatedRace.videoReplayIssuesForSource(videoSource))
        ) {
            setShouldFetchNewRace(true)
        }

        if (hasRaceStateForHistoricalModule(updatedRace.raceStates, RaceStateTypes.SKIP_RACE, tracker.ID)) {
            setShouldFetchNewRace(true)
        }
    }, [fullRace, tracker, videoSource])

    const onTrackingComplete = useCallback(async () => {
        if (!fullRace) {
            return
        }
        if (isQuizCreationMode) {
            const { success, failureText } = await createAnswerKey(tracker.ID, fullRace.id)
            if (success) {
                enqueueSnackbar('Successfully created quiz', {
                    variant: 'success',
                })
            } else {
                enqueueSnackbar(`Failed to create quiz ${failureText}`, {
                    variant: 'warning',
                })
            }
        } else {
            try {
                const assessment = await gradeRace(tracker.ID, fullRace.id)
                setQuizAssessment(assessment)
            } catch (e) {
                if (
                    !isAxiosError(e) ||
                    e.response?.status !== 404 ||
                    e.response?.data !== 'race does not have answer key'
                ) {
                    getErrorData(e, dispatch)
                }
            }
        }
        setShouldFetchNewRace(true)
    }, [isQuizCreationMode, fullRace, tracker, enqueueSnackbar, dispatch])
    return (
        <>
            <Card>
                <Grid
                    container
                    direction={'column'}
                    spacing={1}
                    className={classes.CardRoot}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <TrackerInfoHeader
                        tracker={tracker}
                        practiceMode={tracker.PracticeMode || isQuizPracticeMode}
                        isActive={isActive}
                        onIsActiveUpdated={setIsActive}
                        isQuizCreationMode={isQuizCreationMode}
                        onIsQuizCreationModeUpdated={(newIsQuizCreationMode: boolean) =>
                            setIsQuizCreationMode(newIsQuizCreationMode)
                        }
                        isQuizPracticeMode={isQuizPracticeMode}
                    />
                    {noNextRace && !quizAssessment && (
                        <Grid item>
                            <Typography color={'secondary'} variant="h5">
                                No more {isQuizPracticeMode ? 'quiz ' : ''} races
                            </Typography>
                        </Grid>
                    )}
                    {!noNextRace && (
                        <Grid item className={isLoadingNewRace ? '' : classes.hidden}>
                            <CircularProgress />
                        </Grid>
                    )}
                    {isActive && fullRace && (
                        <>
                            <Grid item className={isLoadingNewRace || quizAssessment ? classes.hidden : ''}>
                                <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                                    <Grid item>
                                        <Typography variant={'h6'}>
                                            {fullRace.raceDate} {fullRace.longTrackName} {fullRace.raceNumber} (
                                            {fullRace.raceType}){' '}
                                            {fullRace.raceSubType ? `(${fullRace.raceSubType})` : ''}{' '}
                                            {fullRace.startType ? `(${fullRace.startType})` : ''}
                                        </Typography>
                                    </Grid>
                                    {isAdmin && <ChooseARace updateRaceTrio={setProvidedRaceTrio} />}
                                    <Grid item>
                                        <PopupState variant="popover" popupId="demo-popup-menu">
                                            {(popupState) => (
                                                <React.Fragment>
                                                    <IconButton {...bindTrigger(popupState)}>
                                                        <MoreVert />
                                                    </IconButton>
                                                    <Menu {...bindMenu(popupState)}>
                                                        {tracker && (
                                                            <>
                                                                <MenuItem>
                                                                    <InputLabel>Runner Buttons</InputLabel>
                                                                    <Select
                                                                        style={{ marginLeft: '10px' }}
                                                                        multiple
                                                                        value={runnerButtonFilter}
                                                                        onChange={handleRunnerButtonFilterChange}
                                                                    >
                                                                        {tracker.GenericHistoricalTrackerRunnerEvents.map(
                                                                            (button) => (
                                                                                <MenuItem
                                                                                    value={button.ShortTextButton}
                                                                                >
                                                                                    {button.ShortTextButton}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                        {tracker.GenericHistoricalTrackerRunnerEventGroups.map(
                                                                            (group) => (
                                                                                <MenuItem value={group.Name}>
                                                                                    {group.Name}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        setRunnerButtonFilter([])
                                                                        popupState.close()
                                                                    }}
                                                                >
                                                                    Clear Runner Button Filter
                                                                </MenuItem>
                                                            </>
                                                        )}
                                                        {fullRace && (
                                                            <MenuItem>
                                                                <InputLabel>Runner Numbers</InputLabel>
                                                                <Select
                                                                    style={{ marginLeft: '10px' }}
                                                                    multiple
                                                                    value={runnerNumberFilter}
                                                                    onChange={handleRunnerNumberFilterChange}
                                                                >
                                                                    {fullRace.runners.map((runner) => (
                                                                        <MenuItem value={runner.programNumber}>
                                                                            {runner.programNumber}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </MenuItem>
                                                        )}
                                                        {fullRace && (
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setRunnerNumberFilter([])
                                                                    popupState.close()
                                                                }}
                                                            >
                                                                Clear Runner Number Filter
                                                            </MenuItem>
                                                        )}
                                                        <MenuSettingWithDefault
                                                            toEnableText="Show Jockey Silks"
                                                            toDisableText="Hide Jockey Silks"
                                                            isEnabled={showJockeySilks}
                                                            setState={setShowJockeySilks}
                                                            defaultQualifier={`${fullRace?.raceType} Races`}
                                                            setDefaultState={
                                                                fullRace
                                                                    ? (value) =>
                                                                          setDefaultShowJockeySilks(
                                                                              fullRace?.country,
                                                                              fullRace?.raceType,
                                                                              value
                                                                          )
                                                                    : undefined
                                                            }
                                                            closePopup={popupState.close}
                                                        />
                                                        <MenuSettingWithDefault
                                                            toEnableText="Sort By Draw Number"
                                                            toDisableText="Sort By Runner Number"
                                                            isEnabled={sortByDrawNumber}
                                                            setState={setSortByDrawNumber}
                                                            defaultQualifier={`${fullRace?.raceType} Races`}
                                                            setDefaultState={
                                                                fullRace
                                                                    ? (value) =>
                                                                          setDefaultSortByDrawNumber(
                                                                              fullRace?.country,
                                                                              fullRace?.raceType,
                                                                              value
                                                                          )
                                                                    : undefined
                                                            }
                                                            closePopup={popupState.close}
                                                        />
                                                        <MenuSettingWithDefault
                                                            toEnableText="Show Runner Names"
                                                            toDisableText="Hide Runner Names"
                                                            isEnabled={showRunnerNames}
                                                            setState={setShowRunnerNames}
                                                            defaultQualifier={`${fullRace?.raceType} Races`}
                                                            setDefaultState={
                                                                fullRace
                                                                    ? (value) =>
                                                                          setDefaultShowRunnerNames(
                                                                              fullRace?.country,
                                                                              fullRace?.raceType,
                                                                              value
                                                                          )
                                                                    : undefined
                                                            }
                                                            closePopup={popupState.close}
                                                        />
                                                        <MenuSettingWithDefault
                                                            toEnableText="Move buttons to BESIDE (left of) video"
                                                            toDisableText="Move buttons to BELOW video"
                                                            isEnabled={buttonsBeside}
                                                            setState={setButtonsBeside}
                                                            defaultQualifier={`${fullRace?.raceType} Races in ${tracker.Name}`}
                                                            setDefaultState={
                                                                fullRace
                                                                    ? (value) =>
                                                                          setDefaultButtonsBeside(
                                                                              tracker.Name,
                                                                              fullRace?.country,
                                                                              fullRace?.raceType,
                                                                              value
                                                                          )
                                                                    : undefined
                                                            }
                                                            closePopup={popupState.close}
                                                        />
                                                        {fullRace && (
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setRunnerButtonsWidth(runnerButtonsWidth + 1)
                                                                    popupState.close()
                                                                }}
                                                            >
                                                                Increase runner buttons width
                                                            </MenuItem>
                                                        )}
                                                        {fullRace && (
                                                            <MenuItem
                                                                onClick={() => {
                                                                    setRunnerButtonsWidth(runnerButtonsWidth - 1)
                                                                    popupState.close()
                                                                }}
                                                            >
                                                                Decrease runner buttons width
                                                            </MenuItem>
                                                        )}
                                                        {tracker.VideoSources.length > 1 &&
                                                            tracker.VideoSources.map((s) => {
                                                                return (
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            setVideoSource(s.Source)
                                                                            popupState.close()
                                                                        }}
                                                                    >
                                                                        Use {s.Source}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                    </Menu>
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={isLoadingNewRace || quizAssessment ? classes.hidden : ''}>
                                <Typography variant={'body1'}>{fullRace.numberOfHorses} Horses</Typography>
                            </Grid>
                            <Grid item>
                                <GenericHistoricalTrackerHeaderButtons
                                    race={fullRace}
                                    tracker={tracker}
                                    videoTimestamp={videoTimestamp}
                                    videoSource={videoSource}
                                    onUpdateRace={updateRace}
                                />
                            </Grid>
                            <Grid item className={isLoadingNewRace || quizAssessment ? classes.hidden : ''}>
                                <Grid container direction={'row'} spacing={1}>
                                    {buttonsBeside && (
                                        <Grid item>
                                            <Grid container direction={'column'} spacing={1}>
                                                <Grid item>
                                                    <RaceButtonsLayout
                                                        fullRace={fullRace}
                                                        videoTimestamp={videoTimestamp}
                                                        videoSource={videoSource}
                                                        tracker={tracker}
                                                        onTrackingCompleted={onTrackingComplete}
                                                        updateRace={updateRace}
                                                        startedTracking={startedTracking}
                                                    />
                                                </Grid>
                                                {startedTracking && (videoTimestamp !== null || videoSource !== null) && (
                                                    <Grid item>
                                                        <Grid
                                                            item
                                                            data-testid={'generic-tracker-runner-buttons'}
                                                            className={'generic-tracker-runner-buttons'}
                                                        >
                                                            <GenericHistoricalTrackerRunnerButtons
                                                                Tracker={tracker}
                                                                Race={fullRace}
                                                                VideoTimestamp={videoTimestamp}
                                                                VideoSource={videoSource}
                                                                ShowJockeySilks={showJockeySilks}
                                                                UpdateRace={updateRace}
                                                                RunnerButtonsWidth={runnerButtonsWidth}
                                                                SortByDrawNumber={sortByDrawNumber}
                                                                ShowRunnerNames={showRunnerNames}
                                                                RunnerNumberFilter={runnerNumberFilter}
                                                                RunnerButtonFilter={runnerButtonFilter}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                                    <HistoricalTrackerVideoController
                                        race={fullRace}
                                        videoSource={videoSource}
                                        onProgress={setVideoTimestamp}
                                        onVideoNotFound={async () => {
                                            await createRaceState(
                                                {
                                                    raceID: fullRace.id,
                                                    type: fullRace.videoReplayIssuesForSource(videoSource),
                                                    latencyInSeconds: latency,
                                                    practiceMode: tracker.PracticeMode || isQuizPracticeMode,
                                                    videoTimestamp: null,
                                                    videoSource: videoSource,
                                                    genericHistoricalTrackerID: tracker.ID,
                                                    genericRealTimeTrackerID: null,
                                                },
                                                dispatch
                                            )
                                            if (tracker.VideoSources.length === 1) {
                                                setShouldFetchNewRace(true)
                                            } else {
                                                const newSource = tracker.VideoSources.filter(
                                                    (s) => s.Source !== videoSource
                                                )[0].Source
                                                setVideoSource(newSource)
                                            }
                                        }}
                                        tracker={tracker}
                                        onPublicTimeMarkerNotFound={async () => {
                                            const missingTimeMarkerRaceState =
                                                trackerRaceStatePrefix + '_MISSING_PUBLIC_TIME_MARKER'
                                            await createRaceState(
                                                {
                                                    raceID: fullRace.id,
                                                    type: missingTimeMarkerRaceState,
                                                    latencyInSeconds: latency,
                                                    practiceMode: tracker.PracticeMode || isQuizPracticeMode,
                                                    videoTimestamp: null,
                                                    videoSource: videoSource,
                                                    genericHistoricalTrackerID: tracker.ID,
                                                    genericRealTimeTrackerID: null,
                                                },
                                                dispatch
                                            )
                                            setShouldFetchNewRace(true)
                                        }}
                                        allowVideoControls={tracker.AllowVideoControls}
                                        startedTracking={startedTracking}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item className={isLoadingNewRace || quizAssessment ? classes.hidden : ''}>
                                {!buttonsBeside && (
                                    <Grid
                                        container
                                        direction={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        spacing={1}
                                    >
                                        <Grid item>
                                            <RaceButtonsLayout
                                                fullRace={fullRace}
                                                videoTimestamp={videoTimestamp}
                                                videoSource={videoSource}
                                                tracker={tracker}
                                                onTrackingCompleted={onTrackingComplete}
                                                updateRace={updateRace}
                                                startedTracking={startedTracking}
                                            />
                                        </Grid>
                                        {startedTracking && (videoTimestamp !== null || videoSource !== null) && (
                                            <Grid
                                                item
                                                data-testid={'generic-tracker-runner-buttons'}
                                                className={'generic-tracker-runner-buttons'}
                                            >
                                                <GenericHistoricalTrackerRunnerButtons
                                                    Tracker={tracker}
                                                    Race={fullRace}
                                                    VideoTimestamp={videoTimestamp}
                                                    VideoSource={videoSource}
                                                    ShowJockeySilks={showJockeySilks}
                                                    UpdateRace={updateRace}
                                                    RunnerButtonsWidth={runnerButtonsWidth}
                                                    SortByDrawNumber={sortByDrawNumber}
                                                    ShowRunnerNames={showRunnerNames}
                                                    RunnerNumberFilter={runnerNumberFilter}
                                                    RunnerButtonFilter={runnerButtonFilter}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                    {quizAssessment && (
                        <Grid item>
                            <QuizResultsReview
                                QuizResults={quizAssessment}
                                OnQuizReviewed={() => setQuizAssessment(null)}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card>
        </>
    )
}

const fetchNextRace = async (
    setStartedTracking: (b: boolean) => void,
    setShouldFetchNewRace: (b: boolean) => void,
    setIsLoadingNewRace: (b: boolean) => void,
    setFullRace: (r: Race) => void,
    tracker: GenericHistoricalTracker,
    isQuizCreationMode: boolean,
    isQuizPracticeMode: boolean,
    dispatch: Dispatch<any>,
    setNoNextRace: (b: boolean) => void,
    providedRaceTrio: RaceTrio | null,
    setProvidedRaceTrio: (r: RaceTrio | null) => void,
    setShowJockeySilks: (b: boolean) => void,
    setSortByDrawNumber: (b: boolean) => void,
    setShowRunnerNames: (b: boolean) => void,
    setButtonsBeside: (b: boolean) => void
) => {
    setStartedTracking(false)
    setShouldFetchNewRace(false)
    setIsLoadingNewRace(true)
    let nextRaceTrio: RaceTrio
    if (providedRaceTrio) {
        nextRaceTrio = providedRaceTrio
    } else {
        try {
            nextRaceTrio = await getHistoricalTrackerNextRace(tracker.Name, isQuizCreationMode, isQuizPracticeMode)
        } catch (err) {
            getErrorData(err, dispatch)
            setNoNextRace(true)
            return
        }
    }

    let nextFullRace: Race
    try {
        nextFullRace = await getRace(
            nextRaceTrio.RaceDate,
            nextRaceTrio.TrackCode,
            nextRaceTrio.RaceNumber,
            false,
            true,
            null,
            tracker.ID
        )
        const filteredRace = filterToOnlyActionsForHistoricalTrackingModule(nextFullRace, tracker.ID)
        setShowJockeySilks(defaultShowJockeySilks(filteredRace.country, filteredRace.raceType))
        setSortByDrawNumber(defaultSortByDrawNumber(filteredRace.country, filteredRace.raceType))
        setShowRunnerNames(defaultShowRunnerNames(filteredRace.country, filteredRace.raceType))
        setButtonsBeside(defaultButtonsBeside(tracker.Name, filteredRace.country, filteredRace.raceType))
        setFullRace(filteredRace)
    } catch (err) {
        getErrorData(err, dispatch)
        setNoNextRace(true)
        setProvidedRaceTrio(null)
        return
    }
    setProvidedRaceTrio(null)
    setIsLoadingNewRace(false)
}

const ChooseARace = ({ updateRaceTrio }: { updateRaceTrio: (r: RaceTrio) => void }) => {
    const [raceTrioText, updateRaceTrioText] = useState('')
    const [isAdding, updateIsAdding] = useState(false)
    const validRegex = /([0-9]{6}) ([a-zA-Z_]*) ([0-9]{1,2})/
    return (
        <>
            <Grid item>
                <Button variant={'text'} onClick={() => updateIsAdding(true)}>
                    (Or Choose A Race)
                </Button>
            </Grid>
            <Dialog open={isAdding} onClose={() => updateIsAdding(false)}>
                <DialogTitle id="customized-dialog-title">Select a race</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        error={raceTrioText !== '' && !validRegex.test(raceTrioText)}
                        helperText={'should match 210805 WOX 5'}
                        value={raceTrioText}
                        variant={'standard'}
                        label={'provide a race trio'}
                        defaultValue={'210805 WOX 5'}
                        onChange={(event) => updateRaceTrioText(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        size={'medium'}
                        disabled={!validRegex.test(raceTrioText)}
                        onClick={() => {
                            const match = raceTrioText.match(validRegex)
                            if (!match) {
                                return
                            }
                            const [, raceDate, trackCode, raceNumber] = match
                            updateRaceTrio({
                                RaceDate: raceDate,
                                TrackCode: trackCode,
                                RaceNumber: parseInt(raceNumber),
                            })
                            updateIsAdding(false)
                        }}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const filterToOnlyActionsForHistoricalTrackingModule = (race: Race, genericHistoricalTrackerID: number) => {
    const filteredRaceStates = race.raceStates.filter(
        (raceState) => raceState.genericHistoricalTrackerID === genericHistoricalTrackerID
    )
    const runnersWithFilteredEvents = race.runners.map((runner) => ({
        ...runner,
        runnerEvents: runner.runnerEvents.filter(
            (runnerEvent) => runnerEvent.genericHistoricalTrackerID === genericHistoricalTrackerID
        ),
    }))
    return {
        ...race,
        raceStates: filteredRaceStates,
        runners: runnersWithFilteredEvents,
    }
}

import axios from 'axios'
import { IRunner } from '../types'
import { getErrorData } from './get_error'
import { userService } from './login'
import { v4 as uuidv4 } from 'uuid'
import { Dispatch } from 'react'

const url = '/api/v2/runner_events'

export interface RunnerEventCreationConfig {
    runner: IRunner
    eventType: string
    videoTimestamp: number | null
    raceID: number
    practiceMode: boolean
    videoSource: string
    genericHistoricalTrackerID?: number | null
    genericRealTimeTrackerID?: number | null
}

export function addRunnerEvent(config: RunnerEventCreationConfig, dispatch: Dispatch<any>) {
    const uuidString = uuidv4().toString()

    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(
        {
            type: 'runnerStateEvent',
            raceStateType: config.eventType,
            raceID: config.raceID,
            uuid: uuidString,
        },
        '*'
    )

    const request = axios
        .post(
            url,
            {
                runner_id: config.runner.id,
                type: config.eventType,
                video_timestamp: config.videoTimestamp,
                video_source: config.videoSource,
                s3_uuid: uuidString,
                practice_mode: config.practiceMode,
                generic_historical_tracker_id: config.genericHistoricalTrackerID,
                generic_real_time_tracker_id: config.genericRealTimeTrackerID,
            },
            {
                headers: userService.authHeader(),
                timeout: 1000 * 30,
            }
        )
        .catch((error) => {
            getErrorData(error, dispatch)
        })

    return request
}

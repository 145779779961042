import React from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { RootStateType } from '../../store'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { GenericHistoricalTrackerComponent } from '../generic_historical_tracker/GenericHistoricalTracker'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    header: {
        width: '100%',
        marginRight: 'auto',
    },
}))

export const Quizzes = () => {
    const { trackerName }: { trackerName: string } = useParams()
    const historicalTracker = useSelector((state: RootStateType) =>
        state?.historicalTrackersStore?.historicalTrackers?.find((t) => t.Name.split(' ').join('_') === trackerName)
    )
    const classes = useStyles()
    if (!historicalTracker) {
        return (
            <Grid container spacing={4} direction={'column'}>
                <Grid item>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid item className={'quizzes-center-header'}>
                            <Typography variant={'h2'}> {trackerName} has no quizzes </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return (
        <Paper className={classes.cardRoot}>
            <GenericHistoricalTrackerComponent
                tracker={historicalTracker}
                isQuizPracticeMode={true}
                isEmbeddedTracker={false}
            />
        </Paper>
    )
}

import { Grid } from '@material-ui/core'
import * as React from 'react'
import { SyntheticEvent, useRef } from 'react'
import { ScrubLocation } from './VideoControls'
import { HistoricalVideoPlayer } from './HistoricalVideoPlayer'
import { RealTimeVideoPlayer } from './RealTimeVideoPlayer'
import { PictureSnapshot } from './PictureSnapshot'

interface VideoProps {
    url: string
    format: string
    pictureURL?: string
    height: number
    width: number
    historical: boolean
    disableControls: boolean
    onProgress?(event: SyntheticEvent<HTMLVideoElement>): void
    idForIFrame?: string
    scrubLocations?: ScrubLocation[]
    initialPlaybackRate?: string
    autoplay?: boolean
    play?: boolean
}

export const VideoPlayerWithPictureSnapshot = ({
    url,
    format,
    pictureURL,
    height,
    width,
    historical,
    disableControls,
    onProgress,
    idForIFrame,
    scrubLocations,
    initialPlaybackRate,
    autoplay,
    play,
}: VideoProps) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    return (
        <Grid item container direction={'row'} spacing={1}>
            {historical && (
                <HistoricalVideoPlayer
                    url={url}
                    format={format}
                    height={height}
                    width={width}
                    idForIFrame={idForIFrame}
                    disableControls={disableControls}
                    videoRef={videoRef}
                    autoplay={autoplay}
                    onProgress={onProgress}
                    scrubLocations={scrubLocations}
                    initialPlaybackRate={initialPlaybackRate}
                    play={play}
                />
            )}
            {!historical && (
                <RealTimeVideoPlayer
                    url={url}
                    format={format}
                    height={height}
                    width={width}
                    videoRef={videoRef}
                    idForIFrame={idForIFrame}
                />
            )}
            {pictureURL && pictureURL !== '' && (
                <PictureSnapshot pictureURL={pictureURL} height={height} width={width} />
            )}
        </Grid>
    )
}

import * as React from 'react'
import { ActionReview, ActionReviewAction } from '../../services/action_review'
import { useState } from 'react'
import { getErrorData } from '../../services/get_error'
import { useDispatch } from 'react-redux'
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createGenericActionComment } from '../../services/generic_action_review'
import { Dispatch } from 'redux'
import { ActionReviewLearningCorrectCommentReview } from './ActionReviewLearningCorrectCommentReview'

const useStyles = makeStyles((theme) => ({
    commentBox: {
        width: theme.spacing(50),
    },
}))

export const ActionReviewButtons = ({
    action,
    actionReview,
    onActionReviewed,
}: {
    action: ActionReviewAction
    actionReview: ActionReview
    onActionReviewed: () => void
}) => {
    const [commentType, setCommentType] = useState('')
    const [comment, setComment] = useState('')
    const [reviewingCorrectAnswer, setReviewingCorrectAnswer] = useState(false)
    const classes = useStyles()
    const dispatch = useDispatch()

    const selectedButton = actionReview.Buttons.find((button) => button.CommentType === commentType)
    return (
        <>
            <Grid item>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Please choose an assessment for this action: </FormLabel>
                    <RadioGroup
                        aria-label="action-assessment"
                        name="controlled-radio-buttons-group"
                        value={commentType}
                        onChange={(event) => setCommentType(event.target.value)}
                    >
                        {actionReview.Buttons.sort((a, b) => a.Ordering - b.Ordering).map((button) => {
                            return (
                                <FormControlLabel
                                    key={button.CommentType}
                                    value={button.CommentType}
                                    control={<Radio />}
                                    label={button.CommentTypeButtonText}
                                />
                            )
                        })}
                    </RadioGroup>
                </FormControl>
            </Grid>
            {selectedButton?.AllowComment && (
                <Grid item>
                    <TextField
                        multiline
                        onChange={(e) => setComment(e.target.value)}
                        id={'comment-box'}
                        value={comment}
                        variant={'outlined'}
                        label={selectedButton.CommentPrompt}
                        size={'medium'}
                        className={classes.commentBox}
                        rows={4}
                    />
                </Grid>
            )}
            {!reviewingCorrectAnswer && (
                <Grid item>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        size={'medium'}
                        disabled={commentType === ''}
                        onClick={onClickSubmit(
                            action,
                            commentType,
                            comment,
                            dispatch,
                            actionReview,
                            setReviewingCorrectAnswer,
                            onActionReviewed,
                            setCommentType,
                            setComment
                        )}
                    >
                        Submit
                    </Button>
                </Grid>
            )}
            {reviewingCorrectAnswer && (
                <ActionReviewLearningCorrectCommentReview
                    commentType={commentType}
                    correctCommentType={action.CorrectCommentType}
                    correctComment={action.CorrectComment}
                    onCorrectAnswerReviewed={async () => {
                        onActionReviewed()
                        setCommentType('')
                        setComment('')
                    }}
                />
            )}
        </>
    )
}

function onClickSubmit(
    action: ActionReviewAction,
    commentType: string,
    comment: string,
    dispatch: Dispatch<any>,
    actionReview: ActionReview,
    setReviewingCorrectAnswer: (value: ((prevState: boolean) => boolean) | boolean) => void,
    onActionReviewed: () => void,
    setCommentType: (value: ((prevState: string) => string) | string) => void,
    setComment: (value: ((prevState: string) => string) | string) => void
) {
    return async () => {
        try {
            const success = await createGenericActionComment(
                '',
                action.RunnerEventID,
                action.RaceStateID,
                commentType,
                comment,
                [],
                [],
                false
            )
            if (!success) {
                getErrorData(new Error('did not successfully submit comment'), dispatch)
            }
            if (actionReview.IsLearningTool && action.CorrectCommentType !== '') {
                setReviewingCorrectAnswer(true)
            } else {
                onActionReviewed()
                setCommentType('')
                setComment('')
            }
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }
}

import axios from 'axios'
import { userService } from './login'

export interface RunnerEventVideoJson {
    id: number
    user_id: number
    runner_id: number
    type: string
    created_at: Date
    video_timestamp: number
    video_source: string
    uuid: string
    picture_url: string
    video_url: string
}

export interface RunnerEventVideo {
    id: number
    userId: number
    runnerId: number
    type: string
    createdAt: Date
    videoTimestamp: number
    videoSource: string
    uuid: number
    pictureUrl: string
    videoUrl: string
}

export const getRunnerEventVideo = async (raceId: number, runnerEventId: number) => {
    const url = `/api/v2/runner_event?race_id=${raceId}&runner_event_id=${runnerEventId}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })

    return {
        id: response.data.id,
        userId: response.data.user_id,
        runnerId: response.data.runner_id,
        type: response.data.type,
        createdAt: new Date(response.data.created_at),
        videoTimestamp: response.data.video_timestamp,
        videoSource: response.data.video_source,
        uuid: response.data.uuid,
        pictureUrl: response.data.picture_url,
        videoUrl: response.data.video_url,
    } as RunnerEventVideo
}

import { createAction, handleAction } from 'redux-actions'
import { ActionReview } from '../services/action_review'

export const getActionReviews = createAction('GET_ACTION_REVIEWS')

export const getActionReviewsReducer = handleAction(
    getActionReviews,
    (state, action) => ({
        ...state,
        actionReviews: action.payload as ActionReview[] | null,
    }),
    { actionReviews: null } as {
        actionReviews: ActionReview[] | null
    }
)

import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export const PerformanceHeading = () => {
    return (
        <Grid container direction={'row'} justifyContent={'center'}>
            <Grid item>
                <Typography variant={'h2'}>My Performance</Typography>
            </Grid>
        </Grid>
    )
}

import React from 'react'
import { Race } from '../../models/race'
import { Button, Grid } from '@material-ui/core'
import {
    GenericHistoricalTracker,
    GenericHistoricalTrackerRaceState,
    GenericHistoricalTrackerRaceRecall,
} from '../../services/generic_historical_tracker'
import { createRaceState } from '../../services/create_race_state'
import { ButtonWithConfirmation } from '../generic_real_time_tracker/RaceCard'
import { useDispatch } from 'react-redux'
import { getErrorData } from '../../services/get_error'
import { RaceStateTypes } from '../../types'

export interface GenericHistoricalTrackerHeaderButtonsProps {
    tracker: GenericHistoricalTracker
    race: Race
    videoTimestamp: number | null
    videoSource: string
    onUpdateRace: () => void
}

export const GenericHistoricalTrackerHeaderButtons = ({
    tracker,
    race,
    videoTimestamp,
    videoSource,
    onUpdateRace,
}: GenericHistoricalTrackerHeaderButtonsProps) => {
    const dispatch = useDispatch()
    const extraHeaderButtons = tracker.GenericHistoricalTrackerRaceStateGroups.filter(
        (rsg) =>
            (rsg.RaceType === null || rsg.RaceType === race.raceType) &&
            (rsg.SubRaceType === null || rsg.SubRaceType === race.raceSubType) &&
            (rsg.StartType === null || rsg.StartType === race.startType) &&
            rsg.ButtonColumn === 0
    ).sort((a, b) => (a.ButtonColumnOrder ?? 0) - (b.ButtonColumnOrder ?? 0))
    return (
        <>
            <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                {extraHeaderButtons &&
                    extraHeaderButtons.length > 0 &&
                    extraHeaderButtons.map((b) => {
                        if (b.GenericHistoricalTrackerRaceStates.length !== 1) {
                            getErrorData(
                                new Error(
                                    `more than one button in header button group ${b.Name} (not supported) - reach out to support`
                                ),
                                dispatch
                            )
                            return null
                        }
                        const button = b.GenericHistoricalTrackerRaceStates[0]
                        return (
                            <Grid item key={b.Name}>
                                {button.RequireConfirmation && (
                                    <ButtonWithConfirmation
                                        buttonText={button.ButtonText}
                                        buttonColor={button.IsSecondaryButton ? 'secondary' : 'primary'}
                                        onConfirmed={async () => {
                                            const emitRaceStates = async (
                                                buttonToSubmit: GenericHistoricalTrackerRaceState
                                            ) => {
                                                await createRaceState(
                                                    {
                                                        raceID: race.id,
                                                        type: buttonToSubmit.RaceState,
                                                        latencyInSeconds: 0,
                                                        practiceMode: tracker.PracticeMode,
                                                        videoTimestamp: videoTimestamp,
                                                        videoSource: videoSource,
                                                        genericRealTimeTrackerID: null,
                                                        genericHistoricalTrackerID: tracker.ID,
                                                    },
                                                    dispatch
                                                )
                                                onUpdateRace()
                                            }
                                            emitRaceStates(button)
                                        }}
                                    />
                                )}
                                {!button.RequireConfirmation && (
                                    <Button
                                        color={button.IsSecondaryButton ? 'secondary' : 'primary'}
                                        variant="contained"
                                        size={'small'}
                                        onClick={() => {
                                            const emitRaceStates = async (
                                                buttonToSubmit: GenericHistoricalTrackerRaceState
                                            ) => {
                                                await createRaceState(
                                                    {
                                                        raceID: race.id,
                                                        type: buttonToSubmit.RaceState,
                                                        latencyInSeconds: 0,
                                                        practiceMode: tracker.PracticeMode,
                                                        videoTimestamp: videoTimestamp,
                                                        videoSource: videoSource,
                                                        genericRealTimeTrackerID: null,
                                                        genericHistoricalTrackerID: tracker.ID,
                                                    },
                                                    dispatch
                                                )
                                                onUpdateRace()
                                            }
                                            emitRaceStates(button)
                                        }}
                                    >
                                        {button.ButtonText}
                                    </Button>
                                )}
                            </Grid>
                        )
                    })}
                <Grid item>
                    <ButtonWithConfirmation
                        buttonText={'Video Issues'}
                        buttonColor={'secondary'}
                        onConfirmed={async () => {
                            await createRaceState(
                                {
                                    raceID: race.id,
                                    type: race.videoReplayIssuesForSource(videoSource),
                                    latencyInSeconds: 0,
                                    practiceMode: tracker.PracticeMode,
                                    videoTimestamp: videoTimestamp,
                                    videoSource: videoSource,
                                    genericHistoricalTrackerID: tracker.ID,
                                    genericRealTimeTrackerID: null,
                                },
                                dispatch
                            )
                            onUpdateRace()
                        }}
                    />
                </Grid>
                <Grid item>
                    <ButtonWithConfirmation
                        buttonText={'Race Recall'}
                        buttonColor={'primary'}
                        onConfirmed={async () => {
                            await createRaceState(
                                {
                                    raceID: race.id,
                                    type: GenericHistoricalTrackerRaceRecall(tracker),
                                    latencyInSeconds: 0,
                                    practiceMode: tracker.PracticeMode,
                                    videoTimestamp: videoTimestamp,
                                    videoSource: videoSource,
                                    genericRealTimeTrackerID: null,
                                    genericHistoricalTrackerID: tracker.ID,
                                },
                                dispatch
                            )
                            onUpdateRace()
                        }}
                    />
                </Grid>
                <Grid item>
                    <ButtonWithConfirmation
                        buttonText={'Skip Race'}
                        buttonColor={'secondary'}
                        onConfirmed={async () => {
                            await createRaceState(
                                {
                                    raceID: race.id,
                                    type: RaceStateTypes.SKIP_RACE,
                                    latencyInSeconds: 0,
                                    practiceMode: tracker.PracticeMode,
                                    videoTimestamp: videoTimestamp,
                                    videoSource: videoSource,
                                    genericHistoricalTrackerID: tracker.ID,
                                    genericRealTimeTrackerID: null,
                                },
                                dispatch
                            )
                            onUpdateRace()
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

import { userService } from './login'
import axios from 'axios'
import { getErrorData } from './get_error'
import CONFIG from '../settings/config'
import { Dispatch } from 'react'
import { getActionComments } from '../reducers/get_action_comments'

export interface GenericTrackerAction {
    RaceID: number
    RaceDate: number
    TrackCode: string
    LongTrackName: string
    RaceNumber: number
    RaceType: string
    RunnerID: number
    ProgramNumber: string
    RaceStateID: number
    RunnerEventID: number
    Type: string
    UserID: number
    UserName: string
    ActionTime: Date
    ActionVideoTimestamp: number
    ActionVideoSource: string
    TrackingModule: string | null
    GenericHistoricalTrackerID: number | null
    GenericRealTimeTrackerID: number | null
}

export interface GenericTrackerActionJson {
    race_id: number
    race_date: number
    track_code: string
    long_track_name: string
    race_number: number
    race_type: string
    runner_id: number
    program_number: string
    race_state_id: number
    runner_event_id: number
    type: string
    user_id: number
    user_name: string
    action_time: string
    action_video_timestamp: number
    action_video_source: string
    tracking_module: string | null
    generic_historical_tracker_id: number | null
    generic_real_time_tracker_id: number | null
}

export interface GenericActionComment {
    Name: string
    CommentType: string
    Comment: string
    GroupRecipients: string[]
    UserRecipients: string[]
    Acknowledgements: string[]
    IsLearningGuideExample: boolean
}

export interface GenericActionCommentJson {
    name: string
    comment_type: string
    comment: string
    group_recipients: string[]
    user_recipients: string[]
    acknowledgements: string[]
    is_learning_guide_example: boolean
}

export interface GenericActionCommentConfig {
    race_state_id: number
    runner_event_id: number
    comment_type: string
    comment: string
    user_recipients: number[]
    group_recipients: number[]
    is_learning_guide_example: boolean
}

export interface IDAndNameJson {
    id: number
    name: string
}

export interface UsersAndGroupsJson {
    users: IDAndNameJson[]
    groups: IDAndNameJson[]
}

export interface IDAndName {
    ID: number
    Name: string
}

export interface UsersAndGroups {
    Users: IDAndName[]
    Groups: IDAndName[]
}

export interface GenericActionReviewCommentForUser {
    GenericActionCommentID: number
    RaceID: number
    RaceDate: number
    TrackCode: string
    LongTrackName: string
    RaceNumber: number
    RaceType: string
    RunnerID: number
    ProgramNumber: number
    RaceStateID: number
    RunnerEventID: number
    UserID: number
    UserName: string
    Type: string
    ActionTime: Date
    CommentType: string
    Comment: string
    Acknowledged: boolean
}

interface GenericActionReviewCommentForUserJson {
    generic_action_comment_id: number
    race_id: number
    race_date: number
    track_code: string
    long_track_name: string
    race_number: number
    race_type: string
    runner_id: number
    program_number: number
    race_state_id: number
    runner_event_id: number
    user_id: number
    user_name: string
    type: string
    action_time: Date
    comment_type: string
    comment: string
    acknowledged: boolean
}

export const genericActionsFromJson = (genericActions: GenericTrackerActionJson[]) => {
    return genericActions.map((jsonTrackerActions: GenericTrackerActionJson) => {
        return {
            RaceID: jsonTrackerActions.race_id,
            RaceDate: jsonTrackerActions.race_date,
            TrackCode: jsonTrackerActions.track_code,
            LongTrackName: jsonTrackerActions.long_track_name,
            RaceNumber: jsonTrackerActions.race_number,
            RaceType: jsonTrackerActions.race_type,
            RunnerID: jsonTrackerActions.runner_id,
            ProgramNumber: jsonTrackerActions.program_number,
            RaceStateID: jsonTrackerActions.race_state_id,
            RunnerEventID: jsonTrackerActions.runner_event_id,
            Type: jsonTrackerActions.type,
            UserID: jsonTrackerActions.user_id,
            UserName: jsonTrackerActions.user_name,
            ActionTime: new Date(jsonTrackerActions.action_time),
            ActionVideoTimestamp: jsonTrackerActions.action_video_timestamp,
            ActionVideoSource: jsonTrackerActions.action_video_source,
            TrackingModule: jsonTrackerActions.tracking_module,
            GenericHistoricalTrackerID: jsonTrackerActions.generic_historical_tracker_id,
            GenericRealTimeTrackerID: jsonTrackerActions.generic_real_time_tracker_id,
        } as GenericTrackerAction
    })
}

export const getCommentsForGenericAction = async (runnerEventID: number | null, raceStateID: number | null) => {
    const url = `/api/v2/generic_action_comments?race_state_id=${raceStateID ?? ''}&runner_event_id=${
        runnerEventID ?? ''
    }`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const comments = response.data.map((commentsJson: GenericActionCommentJson) => {
        return {
            Name: commentsJson.name,
            CommentType: commentsJson.comment_type,
            Comment: commentsJson.comment,
            GroupRecipients: commentsJson.group_recipients,
            UserRecipients: commentsJson.user_recipients,
            Acknowledgements: commentsJson.acknowledgements,
            IsLearningGuideExample: commentsJson.is_learning_guide_example,
        } as GenericActionComment
    })

    return comments
}

export const getUsersAndGroups = async () => {
    const url = '/api/v2/users_and_groups'
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const usersAndGroups = {
        Users: (response.data as UsersAndGroupsJson).users.map((u) => {
            return {
                ID: u.id,
                Name: u.name,
            } as IDAndName
        }),
        Groups: (response.data as UsersAndGroupsJson).groups.map((g) => {
            return {
                ID: g.id,
                Name: g.name,
            } as IDAndName
        }),
    }
    return usersAndGroups
}

export const createGenericActionComment = async (
    name: string,
    runnerEventID: number | null,
    raceStateID: number | null,
    commentType: string,
    comment: string,
    recipientUserIDs: number[],
    recipientGroupIDs: number[],
    isLearningGuideExample: boolean
) => {
    const url = '/api/v2/generic_action_comment'
    const data = {
        name: name,
        runner_event_id: runnerEventID,
        race_state_id: raceStateID,
        comment_type: commentType,
        comment: comment,
        user_recipients: recipientUserIDs,
        group_recipients: recipientGroupIDs,
        is_learning_guide_example: isLearningGuideExample,
    } as GenericActionCommentConfig
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return response.status === 200
}

export const acknowledgeGenericActionComment = async (genericActionCommentID: number) => {
    const url = `/api/v2/acknowledge_generic_action_comment?generic_action_comment_id=${genericActionCommentID}`
    const response = await axios.post(
        url,
        {},
        {
            headers: userService.authHeader(),
            timeout: 1000 * 30,
        }
    )
    return response.status === 200
}

export const getGenericActionReviewComments = async (allComments?: boolean) => {
    const url = `/api/v2/user_generic_action_comments${allComments ? '?all_comments=true' : ''}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const comments =
        response?.data?.map((jsonComment: GenericActionReviewCommentForUserJson) => {
            return {
                GenericActionCommentID: jsonComment.generic_action_comment_id,
                RaceID: jsonComment.race_id,
                RaceDate: jsonComment.race_date,
                TrackCode: jsonComment.track_code,
                LongTrackName: jsonComment.long_track_name,
                RaceNumber: jsonComment.race_number,
                RaceType: jsonComment.race_type,
                RunnerID: jsonComment.runner_id,
                ProgramNumber: jsonComment.program_number,
                RaceStateID: jsonComment.race_state_id,
                RunnerEventID: jsonComment.runner_event_id,
                UserID: jsonComment.user_id,
                UserName: jsonComment.user_name,
                Type: jsonComment.type,
                ActionTime: new Date(jsonComment.action_time),
                CommentType: jsonComment.comment_type,
                Comment: jsonComment.comment,
                Acknowledged: jsonComment.acknowledged,
            } as GenericActionReviewCommentForUser
        }) ?? ([] as GenericActionReviewCommentForUser[])

    return comments
}

export const pollGenericActionReviewComments = (dispatch: Dispatch<any>) => {
    const initializeGenericActionReviewComments = async () => {
        try {
            const comments = await getGenericActionReviewComments(false)
            dispatch(getActionComments(comments))
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }

    initializeGenericActionReviewComments()

    return window.setInterval(async () => {
        try {
            const comments = await getGenericActionReviewComments(false)
            dispatch(getActionComments(comments))
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }, CONFIG.FEEDBACK_POLL_INTERVAL)
}

import axios from 'axios'
import { getErrorData } from './get_error'
import { userService } from './login'
import { RaceStateTypes } from '../types'
import { v4 as uuidv4 } from 'uuid'
import { Dispatch } from 'react'

export interface RaceStateCreationConfig {
    raceID: number
    type: RaceStateTypes | string
    latencyInSeconds: number
    practiceMode: boolean
    videoTimestamp?: number | null
    videoSource?: string | null
    genericHistoricalTrackerID?: number | null
    genericRealTimeTrackerID?: number | null
    x?: number
    y?: number
    width?: number
    height?: number
}

export function createRaceState(config: RaceStateCreationConfig, dispatch: Dispatch<any>) {
    const url = `/api/v2/race_state?race_id=${config.raceID}`

    const uuidString = uuidv4().toString()

    // eslint-disable-next-line no-restricted-globals
    parent.postMessage(
        {
            type: 'raceStateEvent',
            raceStateType: config.type,
            raceID: config.raceID,
            uuid: uuidString,
            x: config.x,
            y: config.y,
            width: config.width,
            height: config.height,
        },
        '*'
    )

    return axios
        .post(
            url,
            {
                type: config.type.toString(),
                uuid: uuidString,
                latencyInSeconds: config.latencyInSeconds,
                videoTimestamp: config.videoTimestamp ?? null,
                videoSource: config.videoSource ?? null,
                practiceMode: config.practiceMode,
                genericHistoricalTrackerID: config.genericHistoricalTrackerID,
                genericRealTimeTrackerID: config.genericRealTimeTrackerID,
            },
            {
                headers: userService.authHeader(),
                timeout: 1000 * 10,
            }
        )
        .catch((error) => {
            if (axios.isAxiosError(error) && error.response) {
                getErrorData(error, dispatch)
            }
        })
}

import React, { useState } from 'react'
import {
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    RowDetailState,
    SortingState,
} from '@devexpress/dx-react-grid'
import {
    Grid as DataGrid,
    PagingPanel,
    Table,
    TableHeaderRow,
    TableRowDetail,
    TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui'
import { IconButton, TableCell, Typography } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { VideoAcknowledgement } from './VideoAcknowledgement'
import {
    acknowledgeGenericActionComment,
    GenericActionReviewCommentForUser,
    getGenericActionReviewComments,
} from '../../services/generic_action_review'
import { getActionComments } from '../../reducers/get_action_comments'
import { getErrorData } from '../../services/get_error'
import { useDispatch } from 'react-redux'

export interface Props {
    genericActionComments: GenericActionReviewCommentForUser[]
    hideAcknowledgement?: boolean
}

export const LearningCenterTable = ({ genericActionComments, hideAcknowledgement }: Props) => {
    const dispatch = useDispatch()
    const [expandedRowIds, updateExpandedRowIds] = useState([] as number[])
    const columns = [
        {
            name: 'Action Info',
            title: 'Action Information',
            getCellValue: (row: GenericActionReviewCommentForUser) => (
                <div
                    className={`row-${row.GenericActionCommentID}-action-info`}
                    data-testid={`row-${row.GenericActionCommentID}-action-info`}
                >
                    <Typography variant={'body1'}>
                        Race: {row.RaceDate} {row.TrackCode} {row.RaceNumber},
                    </Typography>
                    {row.RunnerEventID !== null && (
                        <Typography variant={'body1'}>Runner: {row.ProgramNumber}</Typography>
                    )}
                    <Typography variant={'body1'}>{row.Type}</Typography>
                </div>
            ),
        },
        {
            name: 'CommentType',
            title: 'CommentType',
        },
        {
            name: 'comment',
            title: 'Comment',
            getCellValue: (row: GenericActionReviewCommentForUser) => (
                <div
                    className={`row-${row.GenericActionCommentID}-comment`}
                    data-testid={`row-${row.GenericActionCommentID}-comment`}
                >
                    <Typography variant={'body1'}>{row.Comment}</Typography>
                </div>
            ),
        },
        {
            name: 'Acknowledged',
            title: 'Acknowledged',
            getCellValue: (row: GenericActionReviewCommentForUser) => (row.Acknowledged ? 'Yes' : 'No'),
        },
    ]

    const pageSizes = [5, 10]

    return (
        <>
            <DataGrid
                rows={genericActionComments.sort((a, b) => {
                    const aInt = a.Acknowledged ? 1 : 0
                    const bInt = b.Acknowledged ? 1 : 0
                    return aInt - bInt
                })}
                columns={columns}
                getRowId={(row: GenericActionReviewCommentForUser) => row.GenericActionCommentID}
            >
                <PagingState />
                <RowDetailState
                    expandedRowIds={expandedRowIds}
                    onExpandedRowIdsChange={(expandedRowIds) => updateExpandedRowIds(expandedRowIds as number[])}
                />
                <IntegratedPaging />

                <SortingState defaultSorting={[{ columnName: 'Acknowledged', direction: 'asc' }]} />
                <FilteringState defaultFilters={[{ columnName: 'Acknowledged', value: 'No' }]} />
                <IntegratedFiltering />
                <IntegratedSorting />

                <Table
                    columnExtensions={[
                        {
                            columnName: 'comment',
                            wordWrapEnabled: true,
                        },
                    ]}
                />

                <TableFilterRow showFilterSelector={true} />
                <TableHeaderRow showSortingControls={true} />
                <PagingPanel pageSizes={pageSizes} />

                <TableRowDetail
                    contentComponent={({ row }) => (
                        <VideoAcknowledgement
                            comment={row}
                            hideAcknowledgement={hideAcknowledgement}
                            onAcknowledgedComment={(commentID: number) => {
                                updateExpandedRowIds([])
                                const acknowledgeCommentAndRefresh = async () => {
                                    try {
                                        const success = await acknowledgeGenericActionComment(commentID)
                                        if (success) {
                                            const comments = await getGenericActionReviewComments(false)
                                            dispatch(getActionComments(comments))
                                        }
                                    } catch (e) {
                                        getErrorData(e, dispatch)
                                    }
                                }
                                acknowledgeCommentAndRefresh()
                            }}
                        />
                    )}
                    toggleCellComponent={ToggleCellComponent}
                />
            </DataGrid>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    toggleCellButton: {
        verticalAlign: 'middle',
        display: 'inline-block',
        padding: theme.spacing(1),
    },
}))

const ToggleCellComponent = ({
    row,
    expanded,
    onToggle,
}: {
    row: GenericActionReviewCommentForUser
    expanded: boolean
    onToggle: () => void
}) => {
    const classes = useStyles()
    return (
        <>
            <TableCell>
                <IconButton
                    data-testid={`expand-details-action-${row.GenericActionCommentID}`}
                    className={`${classes.toggleCellButton} expand-details-action-${row.GenericActionCommentID}`}
                    onClick={() => onToggle()}
                >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </TableCell>
        </>
    )
}

export const getSilkColorsForRaceType = (country: string, raceType: string) => {
    const countryRaceType = `${country}_${raceType}`
    switch (countryRaceType) {
        case 'AUSTRALIA_':
        case 'AUSTRALIA_HARNESS':
            return {
                1: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/1.svg)',
                },
                2: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/2.svg)',
                },
                3: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/3.svg)',
                },
                4: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/4.svg)',
                },
                5: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/5.svg)',
                },
                6: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/6.svg)',
                },
                7: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/7.svg)',
                },
                8: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/8.svg)',
                },
                9: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/9.svg)',
                },
                10: {
                    backgroundImage:
                        'url(https://live-data-entry-client.s3.amazonaws.com/JOCKEY_SILKS/AUSTRALIA/SADDLE_CLOTH_IMAGES/10.svg)',
                },
                11: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                12: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                13: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                14: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                15: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                16: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                17: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                18: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                19: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                20: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                21: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                22: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                23: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                24: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                25: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
            }
        case 'USA_HARNESS':
            return {
                1: { silkColor: '#c04646', color: '#fff' },
                2: { silkColor: '#666ba6', color: '#fff' },
                3: { silkColor: '#e4e3e2', color: '#000' },
                4: { silkColor: '#3b663b', color: '#fff' },
                5: { silkColor: '#4d5050', color: '#F7F53A' },
                6: { silkColor: '#dfd34f', color: '#000' },
                7: { silkColor: '#c2548b', color: '#000' },
                8: { silkColor: '#989898', color: '#AC341C' },
                9: { silkColor: '#AE34B5', color: '#fff' },
                10: { silkColor: '#e5e5e5', color: '#fff' },
                11: { silkColor: '#000', color: '#e4e3e2' },
                12: { silkColor: '#000', color: '#e4e3e2' },
                13: { silkColor: '#000', color: '#e4e3e2' },
                14: { silkColor: '#000', color: '#e4e3e2' },
                15: { silkColor: '#000', color: '#e4e3e2' },
            }
        case 'USA_THB':
            return {
                1: {
                    silkColor: '#c6273a',
                    color: '#fff',
                },
                2: {
                    silkColor: '#d0d0d0',
                    color: '#000',
                },
                3: {
                    silkColor: '#37398f',
                    color: '#fff',
                },
                4: {
                    silkColor: '#d9c62b',
                    color: '#000',
                },
                5: {
                    silkColor: '#155423',
                    color: '#fff',
                },
                6: {
                    silkColor: '#454545',
                    color: '#f7f53a',
                },
                7: {
                    silkColor: '#e4531c',
                    color: '#000',
                },
                8: {
                    silkColor: '#b62775',
                    color: '#fff',
                },
                9: {
                    silkColor: '#448393',
                    color: '#fff',
                },
                10: {
                    silkColor: '#673677',
                    color: '#fff',
                },
                11: {
                    silkColor: '#989898',
                    color: '#ac341c',
                },
                12: {
                    silkColor: '#568637',
                    color: '#000',
                },
                13: {
                    silkColor: '#362403',
                    color: '#fff',
                },
                14: {
                    silkColor: '#470102',
                    color: '#f7f53a',
                },
                15: {
                    silkColor: '#ae061a',
                    color: '#fff',
                },
                16: {
                    silkColor: '#fff',
                    color: '#333',
                },
                17: {
                    silkColor: '#24276a',
                    color: '#fff',
                },
                18: {
                    silkColor: '#ebe966',
                    color: '#333',
                },
                19: {
                    silkColor: '#255635',
                    color: '#fff',
                },
                20: {
                    silkColor: '#333',
                    color: '#f7f53a',
                },
                21: {
                    silkColor: '#a312c8',
                    color: '#fff',
                },
                22: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                23: {
                    silkColor: '#da5ba3',
                    color: '#4da5fc',
                },
                24: {
                    silkColor: '#484848',
                    color: '#fff',
                },
                25: {
                    silkColor: '#5b006d',
                    color: '#c85bec',
                },
            }
        default:
            return {
                1: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                2: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                3: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                4: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                5: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                6: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                7: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                8: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                9: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                10: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                11: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                12: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                13: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                14: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                15: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                16: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                17: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                18: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                19: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                20: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                21: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                22: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                23: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                24: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                25: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                26: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                27: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                28: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                29: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
                30: {
                    silkColor: '#126db6',
                    color: '#fff',
                },
            }
    }
}

export const defaultSilk = {
    silkColor: '#126db6',
    color: '#fff',
}

export const getSilkColor = (colors: any, programNumber: number | string) => {
    return colors[programNumber] ?? defaultSilk
}

import React, { useState } from 'react'
import { createQuiz, Quiz } from '../../services/quizzes'
import { Button, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { RootStateType } from '../../store'
import { GenericHistoricalTracker } from '../../services/generic_historical_tracker'
import { Add, ArrowDropDown, Remove } from '@material-ui/icons'
import { QuizzesList } from './QuizzesList'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'

export interface Props {
    genericHistoricalTrackerID: number | null
    setGenericHistoricalTrackerID: (id: number) => void
    selectedQuiz: Quiz | null
    setSelectedQuiz: (quiz: Quiz) => void
    quizzes: Quiz[]
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}))

const toQuizRaceIDs = (quizzesContent: string) => {
    if (quizzesContent === '') {
        return {
            quizzes: [],
            valid: true,
        }
    }
    let valid = true
    const quizRaceIDs = quizzesContent.split(',').map((quizRaceString) => {
        const quizRaceID = parseInt(quizRaceString, 10)
        if (!quizRaceID) {
            valid = false
        }
        return quizRaceID
    })
    return {
        quizzes: quizRaceIDs,
        valid: valid,
    }
}

export const QuizSelector = ({
    genericHistoricalTrackerID,
    setGenericHistoricalTrackerID,
    selectedQuiz,
    setSelectedQuiz,
    quizzes,
}: Props) => {
    const trackers = useSelector((state: RootStateType) => state?.historicalTrackersStore?.historicalTrackers)
    const classes = useStyles()
    const [submittingQuizzes, setSubmittingQuizzes] = useState(false)
    const [addingQuizzes, setIsAddingQuizzes] = useState(false)
    const [quizzesContent, setQuizzesContent] = useState('')
    const { enqueueSnackbar } = useSnackbar()
    return (
        <Grid container direction={'column'} spacing={1} className={classes.root}>
            <Grid item>
                {!trackers && <Typography>No historical trackers found</Typography>}
                {trackers && (
                    <Autocomplete
                        style={{ width: 350 }}
                        multiple={false}
                        id="quiz-tracking-module-selection"
                        options={trackers}
                        getOptionLabel={(tracker: GenericHistoricalTracker) => tracker.Name}
                        value={trackers.find((t) => t.ID === genericHistoricalTrackerID) ?? null}
                        onChange={(event: any, tracker: any) => {
                            setGenericHistoricalTrackerID(tracker.ID)
                        }}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Historical Tracker"
                                placeholder="tracker"
                            />
                        )}
                        renderOption={(tracker: GenericHistoricalTracker) => (
                            <span className={`quiz-adjudication-selector-option-${tracker.ID}`}>{tracker.Name}</span>
                        )}
                        popupIcon={<ArrowDropDown className={'historical-trackers'} />}
                    />
                )}
            </Grid>
            <Grid item>
                {!(quizzes && quizzes.length > 0) && <Typography>No quizzes found</Typography>}
                {quizzes && quizzes.length > 0 && (
                    <QuizzesList quizzes={quizzes} selectedQuiz={selectedQuiz} setSelectedQuiz={setSelectedQuiz} />
                )}
            </Grid>
            {!addingQuizzes && (
                <Grid item>
                    <IconButton onClick={() => setIsAddingQuizzes(true)} size={'small'}>
                        <Add />
                    </IconButton>
                </Grid>
            )}
            {addingQuizzes && (
                <Grid item>
                    <IconButton onClick={() => setIsAddingQuizzes(false)}>
                        <Remove />
                    </IconButton>
                </Grid>
            )}
            {addingQuizzes && (
                <>
                    <Grid item>
                        <TextField
                            onChange={(e) => setQuizzesContent(e.target.value)}
                            value={quizzesContent}
                            variant={'outlined'}
                            label={'quiz race ids'}
                            size={'medium'}
                            className={'comment'}
                            data-testid={'quizzes content'}
                            error={!toQuizRaceIDs(quizzesContent).valid}
                            rows={5}
                            multiline
                            helperText={'comma separated raceIDs'}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            size={'medium'}
                            disabled={!toQuizRaceIDs(quizzesContent).valid && !submittingQuizzes}
                            onClick={() => {
                                setSubmittingQuizzes(true)
                                const quizRaceIDs = toQuizRaceIDs(quizzesContent).quizzes
                                quizRaceIDs.map(async (quizRaceID) => {
                                    const { success, failureText } = await createQuiz(
                                        genericHistoricalTrackerID!,
                                        quizRaceID
                                    )
                                    if (success) {
                                        enqueueSnackbar('Successfully created quiz', {
                                            variant: 'success',
                                        })
                                    } else {
                                        enqueueSnackbar(`Failed to create quiz ${failureText}`, {
                                            variant: 'warning',
                                        })
                                    }
                                })
                                setSubmittingQuizzes(false)
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

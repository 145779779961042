import { IRunner, IRunnerJson, IRunnerEventJson } from '../types'
import { RunnerEvent } from './runner_event'

export class Runner implements IRunner {
    public id: number
    public programNumber: string
    public name: string
    public drawNumber: number
    public runnerEvents: RunnerEvent[]

    constructor(runnerJSON: IRunnerJson) {
        this.id = runnerJSON.id
        this.programNumber = runnerJSON.program_number
        this.name = runnerJSON.name
        this.runnerEvents = runnerJSON.runner_events
            ? runnerJSON.runner_events.map((runnerEvent: IRunnerEventJson) => new RunnerEvent(runnerEvent))
            : []
        this.drawNumber = runnerJSON.draw_number
    }
}

import axios from 'axios'
import { getLatency } from '../reducers/get_latency'
import { getErrorData } from './get_error'
import { userService } from './login'
import { Dispatch } from 'react'

const encodeForm = (data: any) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

export function getUserLatencyInSeconds(dispatch: Dispatch<any>) {
    const url = '/api/v2/echo'
    const sendTime = Date.now()

    const request = axios.post(
        url,
        encodeForm({
            message: sendTime,
        }),
        {
            headers: userService.authHeader(),
            timeout: 1000 * 30,
        }
    )

    return request
        .then((response) => {
            if (!response.data) {
                return
            }

            const sentTime = response.data.message
            const now = Date.now()
            const latency = (now - sentTime) / 1000

            dispatch(getLatency(latency))

            return response
        })
        .catch((error) => {
            if (axios.isAxiosError(error) && error.response) {
                getErrorData(error, dispatch)
            }
        })
}

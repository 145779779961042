import { GenericHistoricalTracker, pollHistoricalRemainingRaces } from '../../services/generic_historical_tracker'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from '../../store'
import * as React from 'react'
import { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
} from '@material-ui/core'
import { LearningCenterGuideForTracker } from '../layouts/LearningCenterGuide'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    CardRoot: {
        padding: theme.spacing(3),
    },
    fullWidth: {
        width: '100%',
    },
    activeToggle: {
        marginLeft: 'auto',
    },
    flexOne: {
        flex: 1,
    },
    hidden: {
        display: 'none',
    },
}))

interface TrackingModuleInfoHeader {
    tracker: GenericHistoricalTracker
    practiceMode: boolean
    isActive: boolean
    onIsActiveUpdated: (newIsActive: boolean) => void
    isQuizCreationMode: boolean
    onIsQuizCreationModeUpdated: (newIsQuizCreationMode: boolean) => void
    isQuizPracticeMode: boolean
}

export const TrackerInfoHeader = ({
    tracker,
    practiceMode,
    isActive,
    onIsActiveUpdated,
    isQuizCreationMode,
    onIsQuizCreationModeUpdated,
    isQuizPracticeMode,
}: TrackingModuleInfoHeader) => {
    const active = useSelector((state: RootStateType) => state?.meStore?.me?.active)
    const isAdmin = useSelector((state: RootStateType) => state?.meStore?.me?.team === 'admin')
    const [howToTrackIsOpen, setHowToTrackIsOpen] = useState(false)
    const [remainingRaces, setRemainingRaces] = useState(null as number | null)
    const classes = useStyles()
    const dispatch = useDispatch()

    useEffect(() => {
        const actionReviewsTimer = pollHistoricalRemainingRaces(
            tracker.Name,
            isQuizPracticeMode,
            dispatch,
            setRemainingRaces
        )
        return () => clearInterval(actionReviewsTimer)
    }, [tracker.Name, setRemainingRaces, dispatch, isQuizPracticeMode])

    return (
        <>
            <Grid item className={classes.fullWidth}>
                <Grid container direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                    <Grid item className={classes.flexOne}></Grid>
                    <Grid item className={classes.flexOne}>
                        <Grid container direction={'row'} justifyContent={'center'}>
                            <Grid item>
                                <Typography variant={'h5'}>
                                    {tracker.Name}
                                    {(practiceMode || !active) && ' (Practice Mode)'}
                                    {isQuizPracticeMode && ' (Quizzes Only)'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.flexOne}>
                        <Grid container direction={'row'} justifyContent={'flex-end'}>
                            {isAdmin && !isQuizPracticeMode && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isQuizCreationMode}
                                            onChange={() => onIsQuizCreationModeUpdated(!isQuizCreationMode)}
                                            name="set-is-quiz-creation-mode"
                                            color="primary"
                                        />
                                    }
                                    label="Quiz Creation Mode"
                                />
                            )}
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={isActive}
                                        onChange={() => onIsActiveUpdated(!isActive)}
                                        name="set-is-active"
                                        color="primary"
                                    />
                                }
                                label="Give me races"
                            />
                        </Grid>
                    </Grid>
                    {remainingRaces !== null && remainingRaces > 0 && (
                        <Grid item>
                            <Typography variant={'body1'}>{remainingRaces} races left</Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant={'body1'}>
                    <Button color={'default'} variant={'text'} size={'small'} onClick={() => setHowToTrackIsOpen(true)}>
                        How to track?
                    </Button>
                </Typography>
                <Dialog open={howToTrackIsOpen} onClose={() => setHowToTrackIsOpen(false)}>
                    <DialogTitle id="customized-dialog-title">How to track {tracker.Name}</DialogTitle>
                    <DialogContent dividers>
                        {tracker.GuideURL && tracker.GuideURL.length > 0 && (
                            <LearningCenterGuideForTracker tracker={tracker} />
                        )}
                        {!(tracker.GuideURL && tracker.GuideURL.length > 0) && (
                            <Typography variant={'h6'}>No Guide, please reach out to Will</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => setHowToTrackIsOpen(false)} color="primary">
                            Got it!
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </>
    )
}

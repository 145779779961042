import axios from 'axios'
import { userService } from './login'
import CONFIG from '../settings/config'
import { Dispatch } from 'redux'
import { getHistoricalTrackers as historicalTrackers } from '../reducers/get_historical_trackers'
import { getErrorData } from './get_error'

export interface RaceTrio {
    RaceDate: string
    TrackCode: string
    RaceNumber: number
}

interface RaceTrioJson {
    race_date: string
    track_code: string
    race_number: number
}

export interface GenericHistoricalTracker {
    ID: number
    Name: string
    GuideURL: string
    RaceType: string
    Country: string
    UseActualPostTimeAsPublicTimeMarker: boolean
    VideoStartPublicTimeMarker: string
    VideoStartOffsetSeconds: number
    NumTrackersPerRace: number
    GenericHistoricalTrackerRaceStateGroups: GenericHistoricalTrackerRaceStateGroup[]
    GenericHistoricalTrackerRunnerEvents: GenericHistoricalTrackerRunnerEvent[]
    GenericHistoricalTrackerRunnerEventGroups: GenericHistoricalTrackerRunnerEventGroup[]
    VideoSources: VideoSource[]
    TimedMessages: GenericHistoricalTrackerTimedMessage[]
    IsActive: boolean
    AllowVideoControls: boolean
    PracticeMode: boolean
    ReverseRunnerButtons: boolean
    AllowJumpToHorseTimestamps: boolean
    IsGrader: boolean
}

export const GenericHistoricalTrackerRaceStateBase = (genericHistoricalTracker: GenericHistoricalTracker) => {
    return genericHistoricalTracker.Name.replace(/ /g, '_').toUpperCase()
}

export const GenericHistoricalTrackerStartTracking = (genericHistoricalTracker: GenericHistoricalTracker) => {
    return GenericHistoricalTrackerRaceStateBase(genericHistoricalTracker) + '_START_TRACKING'
}

export const GenericHistoricalTrackerRaceRecall = (genericHistoricalTracker: GenericHistoricalTracker) => {
    return GenericHistoricalTrackerRaceStateBase(genericHistoricalTracker) + '_RACE_RECALL'
}

export interface GenericHistoricalTrackerJson {
    id: number
    name: string
    guide_url: string
    race_type: string
    country: string
    use_actual_post_time_as_public_time_marker: boolean
    video_start_public_time_marker: string
    video_start_offset_seconds: number
    num_trackers_per_race: number
    generic_historical_tracker_race_state_groups: GenericHistoricalTrackerRaceStateGroupJson[]
    generic_historical_tracker_runner_events: GenericHistoricalTrackerRunnerEventJson[]
    generic_historical_tracker_runner_event_groups: GenericHistoricalTrackerRunnerEventGroupJson[]
    video_sources: VideoSourceJson[]
    timed_messages: GenericHistoricalTrackerTimedMessageJson[]
    is_active: boolean
    allow_video_controls: boolean
    practice_mode: boolean
    reverse_runner_buttons: boolean
    allow_jump_to_horse_timestamps: boolean
    is_grader: boolean
}

export interface GenericHistoricalTrackerRaceStateGroup {
    Name: string
    IsOptionsGroup: boolean
    RaceType: string | null
    SubRaceType: string | null
    StartType: string | null
    ButtonColumn: number
    ButtonColumnOrder: number | null

    GenericHistoricalTrackerRaceStates: GenericHistoricalTrackerRaceState[]
}

interface GenericHistoricalTrackerRaceStateGroupJson {
    name: string
    is_options_group: boolean
    race_type: string | null
    sub_race_type: string | null
    start_type: string | null
    button_column: number
    button_column_order: number | null

    generic_historical_tracker_race_states: GenericHistoricalTrackerRaceStateJson[]
}

export interface GenericHistoricalTrackerRaceState {
    Name: string
    RaceState: string
    ButtonText: string
    Explanation: string
    IsSecondaryButton: boolean
    IsStopTracking: boolean
    RequireConfirmation: boolean
    Order: number
    HorizontalOrder: number
    IsScored: boolean
    IsCorrectnessScore: boolean
    IsFScore: boolean
    ScoreWeight: number | null
}
interface GenericHistoricalTrackerRaceStateJson {
    name: string
    race_state: string
    button_text: string
    explanation: string
    is_secondary_button: boolean
    is_stop_tracking: boolean
    require_confirmation: boolean
    order: number
    horizontal_order: number
    is_scored: boolean
    is_correctness_score: boolean
    is_f_score: boolean
    score_weight: number | null
}

export interface GenericHistoricalTrackerRunnerEventGroup {
    ID: number
    Name: string
    IsOptionsGroup: boolean
    RaceType: string | null
    SubRaceType: string | null
    StartType: string | null
    GenericHistoricalTrackerRunnerEvents: GenericHistoricalTrackerRunnerEvent[]
}

export interface GenericHistoricalTrackerRunnerEvent {
    Name: string
    RaceType: string | null
    SubRaceType: string | null
    StartType: string | null
    IsCheckbox: boolean
    RunnerEvent: string
    RunnerEventChecked: string | null
    IconChecked: string | null
    ButtonText: string
    ShortTextButton: string
    Explanation: string
    Order: number
    IsScored: boolean
    IsCorrectnessScore: boolean
    IsFScore: boolean
    ScoreWeight: number | null
}

export const YesHasRunnerEvent = (runnerEvent: GenericHistoricalTrackerRunnerEvent) => {
    if (runnerEvent.IsCheckbox) {
        return runnerEvent.RunnerEventChecked
    }
    return runnerEvent.RunnerEvent
}

interface GenericHistoricalTrackerRunnerEventGroupJson {
    id: number
    name: string
    is_options_group: boolean
    race_type: string | null
    sub_race_type: string | null
    start_type: string | null
    generic_historical_tracker_runner_events: GenericHistoricalTrackerRunnerEventJson[]
}

interface GenericHistoricalTrackerRunnerEventJson {
    name: string
    race_type: string | null
    sub_race_type: string | null
    start_type: string | null
    is_checkbox: boolean
    runner_event: string
    runner_event_checked: string | null
    icon_checked: string | null
    button_text: string
    short_text_button: string
    explanation: string
    order: number
    is_scored: boolean
    is_correctness_score: boolean
    is_f_score: boolean
    score_weight: number | null
}

export interface GenericHistoricalTrackerTimedMessage {
    RelativeRaceState: string
    Message: string
    OffsetSeconds: number
    DurationSeconds: number
}

interface GenericHistoricalTrackerTimedMessageJson {
    relative_race_state: string
    message: string
    offset_seconds: number
    duration_seconds: number
}

export interface VideoSource {
    Source: string
}

interface VideoSourceJson {
    source: string
}

export const pollHistoricalTrackers = (dispatch: Dispatch<any>) => {
    const initializeHistoricalTrackers = async () => {
        try {
            const trackers = await getHistoricalTrackers()
            dispatch(historicalTrackers(trackers))
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }

    initializeHistoricalTrackers()

    return window.setInterval(async () => {
        try {
            const trackers = await getHistoricalTrackers()
            dispatch(historicalTrackers(trackers))
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }, CONFIG.TRACKERS_POLL_INTERVAL)
}

export const getHistoricalTrackerNextRace = async (
    trackerName: string,
    isQuizCreationMode: boolean,
    isQuizPracticeMode: boolean
) => {
    const url = `/api/v2/historical_tracker_next_race?tracker_name=${trackerName}&is_quiz_creation_mode=${
        isQuizCreationMode ? 'true' : 'false'
    }&quiz_practice_mode=${isQuizPracticeMode}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const race = response.data as RaceTrioJson
    return {
        RaceDate: race.race_date,
        TrackCode: race.track_code,
        RaceNumber: race.race_number,
    } as RaceTrio
}

export const getHistoricalTrackers = async () => {
    const url = '/api/v2/historical_trackers'
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    const trackers = response.data as GenericHistoricalTrackerJson[]
    return trackers
        .filter((t) => t.is_active)
        .map(
            (t) =>
                ({
                    ID: t.id,
                    Name: t.name,
                    GuideURL: t.guide_url,
                    RaceType: t.race_type,
                    Country: t.country,
                    UseActualPostTimeAsPublicTimeMarker: t.use_actual_post_time_as_public_time_marker,
                    VideoStartPublicTimeMarker: t.video_start_public_time_marker,
                    VideoStartOffsetSeconds: t.video_start_offset_seconds,
                    NumTrackersPerRace: t.num_trackers_per_race,
                    ReverseRunnerButtons: t.reverse_runner_buttons,
                    VideoSources: t.video_sources.map(
                        (source: VideoSourceJson) =>
                            ({
                                Source: source.source,
                            } as VideoSource)
                    ),
                    TimedMessages: t.timed_messages.map(
                        (tm: GenericHistoricalTrackerTimedMessageJson) =>
                            ({
                                RelativeRaceState: tm.relative_race_state,
                                Message: tm.message,
                                OffsetSeconds: tm.offset_seconds,
                                DurationSeconds: tm.duration_seconds,
                            } as GenericHistoricalTrackerTimedMessage)
                    ),
                    GenericHistoricalTrackerRaceStateGroups: t.generic_historical_tracker_race_state_groups.map(
                        (g) => ({
                            Name: g.name,
                            IsOptionsGroup: g.is_options_group,
                            RaceType: g.race_type,
                            SubRaceType: g.sub_race_type,
                            StartType: g.start_type,
                            ButtonColumn: g.button_column,
                            ButtonColumnOrder: g.button_column_order,
                            GenericHistoricalTrackerRaceStates: g.generic_historical_tracker_race_states.map(
                                (s) =>
                                    ({
                                        Name: s.name,
                                        RaceState: s.race_state,
                                        ButtonText: s.button_text,
                                        Explanation: s.explanation,
                                        IsSecondaryButton: s.is_secondary_button,
                                        IsStopTracking: s.is_stop_tracking,
                                        RequireConfirmation: s.require_confirmation,
                                        Order: s.order,
                                        HorizontalOrder: s.horizontal_order,
                                        IsScored: s.is_scored,
                                        IsCorrectnessScore: s.is_correctness_score,
                                        IsFScore: s.is_f_score,
                                        ScoreWeight: s.score_weight,
                                    } as GenericHistoricalTrackerRaceState)
                            ),
                        })
                    ),
                    GenericHistoricalTrackerRunnerEvents: t.generic_historical_tracker_runner_events.map(
                        (e) =>
                            ({
                                Name: e.name,
                                RaceType: e.race_type,
                                SubRaceType: e.sub_race_type,
                                StartType: e.start_type,
                                IsCheckbox: e.is_checkbox,
                                RunnerEvent: e.runner_event,
                                RunnerEventChecked: e.runner_event_checked,
                                IconChecked: e.icon_checked,
                                ButtonText: e.button_text,
                                ShortTextButton: e.short_text_button,
                                Explanation: e.explanation,
                                Order: e.order,
                                IsScored: e.is_scored,
                                IsCorrectnessScore: e.is_correctness_score,
                                IsFScore: e.is_f_score,
                                ScoreWeight: e.score_weight,
                            } as GenericHistoricalTrackerRunnerEvent)
                    ),
                    GenericHistoricalTrackerRunnerEventGroups: t.generic_historical_tracker_runner_event_groups.map(
                        (reg) =>
                            ({
                                ID: reg.id,
                                Name: reg.name,
                                IsOptionsGroup: reg.is_options_group,
                                RaceType: reg.race_type,
                                SubRaceType: reg.sub_race_type,
                                StartType: reg.start_type,
                                GenericHistoricalTrackerRunnerEvents: reg.generic_historical_tracker_runner_events.map(
                                    (re) =>
                                        ({
                                            Name: re.name,
                                            RaceType: re.race_type,
                                            SubRaceType: re.sub_race_type,
                                            StartType: re.start_type,
                                            IsCheckbox: re.is_checkbox,
                                            RunnerEvent: re.runner_event,
                                            RunnerEventChecked: re.runner_event_checked,
                                            IconChecked: re.icon_checked,
                                            ButtonText: re.button_text,
                                            ShortTextButton: re.short_text_button,
                                            Explanation: re.explanation,
                                            Order: re.order,
                                            IsScored: re.is_scored,
                                            IsCorrectnessScore: re.is_correctness_score,
                                            IsFScore: re.is_f_score,
                                            ScoreWeight: re.score_weight,
                                        } as GenericHistoricalTrackerRunnerEvent)
                                ),
                            } as GenericHistoricalTrackerRunnerEventGroup)
                    ),
                    IsActive: t.is_active,
                    AllowVideoControls: t.allow_video_controls,
                    PracticeMode: t.practice_mode,
                    AllowJumpToHorseTimestamps: t.allow_jump_to_horse_timestamps,
                    IsGrader: t.is_grader,
                } as GenericHistoricalTracker)
        ) as GenericHistoricalTracker[]
}

export const getHistoricalTrackerRacesRemaining = async (trackerName: string, isQuizPracticeMode: boolean) => {
    const url = `/api/v2/historical_tracker_remaining_races?tracker_name=${trackerName}&quiz_practice_mode=${isQuizPracticeMode}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return response.data.remaining_races
}

export const pollHistoricalRemainingRaces = (
    trackerName: string,
    isQuizPracticeMode: boolean,
    dispatch: Dispatch<any>,
    setRemainingRaces: (numRemaining: number) => void
) => {
    const fetchRemainingRaces = async () => {
        try {
            const numRaces = await getHistoricalTrackerRacesRemaining(trackerName, isQuizPracticeMode)
            setRemainingRaces(numRaces)
        } catch (e) {
            getErrorData(e, dispatch)
        }
    }

    fetchRemainingRaces()

    return window.setInterval(async () => {
        await fetchRemainingRaces()
    }, CONFIG.TRACKERS_POLL_INTERVAL)
}

import axios from 'axios'
import { userService } from './login'

export interface AnswerKey {
    ID: number
    RaceID: number
    GenericHistoricalTrackerID: number | null
    GenericRealTimeTrackerID: number | null
    TrackedPoints: number
    AnswerKeyInvalidReason: string | null
    AnswerKeyExpectedRaceStates: AnswerKeyExpectedRaceState[]
    AnswerKeyExpectedRunnerEvents: AnswerKeyExpectedRunnerEvent[]
}

export interface AnswerKeyExpectedRaceState {
    ID: number
    Type: string
    ShouldNotHaveType: boolean
    ExampleRaceStateID: number | null
    MinTime: string | null
    MaxTime: string | null
    MinTimestamp: number | null
    MaxTimestamp: number | null
    VideoSource: string | null
    Points: number
    AnswerKeyInvalidReason: string | null
}

export interface AnswerKeyExpectedRunnerEvent {
    ID: number
    ProgramNumber: string
    Type: string
    GenericRealTimeRunnerEventGroupID: number | null
    GenericHistoricalRunnerEventGroupID: number | null
    ShouldNotHaveType: boolean
    ExampleRunnerEventID: number | null
    MinTime: string | null
    MaxTime: string | null
    MinTimestamp: number | null
    MaxTimestamp: number | null
    VideoSource: string | null
    Points: number
    AnswerKeyInvalidReason: string | null
}

interface AnswerKeyJson {
    id: number
    race_id: number
    generic_historical_tracker_id: number | null
    generic_real_time_tracker_id: number | null
    tracked_points: number
    answer_key_invalid_reason: string | null
    answer_key_expected_race_states: AnswerKeyExpectedRaceStateJson[]
    answer_key_expected_runner_events: AnswerKeyExpectedRunnerEventJson[]
}

interface AnswerKeyExpectedRaceStateJson {
    id: number
    type: string
    example_race_state_id: number | null
    should_not_have_type: boolean
    min_time: string | null
    max_time: string | null
    min_timestamp: number | null
    max_timestamp: number | null
    video_source: string | null
    points: number
    answer_key_invalid_reason: string | null
}

interface AnswerKeyExpectedRunnerEventJson {
    id: number
    program_number: string
    type: string
    should_not_have_type: boolean
    example_runner_event_id: number | null
    generic_real_time_runner_event_group_id: number | null
    generic_historical_runner_event_group_id: number | null
    min_time: string | null
    max_time: string | null
    min_timestamp: number | null
    max_timestamp: number | null
    video_source: string | null
    points: number
    answer_key_invalid_reason: string | null
}

export const fetchAnswerKey = async (raceID: number, genericHistoricalTrackerID: number) => {
    const url = `/api/v2/answer_key?generic_historical_tracker_id=${genericHistoricalTrackerID}&race_id=${raceID}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })

    const answerKey = response.data as AnswerKeyJson
    return {
        ID: answerKey.id,
        RaceID: answerKey.race_id,
        GenericHistoricalTrackerID: answerKey.generic_historical_tracker_id,
        GenericRealTimeTrackerID: answerKey.generic_real_time_tracker_id,
        TrackedPoints: answerKey.tracked_points,
        AnswerKeyInvalidReason: answerKey.answer_key_invalid_reason,
        AnswerKeyExpectedRaceStates: answerKey.answer_key_expected_race_states.map(
            (rs) =>
                ({
                    ID: rs.id,
                    Type: rs.type,
                    ShouldNotHaveType: rs.should_not_have_type,
                    ExampleRaceStateID: rs.example_race_state_id,
                    MinTime: rs.min_time,
                    MaxTime: rs.max_time,
                    MinTimestamp: rs.min_timestamp,
                    MaxTimestamp: rs.max_timestamp,
                    VideoSource: rs.video_source,
                    Points: rs.points,
                    AnswerKeyInvalidReason: rs.answer_key_invalid_reason,
                } as AnswerKeyExpectedRaceState)
        ),
        AnswerKeyExpectedRunnerEvents: answerKey.answer_key_expected_runner_events.map(
            (re) =>
                ({
                    ID: re.id,
                    ProgramNumber: re.program_number,
                    Type: re.type,
                    ShouldNotHaveType: re.should_not_have_type,
                    ExampleRunnerEventID: re.example_runner_event_id,
                    GenericRealTimeRunnerEventGroupID: re.generic_real_time_runner_event_group_id,
                    GenericHistoricalRunnerEventGroupID: re.generic_historical_runner_event_group_id,
                    MinTime: re.min_time,
                    MaxTime: re.max_time,
                    MinTimestamp: re.min_timestamp,
                    MaxTimestamp: re.max_timestamp,
                    VideoSource: re.video_source,
                    Points: re.points,
                    AnswerKeyInvalidReason: re.answer_key_invalid_reason,
                } as AnswerKeyExpectedRunnerEvent)
        ),
    } as AnswerKey
}

import axios from 'axios'
import { userService } from './login'
import { assessmentFromJson, TrackerAssessmentJson } from './tracker_assessment'

export interface Quiz {
    RaceID: number
    RaceDate: string
    TrackCode: string
    RaceNumber: number
    IsFinalized: boolean
    NotFinalizedReason: string | null
    CreatedAt: Date
}

export interface QuizJson {
    race_id: number
    race_date: string
    track_code: string
    race_number: number
    is_finalized: boolean
    not_finalized_reason: string | null
    created_at: Date
}

export const fetchAllQuizzes = async (genericHistoricalTrackerID: number) => {
    const url = `/api/v2/quizzes?generic_historical_tracker_id=${genericHistoricalTrackerID}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return response.data.map(
        (quiz: QuizJson) =>
            ({
                RaceID: quiz.race_id,
                RaceDate: quiz.race_date,
                TrackCode: quiz.track_code,
                RaceNumber: quiz.race_number,
                IsFinalized: quiz.is_finalized,
                NotFinalizedReason: quiz.not_finalized_reason,
                CreatedAt: new Date(quiz.created_at),
            } as Quiz)
    )
}

export const createQuiz = async (genericHistoricalTrackerID: number, raceID: number) => {
    const url = '/api/v2/quiz'
    const data = {
        generic_historical_tracker_id: genericHistoricalTrackerID,
        race_id: raceID,
    }
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return {
        success: response.status === 200 && response.data === 'success',
        failureText: response.data,
    }
}

export const createAnswerKey = async (genericHistoricalTrackerID: number, raceID: number) => {
    const url = '/api/v2/create_answer_key'
    const data = {
        generic_historical_tracker_id: genericHistoricalTrackerID,
        race_id: raceID,
    }
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return {
        success: response.status === 200 && response.data === 'success',
        failureText: response.data,
    }
}

export const gradeRace = async (genericHistoricalTrackerID: number, raceID: number) => {
    const url = '/api/v2/grade_race'
    const data = {
        generic_historical_tracker_id: genericHistoricalTrackerID,
        race_id: raceID,
    }
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return assessmentFromJson(response.data as TrackerAssessmentJson)
}

export const createRaceStateAdjudication = async (
    raceTrackingAnswerKeyExpectedRaceStateID: number,
    shouldNotHaveType: boolean,
    raceStateID: number | null
) => {
    const url = '/api/v2/race_state_answer_adjudication'
    const data = {
        race_tracking_answer_key_expected_race_state_id: raceTrackingAnswerKeyExpectedRaceStateID,
        should_not_have_type: shouldNotHaveType,
        race_state_id: raceStateID,
    }
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return {
        success: response.status === 200 && response.data === 'success',
        failureText: response.data,
    }
}

export const createRunnerEventAdjudication = async (
    raceTrackingAnswerKeyExpectedRunnerEventID: number,
    shouldNotHaveType: boolean,
    runnerEventID: number | null
) => {
    const url = '/api/v2/runner_event_answer_adjudication'
    const data = {
        race_tracking_answer_key_expected_runner_event_id: raceTrackingAnswerKeyExpectedRunnerEventID,
        should_not_have_type: shouldNotHaveType,
        runner_event_id: runnerEventID,
    }
    const response = await axios.post(url, data, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })
    return {
        success: response.status === 200 && response.data === 'success',
        failureText: response.data,
    }
}

import React from 'react'
import { Grid, Link, Typography } from '@material-ui/core'
import { RootStateType } from '../../store'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { GenericRealTimeTracker } from '../../services/generic_real_time_tracker'
import { GenericHistoricalTracker } from '../../services/generic_historical_tracker'

export const LearningCenterGuide = () => {
    const { trackerName }: { trackerName: string } = useParams()
    const historicalTracker = useSelector((state: RootStateType) =>
        state?.historicalTrackersStore?.historicalTrackers?.find((t) => t.Name.split(' ').join('_') === trackerName)
    )
    const realTimeTracker = useSelector((state: RootStateType) =>
        state?.realTimeTrackersStore?.realTimeTrackers?.find((t) => t.Name.split(' ').join('_') === trackerName)
    )
    const tracker = historicalTracker ?? realTimeTracker
    if (!tracker) {
        return (
            <Grid container spacing={4} direction={'column'}>
                <Grid item>
                    <Grid container direction={'row'} justifyContent={'center'}>
                        <Grid item className={'learning-center-header'}>
                            <Typography variant={'h2'}> {trackerName} has no learning center guide </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return <LearningCenterGuideForTracker tracker={tracker} />
}

export const LearningCenterGuideForTracker = ({
    tracker,
}: {
    tracker: GenericRealTimeTracker | GenericHistoricalTracker
}) => {
    return (
        <Grid container spacing={4} direction={'column'}>
            <Grid item>
                <Grid container direction={'row'} justifyContent={'center'}>
                    <Grid item className={'learning-center-header'}>
                        <Typography variant={'h6'}>
                            Documentation & Video Examples (clicking link opens in new window):{' '}
                            <Link href={tracker.GuideURL} target={'_blank'}>
                                {tracker.GuideURL}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

import { createMuiTheme, CssBaseline, IconButton, MuiThemeProvider } from '@material-ui/core'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './App.css'
import { history, RootStateType } from './store'
import Notifier from './components/common/Notifier'
import Login from './components/layouts/Login'
import { ConnectedRouter, push } from 'connected-react-router'
import { SnackbarProvider } from 'notistack'
import { Route } from 'react-router'
import { drawerWidth, NavBar } from './components/common/NavBar'
import { useEffect, useState } from 'react'
import { pollMeData } from './services/poll_me_data'
import { makeStyles } from '@material-ui/core/styles'
import { GenericActionReviewCenter } from './components/layouts/GenericActionReview'
import { LearningCenterFeedback } from './components/layouts/LearningCenterFeedback'
import clsx from 'clsx'
import { pollHistoricalTrackers } from './services/generic_historical_tracker'
import { GenericHistoricalTrackerComponentFromParams } from './components/generic_historical_tracker/GenericHistoricalTracker'
import { pollRealTimeTrackers } from './services/generic_real_time_tracker'
import { GenericRealTimeTrackerComponent } from './components/layouts/GenericRealTimeTracker'
import { pollGenericActionReviewComments } from './services/generic_action_review'
import { LearningCenterGuide } from './components/layouts/LearningCenterGuide'
import { Clear } from '@material-ui/icons'
import { QuizAdjudication } from './components/layouts/QuizAdjudication'
import { pollToggles } from './services/poll_toggles'
import { MyPerformance } from './components/layouts/MyPerformance'
import { Quizzes } from './components/layouts/Quizzes'
import { pollActionReviews } from './services/action_review'
import ActionReview from './components/layouts/ActionReview'
import { ObjectionReviewLayout } from './components/layouts/ObjectionReview'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}))

export const App = () => {
    const classes = useStyles()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const darkMode = useSelector((state: RootStateType) => state?.darkModeStore?.darkMode ?? false)
    const userName = useSelector((state: RootStateType) => state?.meStore?.me?.userName ?? '')
    const theme = createMuiTheme({
        typography: {
            fontSize: 12,
        },
        palette: {
            type: darkMode ? 'dark' : 'light',
        },
    })
    const dispatch = useDispatch()
    useEffect(() => {
        if (!localStorage.getItem('user')) {
            dispatch(push('/login'))
            return
        }
        const meTimer = pollMeData(dispatch)
        return () => {
            clearInterval(meTimer)
        }
    }, [dispatch])
    useEffect(() => {
        if (!localStorage.getItem('user')) {
            dispatch(push('/login'))
            return
        }
        if (!userName || userName === '') {
            return
        }
        const historicalTrackersTimer = pollHistoricalTrackers(dispatch)
        const realTimeTrackersTimer = pollRealTimeTrackers(dispatch)
        const genericActionCommentsTimer = pollGenericActionReviewComments(dispatch)
        const togglesTimer = pollToggles(dispatch)
        const actionReviewsTimer = pollActionReviews(dispatch)

        return () => {
            clearInterval(historicalTrackersTimer)
            clearInterval(realTimeTrackersTimer)
            clearInterval(genericActionCommentsTimer)
            clearInterval(togglesTimer)
            clearInterval(actionReviewsTimer)
        }
    }, [userName, dispatch])

    const notistackRef = React.createRef() as any

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
                maxSnack={3}
                ref={notistackRef}
                action={(key) => (
                    <IconButton className={'clear-snackbar'} onClick={() => notistackRef.current.closeSnackbar(key)}>
                        <Clear />
                    </IconButton>
                )}
            >
                <div className={classes.root}>
                    <NavBar drawerOpen={drawerOpen} setDrawerOpen={(open) => setDrawerOpen(open)} />
                    <main
                        className={clsx(classes.content, {
                            [classes.contentShift]: drawerOpen,
                        })}
                    >
                        <div className={classes.drawerHeader} />
                        <ConnectedRouter history={history}>
                            <Notifier />
                            <Route exact={true} path="/login" component={Login} />
                            <Route exact={true} path="/generic_action_review" component={GenericActionReviewCenter} />
                            <Route
                                exact={true}
                                path="/learning_center_guide/:trackerName"
                                component={LearningCenterGuide}
                            />
                            <Route exact={true} path="/learning_center_feedback" component={LearningCenterFeedback} />
                            <Route exact={true} path="/performance" component={MyPerformance} />
                            <Route exact={true} path="/objections" component={ObjectionReviewLayout} />
                            <Route
                                exact={true}
                                path={'/generic_historical_tracker/:trackerName'}
                                component={GenericHistoricalTrackerComponentFromParams}
                            />
                            <Route
                                exact={true}
                                path={'/generic_real_time_tracker/:trackerName'}
                                component={GenericRealTimeTrackerComponent}
                            />
                            <Route exact={true} path={'/quizzes/:trackerName'} component={Quizzes} />
                            <Route exact={true} path={'/quiz_adjudication'} component={QuizAdjudication} />
                            <Route exact={true} path={'/action_review/:actionReviewID'} component={ActionReview} />
                        </ConnectedRouter>
                    </main>
                </div>
            </SnackbarProvider>
        </MuiThemeProvider>
    )
}

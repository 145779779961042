import { createAction, handleAction } from 'redux-actions'

export const getError = createAction('GET_ERROR')

export const getErrorReducer = handleAction(
    getError,
    (state, action) => ({
        ...state,
        error: action.payload,
    }),
    { error: null }
)

import { Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@material-ui/core'
import { Check, ThumbDown, ThumbUp, Clear, HourglassEmpty } from '@material-ui/icons'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { UnifiedAssessment } from '../../services/tracker_assessment'
import { RootStateType } from '../../store'
import { useSelector } from 'react-redux'

export interface MistakesListProps {
    mistakes: UnifiedAssessment[]
    selectedAssessment: UnifiedAssessment | null
    setSelectedAssessment: (assessment: UnifiedAssessment) => void
}

const useStyles = makeStyles((theme) => ({
    approvedOrAcknowledged: {
        backgroundColor: theme.palette.success.light,
    },
    notReviewed: {
        backgroundColor: theme.palette.error.light,
    },
    objected: {
        backgroundColor: theme.palette.warning.light,
    },
}))

const getClassForMistake = (classes: any, assessment: UnifiedAssessment) => {
    if (!assessment.Acknowledged && !assessment.Objected) {
        return classes.notReviewed
    }
    if (assessment.Acknowledged || assessment.Approved) {
        return classes.approvedOrAcknowledged
    }
    if (assessment.Objected) {
        return classes.objected
    }
}

const notYetReviewed = 'not reviewed'
const acknowledged = 'acknowledged'
const objected = 'objected'
const unreviewedObjections = 'unreviewed objections'
const approved = 'approved'
const rejected = 'rejected'

export const MistakesList = ({ mistakes, selectedAssessment, setSelectedAssessment }: MistakesListProps) => {
    const classes = useStyles()
    const isAdmin = useSelector((state: RootStateType) => state?.meStore?.me?.team === 'admin')
    const [filters, setFilters] = useState([notYetReviewed])
    const displayMistakes = mistakes
        .filter((q) => (!q.Acknowledged && !q.Objected) || !filters.includes(notYetReviewed))
        .filter((q) => !filters.includes(acknowledged) || q.Acknowledged)
        .filter((q) => !filters.includes(objected) || q.Objected)
        .filter((q) => !filters.includes(unreviewedObjections) || (q.Objected && !q.Approved && !q.Rejected))
        .filter((q) => !filters.includes(approved) || q.Approved)
        .filter((q) => !filters.includes(rejected) || q.Rejected)
        .sort((a, b) => {
            const actionSlug = (x: UnifiedAssessment) =>
                `${x.Type}_${x.GenericRealTimeTrackerID}_${x.GenericHistoricalTrackerID}_${x.RaceDate}`
            return actionSlug(a).localeCompare(actionSlug(b))
        })
    return (
        <Grid container direction={'column'} spacing={2}>
            <Grid item>
                <ToggleButtonGroup
                    value={filters}
                    onChange={(event, newFilters) => setFilters(newFilters)}
                    exclusive={true}
                >
                    <ToggleButton value={notYetReviewed}>{notYetReviewed}</ToggleButton>
                    <ToggleButton value={acknowledged}>{acknowledged}</ToggleButton>
                    {isAdmin && <ToggleButton value={unreviewedObjections}>{unreviewedObjections}</ToggleButton>}
                    <ToggleButton value={objected}>{objected}</ToggleButton>
                    <ToggleButton value={approved}>{approved}</ToggleButton>
                    <ToggleButton value={rejected}>{rejected}</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            {mistakes.length === 0 && (
                <Grid item>
                    <Typography variant={'h6'}>No mistakes match the filters</Typography>
                </Grid>
            )}
            <Grid item>
                <List component="nav" aria-label="quizzes">
                    {displayMistakes.map((mistake) => (
                        <Tooltip
                            title={mistake.AssessmentText ?? ''}
                            key={
                                mistake.RaceTrackingRaceStateAssessmentID ?? mistake.RaceTrackingRunnerEventAssessmentID
                            }
                        >
                            <ListItem
                                button
                                selected={assessmentsAreEqual(mistake, selectedAssessment)}
                                onClick={() => setSelectedAssessment(mistake)}
                                className={getClassForMistake(classes, mistake)}
                            >
                                <ListItemText
                                    primary={`(${mistake.UserName}) ${mistake.RaceDate} ${mistake.TrackCode} ${
                                        mistake.RaceNumber
                                    }${mistake.ProgramNumber ? `, Runner ${mistake.ProgramNumber}` : ''}: ${
                                        mistake.Type
                                    }`}
                                />
                                <ListItemIcon>
                                    {mistake.Acknowledged && <Check />}
                                    {mistake.Objected && <Clear />}
                                    {mistake.Objected && !mistake.Approved && !mistake.Objected && <HourglassEmpty />}
                                    {mistake.Objected && mistake.Approved && <ThumbUp />}
                                    {mistake.Objected && mistake.Rejected && <ThumbDown />}
                                </ListItemIcon>
                            </ListItem>
                        </Tooltip>
                    ))}
                </List>
            </Grid>
        </Grid>
    )
}

const assessmentsAreEqual = (first: UnifiedAssessment, second: UnifiedAssessment | null) => {
    if (first.RaceTrackingRunnerEventAssessmentID) {
        return first.RaceTrackingRunnerEventAssessmentID === second?.RaceTrackingRunnerEventAssessmentID
    } else {
        return first.RaceTrackingRaceStateAssessmentID === second?.RaceTrackingRaceStateAssessmentID
    }
}

import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { fetchAllQuizzes, Quiz } from '../../services/quizzes'
import { getErrorData } from '../../services/get_error'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { QuizSelector } from '../quiz_adjudication/QuizSelector'
import { QuizAdjudicationGrid } from '../quiz_adjudication/QuizAdjudicationGrid'

export const QuizAdjudication = () => {
    const [genericHistoricalTrackerID, setGenericHistoricalTrackerID] = useState(null as number | null)
    const [quizzes, setQuizzes] = useState([] as Quiz[])
    const [selectedQuiz, setSelectedQuiz] = useState(null as Quiz | null)
    const [shouldFetchQuizzes, setShouldFetchQuizzes] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (shouldFetchQuizzes && genericHistoricalTrackerID) {
            setShouldFetchQuizzes(false)
            fetchQuizzes(genericHistoricalTrackerID, setQuizzes, dispatch)
        }
    }, [genericHistoricalTrackerID, shouldFetchQuizzes, dispatch])

    return (
        <>
            <Grid container direction={'row'} spacing={2} justifyContent={'flex-start'} className={'quiz-adjudication'}>
                <Grid item>
                    <QuizSelector
                        genericHistoricalTrackerID={genericHistoricalTrackerID}
                        setGenericHistoricalTrackerID={(newID: number) => {
                            setSelectedQuiz(null)
                            setQuizzes([])
                            setGenericHistoricalTrackerID(newID)
                            setShouldFetchQuizzes(true)
                        }}
                        quizzes={quizzes}
                        selectedQuiz={selectedQuiz}
                        setSelectedQuiz={setSelectedQuiz}
                    />
                </Grid>
                <Grid item>
                    {selectedQuiz && genericHistoricalTrackerID && (
                        <QuizAdjudicationGrid
                            selectedQuiz={selectedQuiz}
                            genericHistoricalTrackerID={genericHistoricalTrackerID}
                            onAdjudicationComplete={async () => {
                                setSelectedQuiz(null)
                                setShouldFetchQuizzes(true)
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

const fetchQuizzes = async (
    genericHistoricalTrackerID: number,
    setQuizzes: (quizzes: Quiz[]) => void,
    dispatch: Dispatch<any>
) => {
    try {
        const newQuizzes = await fetchAllQuizzes(genericHistoricalTrackerID)
        setQuizzes(newQuizzes)
    } catch (e) {
        getErrorData(e, dispatch)
    }
}

import { createAction, handleAction } from 'redux-actions'
import { GenericActionReviewCommentForUser } from '../services/generic_action_review'

export const getActionComments = createAction('GET_ACTION_COMMENTS')

export const getActionCommentsReducer = handleAction(
    getActionComments,
    (state, action) => ({
        ...state,
        userActionComments: action.payload as GenericActionReviewCommentForUser[],
    }),
    { userActionComments: [] } as {
        userActionComments: GenericActionReviewCommentForUser[]
    }
)

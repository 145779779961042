import { Grid } from '@material-ui/core'
import * as React from 'react'
import { Rnd } from 'react-rnd'
import { HlsVideo } from './HlsVideo'
import { SyntheticEvent, useEffect } from 'react'
import { ScrubLocation, VideoControls } from './VideoControls'

interface VideoProps {
    url: string
    format: string
    height: number
    width: number
    idForIFrame?: string
    disableControls: boolean
    videoRef: React.RefObject<HTMLVideoElement>
    onProgress?(event: SyntheticEvent<HTMLVideoElement>): void
    autoplay?: boolean
    scrubLocations?: ScrubLocation[]
    initialPlaybackRate?: string
    play?: boolean
}

export const HistoricalVideoPlayer = ({
    url,
    format,
    height,
    width,
    idForIFrame,
    disableControls,
    videoRef,
    onProgress,
    autoplay,
    scrubLocations,
    initialPlaybackRate,
    play,
}: VideoProps) => {
    useEffect(() => {
        if (play !== undefined && videoRef) {
            if (play && videoRef?.current?.paused) {
                videoRef.current.play()
            }

            if (!play && videoRef?.current?.played) {
                videoRef.current.pause()
            }
        }
    }, [play, videoRef])
    return (
        <Grid item>
            <Rnd
                default={{
                    x: 0,
                    y: 0,
                    width: width,
                    height: height,
                }}
                disableDragging={true}
                lockAspectRatio={width / height}
                style={{
                    position: 'relative',
                }}
            >
                {format == 'mov' && (
                    <video
                        data-testid={'historical-video'}
                        ref={videoRef}
                        src={url}
                        height={'100%'}
                        width={'100%'}
                        controls={!disableControls}
                        onTimeUpdate={onProgress}
                        id={idForIFrame}
                        autoPlay={autoplay}
                    />
                )}
                {format == 'm3u8' && (
                    <HlsVideo
                        url={url}
                        videoRef={videoRef}
                        onProgress={onProgress}
                        initialTimestamp={0}
                        idForIFrame={idForIFrame}
                    />
                )}
            </Rnd>
            {!disableControls && (
                <VideoControls
                    videoRef={videoRef}
                    scrubLocations={scrubLocations}
                    initialPlaybackRate={initialPlaybackRate}
                />
            )}
        </Grid>
    )
}

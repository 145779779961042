import axios from 'axios'
import { userService } from './login'

export const getPictureVideoURL = async (key: string) => {
    const url = `/api/v2/picture_video?key=${key}`

    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })

    return response.data.url
}

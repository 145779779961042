import axios from 'axios'
import { userService } from './login'

export interface RaceStateVideoJson {
    id: number
    race_id: number
    user_id: number
    type: string
    time: Date
    video_timestamp: number
    video_source: string
    uuid: string
    picture_url: string
    video_url: string
    video_snap_picture_url: string
}

export interface RaceStateVideo {
    id: number
    userId: number
    raceId: number
    type: string
    time: Date
    videoTimestamp: number
    videoSource: string
    uuid: number
    pictureUrl: string
    videoUrl: string
    videoSnapPictureURL: string
}

export const getRaceStateVideo = async (raceStateId: number) => {
    const url = `/api/v2/race_state/${raceStateId}`
    const response = await axios.get(url, {
        headers: userService.authHeader(),
        timeout: 1000 * 30,
    })

    return {
        id: response.data.id,
        userId: response.data.user_id,
        raceId: response.data.race_id,
        type: response.data.type,
        time: new Date(response.data.time),
        videoTimestamp: response.data.video_timestamp,
        videoSource: response.data.video_source,
        uuid: response.data.uuid,
        pictureUrl: response.data.picture_url,
        videoUrl: response.data.video_url,
        videoSnapPictureURL: response.data.video_snap_picture_url,
    } as RaceStateVideo
}

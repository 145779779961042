import * as React from 'react'
import { MenuItem } from '@material-ui/core'

interface Props {
    toEnableText: string
    toDisableText: string
    isEnabled: boolean
    setState: (isEnabled: boolean) => void
    closePopup: () => void

    setDefaultState?: (isEnabled: boolean) => void
    defaultQualifier?: string
}

export const MenuSettingWithDefault = (props: Props) => {
    const text = props.isEnabled ? props.toDisableText : props.toEnableText
    return (
        <>
            <MenuItem
                onClick={() => {
                    props.setState(!props.isEnabled)
                    props.closePopup()
                }}
            >
                {text}
            </MenuItem>

            {props.setDefaultState && (
                <MenuItem
                    onClick={() => {
                        props.setDefaultState?.(!props.isEnabled)
                        props.setState(!props.isEnabled)
                        props.closePopup()
                    }}
                >
                    {text} By Default For {props.defaultQualifier}
                </MenuItem>
            )}
        </>
    )
}

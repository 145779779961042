import React from 'react'
import { AnswerKey, AnswerKeyExpectedRaceState, AnswerKeyExpectedRunnerEvent } from '../../services/answer_keys'
import { makeStyles } from '@material-ui/core/styles'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    TableContainer,
    Paper,
    Tooltip,
    Grid,
} from '@material-ui/core'
import { Check, Clear } from '@material-ui/icons'
import clsx from 'clsx'
import { RootStateType } from '../../store'
import { useSelector } from 'react-redux'

export interface QuizAnswersTableProps {
    answerKey: AnswerKey | null
    genericHistoricalTrackerID: number
    onRunnerEventSelected: (runnerEvent: AnswerKeyExpectedRunnerEvent) => void
    onRaceStateSelected: (raceState: AnswerKeyExpectedRaceState) => void
}

const useStyles = makeStyles((theme) => ({
    Correct: {
        backgroundColor: theme.palette.success.light,
    },
    Incorrect: {
        backgroundColor: theme.palette.error.light,
    },
    VerticalText: {
        writingMode: 'vertical-lr',
    },
    TableCell: {
        maxWidth: '250px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}))

export const QuizAnswersTable = ({
    answerKey,
    genericHistoricalTrackerID,
    onRunnerEventSelected,
    onRaceStateSelected,
}: QuizAnswersTableProps) => {
    const classes = useStyles()
    const tracker = useSelector((state: RootStateType) =>
        state?.historicalTrackersStore?.historicalTrackers?.find((t) => t.ID === genericHistoricalTrackerID)
    )
    if (!answerKey) {
        return null
    }
    const distinctRunnerEvents = [
        ...new Set(
            answerKey.AnswerKeyExpectedRunnerEvents.filter(
                (runnerEvent) => !runnerEvent.GenericHistoricalRunnerEventGroupID
            ).map((a) => a.Type)
        ),
    ]
    const distinctRaceStates = [...new Set(answerKey.AnswerKeyExpectedRaceStates.map((a) => a.Type))]
    const distinctRunnerEventGroups = [
        ...new Set(
            answerKey.AnswerKeyExpectedRunnerEvents.filter(
                (runnerEvent) => !!runnerEvent.GenericHistoricalRunnerEventGroupID
            ).map((a) => a.GenericHistoricalRunnerEventGroupID)
        ),
    ]
    const distinctRunners = [...new Set(answerKey.AnswerKeyExpectedRunnerEvents.map((a) => a.ProgramNumber))]
    return (
        <Grid container direction={'row'} spacing={2}>
            {(distinctRunnerEvents?.length ?? 0) + (distinctRunnerEventGroups?.length ?? 0) > 0 && (
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table size={'small'} className={'quiz-adjudication-table'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell variant={'head'} className={classes.TableCell}>
                                        Runner
                                    </TableCell>
                                    {distinctRunnerEvents.map((signal) => (
                                        <Tooltip title={signal} key={signal}>
                                            <TableCell variant={'head'} key={signal} className={classes.VerticalText}>
                                                {signal}
                                            </TableCell>
                                        </Tooltip>
                                    ))}
                                    {distinctRunnerEventGroups.map((runnerEventGroupID) => {
                                        const runnerEventGroupName =
                                            tracker?.GenericHistoricalTrackerRunnerEventGroups?.find(
                                                (runnerEventGroup) => runnerEventGroup.ID === runnerEventGroupID
                                            )?.Name
                                        if (!runnerEventGroupName) {
                                            return null
                                        }
                                        return (
                                            <Tooltip title={runnerEventGroupName} key={runnerEventGroupName}>
                                                <TableCell
                                                    variant={'head'}
                                                    key={runnerEventGroupName}
                                                    className={classes.VerticalText}
                                                >
                                                    {runnerEventGroupName}
                                                </TableCell>
                                            </Tooltip>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {distinctRunners
                                    .sort(
                                        (programNumberA, programNumberB) =>
                                            parseInt(programNumberA, 10) - parseInt(programNumberB, 10)
                                    )
                                    .map((programNumber) => (
                                        <TableRow key={programNumber}>
                                            <TableCell variant={'head'}>{programNumber}</TableCell>
                                            {distinctRunnerEvents.map((signal) => {
                                                const answerKeyRunnerEvent =
                                                    answerKey.AnswerKeyExpectedRunnerEvents.find(
                                                        (re) => re.Type === signal && re.ProgramNumber === programNumber
                                                    )!
                                                return displayRunnerEventAnswerKeyCell(
                                                    classes,
                                                    answerKeyRunnerEvent,
                                                    signal,
                                                    programNumber,
                                                    onRunnerEventSelected
                                                )
                                            })}
                                            {distinctRunnerEventGroups.map((runnerEventGroupID) => {
                                                const answerKeyRunnerEvent =
                                                    answerKey.AnswerKeyExpectedRunnerEvents.find(
                                                        (re) =>
                                                            re.GenericHistoricalRunnerEventGroupID ===
                                                                runnerEventGroupID && re.ProgramNumber === programNumber
                                                    )!
                                                const runnerEventGroupName =
                                                    tracker?.GenericHistoricalTrackerRunnerEventGroups?.find(
                                                        (runnerEventGroup) => runnerEventGroup.ID === runnerEventGroupID
                                                    )?.Name
                                                if (!runnerEventGroupName) {
                                                    return null
                                                }
                                                return displayRunnerEventAnswerKeyCell(
                                                    classes,
                                                    answerKeyRunnerEvent,
                                                    runnerEventGroupName,
                                                    programNumber,
                                                    onRunnerEventSelected
                                                )
                                            })}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}

            {(distinctRaceStates?.length ?? 0) > 0 && (
                <Grid item>
                    <TableContainer component={Paper}>
                        <Table size={'small'} className={'quiz-adjudication-table-race-states'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell variant={'head'} className={classes.TableCell}>
                                        Button
                                    </TableCell>
                                    <TableCell variant={'head'} className={classes.TableCell}>
                                        Assessment
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {distinctRaceStates.map((signal) => {
                                    const raceState = answerKey.AnswerKeyExpectedRaceStates.find(
                                        (re) => re.Type === signal
                                    )!
                                    let className = raceState.AnswerKeyInvalidReason
                                        ? classes.Incorrect
                                        : classes.Correct
                                    const icon = raceState.ShouldNotHaveType ? (
                                        <Clear fontSize={'small'} className={'clear'} />
                                    ) : (
                                        <Check fontSize={'small'} className={'check'} />
                                    )
                                    return (
                                        <TableRow key={signal}>
                                            <TableCell variant={'head'}>{signal}</TableCell>
                                            <TableCell className={clsx(className, `quiz-adjudication-cell-${signal}`)}>
                                                <Tooltip title={raceState?.AnswerKeyInvalidReason ?? ''}>
                                                    <IconButton onClick={() => onRaceStateSelected(raceState)}>
                                                        {icon}
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Grid>
    )
}

const displayRunnerEventAnswerKeyCell = (
    classes: any,
    answerKeyRunnerEvent: AnswerKeyExpectedRunnerEvent,
    signal: string,
    programNumber: string,
    onRunnerEventSelected: (runnerEvent: AnswerKeyExpectedRunnerEvent) => void
) => {
    let className = answerKeyRunnerEvent.AnswerKeyInvalidReason ? classes.Incorrect : classes.Correct
    const icon = answerKeyRunnerEvent.ShouldNotHaveType ? (
        <Clear fontSize={'small'} className={'clear'} />
    ) : (
        <Check fontSize={'small'} className={'check'} />
    )
    return (
        <React.Fragment key={signal}>
            <TableCell
                className={clsx(className, `quiz-adjudication-cell-${programNumber}-${signal.replace(' ', '-')}`)}
            >
                <Tooltip title={answerKeyRunnerEvent?.AnswerKeyInvalidReason ?? ''}>
                    <IconButton onClick={() => onRunnerEventSelected(answerKeyRunnerEvent)}>{icon}</IconButton>
                </Tooltip>
            </TableCell>
        </React.Fragment>
    )
}
